import counterValidation from '../domain/counterValidation';

class TransactionType{
    constructor({_id, id, name, description, relations, requiredMetaAttributes, payment, counters,
            rules}){
        this.id = _id || id;
        this.name = name;
        this.description = description;

        //Remove falsey relations. No need to store them
        for (let key in relations) {
            if (relations.hasOwnProperty(key)) {
                if(!relations[key])
                    delete relations[key];
            }
        }
        this.relations = relations; //Object containing relations. For example, relations.packingRelated: true

        if(!Array.isArray(requiredMetaAttributes))
            throw new Error(`requiredMetaAttributes is not of type array for Transaction Type ${this.name || this.id}`);
        this.requiredMetaAttributes = requiredMetaAttributes;

        if(payment == null) {
            payment = {commission: 0, purchaseCost: 0} //Default to 0
        }
        if(typeof payment.commission !== "number")
            throw new Error(`Invalid payment.commission for Transaction Type ${this.name}`);
        if(typeof payment.purchaseCost !== "number")
            throw new Error(`Invalid payment.purchaseCost for Transaction Type ${this.name}`);
        this.payment = payment;

        counterValidation.validateCounters(counters); //Throws exception if counters invalid
        this.counters = counters;

        if(typeof rules !== "object")
            throw new Error(`Invalid rules. Must be of type object for Transaction Type ${this.name}`);

        if(typeof rules.commentRequired !== "boolean"
            || typeof rules.approvalRequired !== "boolean"
            || typeof rules.lockRequired !== "boolean")
            throw new Error(`Invalid commentRequired, lockRequired, or approvalRequired for Transaction Type ${this.name}`);

        this.rules = { //Set rules only keeping the desired attributes on the object
            commentRequired: rules.commentRequired,
            approvalRequired: rules.approvalRequired,
            lockRequired: rules.lockRequired

        }
    }

    getTransactionTypeReference(){
        //Require down here to prevent circular dependency node was resolving poorly
        let TransactionTypeReference = require('./TransactionTypeReference').TransactionTypeReference;
        return TransactionTypeReference.constructFromTransactionType(this);
    }

    getWithoutId(){
        let {["id"]:omit, ...res} = this;
        return res;
    }
}

export { TransactionType };