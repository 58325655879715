import React, {Component} from 'react';
import { Alert } from 'reactstrap';
import PropTypes from "prop-types";

class ErrorAlert extends Component {
    render() {
        const {error} = this.props;
        return <Alert color="danger">
            Error loading content. {error.error ? error.error.message : "Unknown error"}
        </Alert>
    }
}

ErrorAlert.propTypes = {
    error: PropTypes.object.isRequired,
};

export {ErrorAlert};
