import Cookies from 'universal-cookie';
import {rpWrapper as rp} from './request.axios.wrapper';

let env = process.env.NODE_ENV;
const BASE = env === "production" ? "https://sales.kjdelectronics.com/v1/" : "http://localhost:3041/v1/";
const cookies = new Cookies();
const sessionKey = cookies.get('kjd_session');
const AUTH_HEADER = {"kjd_session": sessionKey};

class SalesClient {
    static getPackRequest(region, noCache=false){
        let uri = BASE + `packing/${region}/json`;
        if(noCache)
            uri = uri + "?noCache=true";

        let options = {
            method: 'GET',
            uri: uri,
            headers: AUTH_HEADER,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }

}

export default SalesClient;