class ProductTargets{
    constructor({storeReservedTarget=5, perDayTargets={}}={}){
        if(typeof storeReservedTarget !== "number" || storeReservedTarget < 0)
            throw new Error("Type Error: storeReservedTarget Must be a number greater than 0");

        this.storeReservedTarget = storeReservedTarget; //Defaults to 5
        this.perDayTargets = perDayTargets;
    }

}

export { ProductTargets };