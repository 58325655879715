class ProductPurchasingHistoryItem{
    constructor({poId, productId, purchaseDate, unitPrice, unitCost, quantity}){
        this.poId = poId;
        this.productId = productId;
        this.purchaseDate = new Date(purchaseDate);
        this.unitPrice = parseFloat(unitPrice);
        this.unitCost = parseFloat(unitCost);
        this.quantity = parseInt(quantity);
    }
}

export { ProductPurchasingHistoryItem };