import React, {Component} from 'react';
import { Row, Col, Input, Button, Form, FormGroup, Label } from 'reactstrap';
import KeyAttributeHeader from "../product/KeyAttributeHeader";
import Detail from "../product/Detail";
import ReserveTransferDetails from "../reserve/ReserveTransferDetails";
import {FbaInboundShipmentTable} from "../organisms/FbaInboundShipmentTable";
import axios from "axios";
const URL = process.env.NODE_ENV === "production" ? "https://publishing.kjdelectronics.com/api/renderpdf" : "http://localhost:1350/api/renderpdf";

class PublishingPage extends Component {
    constructor(props){
        super(props);

        this.state = {
            html: "",
            base64Pdf: null
        }
    }

    downloadLetter(){
        let options = {
            method: 'post',
            url: URL,
            responseType: 'arraybuffer',
            headers: {...this.authHeader, Accept: "application/pdf"},
            data: {
                FullDate: new Date().toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"long", day:"numeric"}),
                Template: "letter",
                Data: {
                    letterHtml: this.state.html
                },
            }
        };

        return axios(options)
            .then(result => {
                const data = Buffer.from(result.data, 'binary').toString('base64');
                console.log(data);
                this.setState({base64Pdf: data})
            })
            .catch(err => {
                if (err.response) {
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                    return {error: true, errorData: err, message: err.response.data.message}
                }
                throw err;
            })
    }

    render() {
        return (
            <div className="App-Width">
                <h1>Publishing - kjdElectronics Letter Head</h1>
                <div >
                    <div className="shipping-page">
                        <Form>
                            <FormGroup>
                                <h3><Label for="shipmentId">Report Raw HTML</Label></h3>
                                <Input type="textarea" id="html" size="lg"  value={this.state.html} onChange={e => this.setState({html: e.target.value})} rows="20" cols="50"/>
                            </FormGroup>
                        </Form>
                        <Button className="m-1" outline={true} size="lg" onClick={e => this.downloadLetter()}>Generate PDF</Button>
                    </div>
                    <h2>{this.state.base64Pdf && <a download="Letter.pdf" href={`data:application/pdf;base64,${this.state.base64Pdf}`} title='Download pdf document'>Download PDF</a>}</h2>
                </div>
            </div>
        );
    }
}

export default PublishingPage;
