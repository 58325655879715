import React, {Component} from 'react';
import {
    FormGroup,
    Label,
    Input,
    Card,
    CardBody,
    CardHeader,
    Badge,
    Alert,
    Table,
    Row,
    Col,
    Button,
    NavLink
} from 'reactstrap';
import imClient from "../service/ImClientSingleton";
import {Link} from "react-router-dom";

class ApprovableListItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            statusCodeOverride: null,
            statusOverride: null
        }
    }

    getStatusColor(){
        let statusCode = this.state.statusCodeOverride || this.props.approvable.status.code;

        if(statusCode === "PENDING")
            return "warning";
        if(statusCode === "DECLINED")
            return "danger";
        if(statusCode === "APPROVED")
            return "success";
        if(statusCode === "VOIDED")
            return "secondary";
    }

    void(){
        imClient.voidTransaction({idOrRef: this.props.approvable.transaction.ref, comment: "Voided via Discrepancy Management Console"})
            .then(transaction => {
                this.setState({statusCodeOverride: "VOIDED", statusOverride: "Voided"});
            })
    }

    updateApproval({isApproved}){
        imClient.updateTransactionApproval({
            idOrRef: this.props.approvable.transaction.ref,
            isApproved: isApproved,
            comment: `${isApproved ? "Approved" : "Declined"} via Discrepancy Management Console`
        }).then(transaction => {
                if(isApproved)
                    this.setState({statusCodeOverride: "APPROVED", statusOverride: "Approved"});
                else
                    this.setState({statusCodeOverride: "DECLINED", statusOverride: "Declined"});
            })
    }

    getStatusLabel(){
        return this.state.statusOverride || this.props.approvable.status.name;
    }

    isValidToApprove(){
        if(this.props.approvable.status.code !== "PENDING")
            return null;
        return this.props.approvable.validation.valid;
    }

    getPendingValidationBadge() {
        if (this.state.statusCodeOverride || this.props.approvable.status.code !== "PENDING")
            return "";

        if(!this.isValidToApprove())
            return <Badge color="danger" className="ml-1">Invalid</Badge>;
    }

    getButtons(){
        let approve = <Button color="success" size="sm" className="pl-2 pr-2 mr-1" onClick={e => this.updateApproval({isApproved: true})}>Approve</Button>;
        let decline = <Button outline color="danger" size="sm" className="pl-2 pr-2 mr-1" onClick={e => this.updateApproval({isApproved: false})}>Decline</Button>;
        let voidBtn = <Button outline color="secondary" size="sm" className="pl-2 pr-2" onClick={e => this.void()}>Void</Button>;

        let statusCode = this.state.statusCodeOverride || this.props.approvable.status.code;

        if(statusCode === "PENDING") {
            if(this.isValidToApprove())
                return [approve, decline, voidBtn];
            else
                return [voidBtn];
        }
        if(statusCode === "DECLINED")
            return [voidBtn];
        if(statusCode === "APPROVED")
            return [voidBtn];
        if(statusCode === "VOIDED")
            return "";
    }

    render() {
        const t = this.props.approvable.transaction;
        return (
            <tr>
               <th> <NavLink className="compact" tag={Link} to={`/transactions/${t.ref}`}>{t.ref}</NavLink></th>
                <td>{t.product.id}</td>
                <td>{t.product.shortName}</td>
                <td>{t.type.name}</td>
                <td>{t.itemCount}</td>
                <td>{new Date(t.dateTime).toLocaleDateString("en-US")}</td>
                <td>{t.user.displayName}</td>
                <td>{t.comments.length !== 0 ? t.comments[0].comment : ""}</td>
                <td>
                    <Badge color={this.getStatusColor()}>{this.getStatusLabel()}</Badge>
                    {this.getPendingValidationBadge()}
                </td>
                <td>
                    {this.getButtons()}
                </td>
            </tr>
        );
    }
}

export default ApprovableListItem;
