const COST_DEFAULTS = require('../../config/options/productDefaults').defaults.productCosts;
import { round } from 'mathjs';

class ProductCosts {
    constructor({
                    perItemPackingCommission = COST_DEFAULTS.perItemPackingCommission,
                    perItemPrepCost = COST_DEFAULTS.perItemPrepCost,
                    perItemShippingCost = COST_DEFAULTS.perItemShippingCost,
                    perItemInventoryManagementCost = COST_DEFAULTS.perItemInventoryManagementCost,
                } = COST_DEFAULTS) {

        this.perItemPackingCommission = perItemPackingCommission;
        this.perItemPrepCost = perItemPrepCost;
        this.perItemShippingCost = perItemShippingCost;
        this.perItemInventoryManagementCost = perItemInventoryManagementCost;

        this.total = this.calculateTotal();
    }

    calculateTotal() {
        return round(this.perItemPackingCommission + this.perItemPrepCost +
            this.perItemShippingCost + this.perItemInventoryManagementCost, 10);
    }

    static exchangeProductCostsCurrency({productCostsToExchange, exchangeRate}) {
        let newCosts = new ProductCosts(productCostsToExchange);
        newCosts.perItemPackingCommission = productCostsToExchange * exchangeRate;
        newCosts.perItemPrepCost = productCostsToExchange * exchangeRate;
        newCosts.perItemShippingCost = productCostsToExchange * exchangeRate;
        newCosts.perItemInventoryManagementCost = productCostsToExchange * exchangeRate;
        newCosts.perItemPurchaseCost = productCostsToExchange * exchangeRate;
        newCosts.total = newCosts.calculateTotal();
    }

    static getZeroedProductCostsObject() {
        return new ProductCosts({
            perItemPackingCommission: 0,
            perItemPrepCost: 0,
            perItemShippingCost: 0,
            perItemInventoryManagementCost: 0,
        });
    }

}

export { ProductCosts };