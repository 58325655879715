import { defaults as DEFAULTS } from '../../../config/options/productDefaults';
class ProductMarketplaceTargets{
    constructor({inStockDaysTarget=DEFAULTS.marketplaceInStockDaysTarget,
                    minimumStockThreshold=DEFAULTS.marketplaceMinimumStockThreshold}={}){
        this.inStockDaysTarget = inStockDaysTarget;
        this.minimumStockThreshold = inStockDaysTarget;
    }

}

export { ProductMarketplaceTargets };