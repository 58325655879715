import { round } from 'mathjs';
class ProductMarketplaceFinanceFeeItem{
    constructor({name, displayName, market, base}){
        this.name = name;
        this.displayName = displayName;
        this.market = round(market, 2);
        this.base = round(base, 2);
    }
}

export { ProductMarketplaceFinanceFeeItem };