function getNewCount({product, adjustment, isAutomaticallyApprovedInd}){
    return product.counters.counters.inventory + ( isAutomaticallyApprovedInd ? 0 : adjustment);
}

function getTransactionType(adjustment){
    if(adjustment === 0)
        return "N/A";
    if(adjustment < 0)
        return "INVENTORY-RM";
    else
        return "INVENTORY-ADD-ADJ"
}

function getName(){
    return "Inventory"
}

const InventoryAdjustmentLogic = {
    getNewCount, getTransactionType, getName
};

export default InventoryAdjustmentLogic;