import React, {Component} from 'react';
import ProductPage from "./components/pages/ProductPage";
import DiscrepancyReport from "./components/pages/DiscrepancyReport";
import ManageDiscrepancies from "./components/pages/ManageDiscrepancies";
import ReserveTransfer from "./components/pages/ReserveTransfer";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import AppProvider from "./context/AppProvider";
import {config as ssoConfig} from "./custom_vendor/sso";
import ssoClient from "./components/service/SsoClientSingleton"
import Cookies from 'universal-cookie';
import {LoadingSpinner} from "./components/atoms/LoadingSpinner";
import ShippingPage from "./components/pages/ShippingPage";
import NavBar from './components/organisms/NavBar'
import MangeInboundReserveProcessing from "./components/pages/MangeInboundReserveProcessing";
import ReserveManagementPage from "./components/pages/ReserveManagementPage";
import TransactionsPage from "./components/pages/TransactionsPage";
import ReserveActivityPage from "./components/pages/ReserveActivityPage";
import TransactionPage from "./components/pages/TransactionPage";
import PackRequestManagementPage from "./components/pages/PackRequestManagementPage";
import TransactionCreatePage from "./components/pages/TransactionCreatePage";
import MfnOrdersPage from "./components/pages/MfnOrdersPage";
import MfnOrderShipmentPage from "./components/pages/MfnOrderShipmentPage";
import {NotificationContainer} from 'react-notifications';
import KitBuildingPage from "./components/pages/KitBuildingPage";
import PublishingPage from "./components/pages/PublishingPage";
import ProductManagementPage from "./components/pages/ProductManagementPage";


const cookies = new Cookies();

class App extends Component {
    constructor(props){
        super(props);

        this.state = {
            user: null,
            activeUsers: []
        }
    }

    async componentDidMount(){
        await ssoClient.getUser()
            .then(user => {
                return this.setState({user: user});
            })
            .catch(err => {
                if(err.statusCode === 401) {
                    window.location.href = ssoConfig.baseUrl;
                }
                console.error(err);
            });

        await ssoClient.getActiveUsers()
            .then(users => {
                return this.setState({activeUsers: users});
            });
    }

    render() {
        const sessionKey = cookies.get('kjd_session');

        if(!sessionKey)
            window.location.href = ssoConfig.baseUrl;

        if(!this.state.user)
            return <LoadingSpinner/>;

        return (
        <AppProvider user={this.state.user} activeUsers={this.state.activeUsers}>
            <Router>
                <div>
                    <Route path="/" component={NavBar}/>
                    <Route exact path="/" component={ProductPage} />
                    <Route exact path="/packing/:productId" component={ProductPage} />
                    <Route exact path="/management/:productId" component={ProductManagementPage} />
                    <Route path="/discrepancies/report/:productId" component={DiscrepancyReport} />
                    <Route path="/reserve/transfer/:productId" component={ReserveTransfer} />
                    <Route exact path="/discrepancies" component={ManageDiscrepancies} />
                    <Route exact path="/transactions" component={TransactionsPage} />
                    <Route exact path="/transactions/new" component={TransactionCreatePage} />
                    <Route exact path="/transactions/:transactionId" component={TransactionPage} />
                    <Route exact path="/reserve" component={ReserveManagementPage} />
                    <Route exact path="/reserve/activity" component={ReserveActivityPage} />
                    <Route exact path="/reserve/inbound-processing" component={MangeInboundReserveProcessing} />
                    <Route exact path="/shipping" component={ShippingPage} />
                    <Route exact path="/pack-request" component={PackRequestManagementPage} />
                    <Route exact path="/mfn/orders" component={MfnOrdersPage} />
                    <Route exact path="/mfn/orders/:orderId" component={MfnOrderShipmentPage} />
                    <Route exact path="/kits/:productId" component={KitBuildingPage} />
                    <Route exact path="/publishing" component={PublishingPage} />
                    <NotificationContainer/>
                </div>
            </Router>
        </AppProvider>);
    }
}

export default App;
