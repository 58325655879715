import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const sessionKey = cookies.get('kjd_session');

const DEV = {
    baseUrl: "http://localhost:3015/v1"
};

const PROD = {
    baseUrl: "https://kits.kjdelectronics.com/v1"
};

const config = process.env.NODE_ENV.toLowerCase() === 'production' ? PROD : DEV;

class KitsClient {
    constructor ({sessionKey}) {
        this.authHeader = {"Authorization": `Bearer ${sessionKey}`};
    }

    getKit({productId}){

        let options = {
            method: 'GET',
            url: config.baseUrl + `/kits/${productId}`,
            headers: this.authHeader,
        };

        return axios(options)
            .then(result => {
                return result.data;
            })
    }

    async submitBuild({productId, count}){
        let options = {
            method: 'post',
            url: config.baseUrl + `/kits/${productId}/build`,
            headers: this.authHeader,
            data: {
                unitCount: count
            }
        };

        return axios(options)
            .then(result => {
                return result.data;
            })
            .catch(err => {
                if (err.response) {
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                    return {error: true, errorData: err, message: err.response.data.message}
                }
                throw err;
            })
    }
}

const client = new KitsClient({sessionKey: sessionKey});

export default client;