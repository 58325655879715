import React from 'react';
import AppContext from './AppContext';
import PropTypes from 'prop-types';

class AppProvider extends React.Component {
    render() {
        return (
            <AppContext.Provider
                value={{
                    user: this.props.user,
                    activeUsers: this.props.activeUsers
                }}
            >
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

AppProvider.propTypes = {
    user: PropTypes.object.isRequired
};

export default AppProvider;