import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {TransactionDetail} from "../organisms/TransactionDetail";
import {Button, FormGroup, Input, Label, NavLink} from "reactstrap";
import {Link} from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SalesClient from "../../client/SalesClient";
import imClient from "../service/ImClientSingleton";
import {LoadingSpinner} from "../atoms/LoadingSpinner";
import {PackRequestManagementTable} from "../molecules/tables/PackRequestManagementTable";
const REGIONS = ["US", "EU", "CA"];

class PackRequestManagementPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            region: "us",
            dropDownOpen: false,
            packRequest: null,
            products: null,
            dirtyRowsByProductId: [],
            showNoPackRequest: true,
            showApprovalNeededOnly: true,
        }
    }

    componentDidMount() {
        this.getPackRequest();
        this.getProducts();
    }

    getProducts(){
        imClient.getAllProducts({noCache: true})
            .then(products => {
                this.setState({products});
            })
            .catch(err => {
                this.setState({products: null});
                console.log(err);
            })
    }

    getPackRequest(region, noCache=false){
        SalesClient.getPackRequest(region ? region : this.state.region, noCache)
            .then(packRequest => {
                this.setState({packRequest, dirtyRowsByProductId: []});
            })
            .catch(err => {
                this.setState({packRequest: null});
                console.log(err);
            })
    }

    onRowUpdated(productId){
        this.setState({
            dirtyRowsByProductId: this.state.dirtyRowsByProductId.concat(productId)
        })
    }

    forceRefresPackRequest(){
        this.setState({packRequest: null});
        this.getPackRequest(this.state.region, true);
    }

    async changeRegion({region}){
        region = region.toLowerCase();
        await this.setState({region, packRequest: null});
        this.getPackRequest(region);
    }

    get regionDropdown(){
        return <Button size="sm"><Dropdown size="sm" data-placement="right" isOpen={this.state.dropdownOpen}
                      toggle={() => this.setState({dropdownOpen: !this.state.dropdownOpen})}>
                <DropdownToggle caret>
                    Region ({this.state.region.toUpperCase()})
                </DropdownToggle>
                <DropdownMenu>
                    {
                        REGIONS.map(region => <DropdownItem onClick={() => this.changeRegion({region})}>{region}</DropdownItem>)
                    }
                </DropdownMenu>
            </Dropdown></Button>
    }

    get body(){
        if(!this.state.products)
            return <LoadingSpinner/>;
        return <PackRequestManagementTable products={this.state.products} packRequest={this.state.packRequest}
                                           onRowUpdated={productId => this.onRowUpdated(productId)}
                                           dirtyRowsByProductId={this.state.dirtyRowsByProductId}
                                           showApprovalNeededOnly={this.state.showApprovalNeededOnly}
                                           showNoPackRequest={this.state.showNoPackRequest}/>
    }

    render() {
        return (
            <div className="App-Width">
                <div className="d-inline-block">
                    <h1 className="d-inline-block">Pack Request Management {this.regionDropdown}</h1>
                    <FormGroup className="d-inline-block pb-2">
                        <Label className="ml-3 pr-4"><strong>Show Products With No Pack Request</strong></Label>
                        <Input type="checkbox" checked={this.state.showNoPackRequest} onChange={(e) => this.setState({showNoPackRequest: e.target.checked})}/>
                    </FormGroup>
                    <FormGroup className="d-inline-block pb-2">
                        <Label className="ml-3 pr-4"><strong>Approval Needed</strong></Label>
                        <Input type="checkbox" checked={this.state.showApprovalNeededOnly} onChange={(e) => this.setState({showApprovalNeededOnly: e.target.checked})}/>
                    </FormGroup>
                    <FormGroup className="d-inline-block pb-2 ml-2">
                        <Button disabled={this.state.packRequest == null} color="primary" size="sm" onClick={() => this.forceRefresPackRequest()}>Refresh Pack Request</Button>
                    </FormGroup>
                </div>
                {this.body}
            </div>
        );
    }
}

PackRequestManagementPage.propTypes = {
};


export default PackRequestManagementPage;
