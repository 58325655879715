import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import {Badge, Row, Col, NavLink} from 'reactstrap';
import paginationFactory from "react-bootstrap-table2-paginator";
import {TransactionSummary} from "../molecules/TransactionSummary";

class TransactionDetail extends React.Component {
    static lpnLink(transaction){
        if(transaction.meta.returnLpn){
            return  <NavLink href={`https://returns.kjdelectronics.com/lpn/${transaction.meta.returnLpn}`}
                             target="_blank"><Badge>{transaction.meta.returnLpn}</Badge></NavLink>
        }
        return '';
    }

    render() {
        const {transaction} = this.props;

        if(!transaction)
            return "";

        //TODO Data
        // - Product Counters current
        // - Product Counters at time of transaction
        // - Basic Product Details
        // - Other Transactions for Product
        // - Other Transactions before and after this transaction
        // - Transaction Info: Type, user, datetime, product, count, raw meta, approval, comments
        // - Linkable rich meta for LPN, FBA Shipment Details, order ids

        //TODO Features
        // - Void, Reassign, Comment, Approve
        // - Refresh Counters (Forced)
        // - Event integration for live counter updates

        return <div className="mb-1">
            <h4>Details</h4>
            <TransactionSummary transaction={transaction}/>
        </div>
    }
}

TransactionDetail.propTypes = {
    transaction: PropTypes.object.isRequired
};

export {TransactionDetail};