import { ProductPurchasingHistoryItem } from './ProductPurchasingHistoryItem';
import { ProductPurchasingRecommendation } from './ProductPurchasingRecommendation';
import { initArrayOfObjects } from '../../../helper/arrayOps';

class ProductPurchasing{
    constructor({supplier:{name, id, leadTimeDays}={}, purchaseHistory=[], mostRecentUnitPrice=null, mostRecentUnitCost=null,
                    leadTimeDaysOverride=null, minimumOrderQuantity=null, maximumOrderQuantity=null, recommendation={}}={}){

        //Synced and imported from purchasing
        this.supplier = {name: name, id: id, leadTimeDays: leadTimeDays};
        this.purchaseHistory = initArrayOfObjects({array: purchaseHistory, objectToInstantiate: ProductPurchasingHistoryItem});
        this.mostRecentUnitPrice = mostRecentUnitPrice ? parseFloat(mostRecentUnitPrice) : null;
        this.mostRecentUnitCost = mostRecentUnitCost ? parseFloat(mostRecentUnitCost) : null;

        this.leadTimeDaysOverride = leadTimeDaysOverride;
        this.minimumOrderQuantity = minimumOrderQuantity;
        this.maximumOrderQuantity = maximumOrderQuantity;

        this.recommendation = recommendation ? new ProductPurchasingRecommendation(recommendation) : {};
    }

    getAverageUnitPrice({lookbackDays}){
        //TODO
    }

    getAverageUnitCost({lookbackDays}){
        //TODO
    }

    getLeadTime(){
        return this.leadTimeDaysOverride ? this.leadTimeDaysOverride : this.supplier.leadTimeDays;
    }

    getUserEditableFieldsForDb(){
        let {["supplier"]:omit, ...res} = this;
        delete res.purchaseHistory;
        delete res.mostRecentUnitCost;
        delete res.mostRecentUnitPrice;
        delete res.recommendation;
        return res;
    }

    //TODO Set leadTime for db
}

export { ProductPurchasing };