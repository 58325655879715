import React, {Component} from 'react';
import sanitizeHtml from 'sanitize-html';

class SanitizeHtml extends Component {
    constructor(props) {
        super(props);
    }

    render(){
        const unsafeHtml = this.props.html;
        if(!unsafeHtml) return "";
        const safeHtml = sanitizeHtml(unsafeHtml, {
            allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'span'],
            allowedAttributes: {
                a: ['href', 'target']
            }
        });
        return (
            <div
                dangerouslySetInnerHTML={{__html: safeHtml}}
            />
        );
    }
}

export default SanitizeHtml;