import React from 'react';
import {Link} from 'react-router-dom'
import {
    FormGroup,
    Label,
    Form,
    Input,} from 'reactstrap';
import AppContext from "../../context/AppContext";
import {config as ssoConfig} from "../../custom_vendor/sso";
import Avatar from 'react-avatar';
import PropTypes from "prop-types";
const GROUP = "IM_TRANSACTION_CREATE_AS_USER";

class UserSelectDropdown extends React.Component {
    constructor(props) {
        super(props);
    }

    onUserChange({user, currentUser}){
        if(this.props.onUserChange)
            this.props.onUserChange({user, currentUser });
    }

    formatUser(user){
        return `${user.displayName} (${user.username})`;
    }

    optionFromUser({user, activeUsers}){
        return <option selected={this.props.userId === user.id} id={user.id}
                       value={user.id}>{this.formatUser(user)}</option>;
    }

    onChange(e, activeUsers){
        console.log(e);
        const userId = e.target.value;
        const user = activeUsers.find(u => u.id === userId);
        this.onUserChange({user, currentUser: null});
    }

    render() {

        return ( <AppContext.Consumer>
                {({user, activeUsers}) => {
                    if(this.props.userId == null)
                        this.onUserChange({user, currentUser: user}); //Default to current user

                    if(!user.groups.includes(GROUP))
                        return;

                    if(!activeUsers)
                        activeUsers = [];

                    return <Form inline><FormGroup row inline>
                        <Label for="exampleSelect" inline className="mr-1">Submit As</Label>
                        <Input onChange={e => this.onChange(e, activeUsers)} inline type="select" name="select" id="exampleSelect" value={this.props.userId}>
                            {this.optionFromUser({user: user, currentUser: user})}
                            {activeUsers.map(u => {
                                if(u.id === user.id)
                                    return null;
                                return this.optionFromUser({user: u, activeUsers});

                            })}
                        </Input>
                    </FormGroup></Form>}
                }
                </AppContext.Consumer>
            );
    }
}

UserSelectDropdown.propTypes = {
    userId: PropTypes.string.isRequired,
    onUserChange: PropTypes.func.isRequired,
};

export default UserSelectDropdown