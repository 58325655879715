import React, {Component} from 'react';
import PropTypes from "prop-types";
import Button from "reactstrap/es/Button";
import transactionsClient from "../../client/transactionsClient"
import {Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Label, Input} from "reactstrap";

class TransactionCommentDialog extends Component {
    state = {
        comment: ""
    };

    async addComment(){
        transactionsClient.transactions.addComment({idOrRef: this.props.transaction.ref, comment: this.state.comment})
            .then(r => console.log("Comment added"))
            .catch(e => console.log(e))
            .finally(r => this.props.toggle())
    }

    render() {
        const {transaction, toggle, onVoid, className} = this.props;

        return <Modal isOpen={true} toggle={toggle} className={className}>
            <ModalHeader toggle={toggle}>Void Transaction {transaction.ref} - {transaction.type.name}</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="exampleText">Add Comment</Label>
                    <Input type="textarea" name="text" id="voidComment" value={this.state.comment} onChange={e => this.setState({comment: e.target.value})} />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button disabled={this.state.comment.length === 0} color="primary" onClick={() => this.addComment()}>Save</Button>
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}

TransactionCommentDialog.propTypes = {
    transaction: PropTypes.object.isRequired,
    toggle: PropTypes.func.isRequired,
    className: PropTypes.string
};

export {TransactionCommentDialog};
