import { TransactionType } from './TransactionType';
import counterValidation from '../domain/counterValidation';

class TransactionTypeReference{
    constructor({id, name, counters}){
        if(!id || !name || !counters)
            throw "id, name. counters are all required to instantiate a TransactionTypeReference";

        this.id = id;
        this.name = name;
        counterValidation.validateCounters(counters); //Throws exception if counters invalid
        this.counters = counters;
    }

    //Provide the same method here as on TransactionType. This way a check does not need to be performed when calling this method
    getTransactionTypeReference(){
        return this;
    }

    static constructFromTransactionType(transactionTypeObj){
        if(!(transactionTypeObj instanceof TransactionType))
            throw "TransactionTypeReference can only be created from an instance of TransactionType";

        return new TransactionTypeReference(
            {
                id: transactionTypeObj.id,
                name: transactionTypeObj.name,
                counters: transactionTypeObj.counters
            });
    }
}

export { TransactionTypeReference };