import io from 'socket.io-client';
import feathers from '@feathersjs/client';
const URL = process.env.REACT_APP_EVENTS_URL;

class EventsClient {
    constructor({sessionKey}) {
        this._socket = io(URL);
        this._client = feathers();
        this._sessionKey = sessionKey;

        this._events = null;

        this._client .configure(feathers.socketio(this._socket));
        this._client .configure(feathers.authentication({
            storage: window.localStorage
        }));
    }

    connect(){
        return this._client.authenticate({
            strategy: 'kjdAuth',
            sessionKey: this._sessionKey
        }).then(res => {
            console.log(`Events Client Authenticated`);
            this._events =  this._client.service('events');
        }).catch(error => {
            console.log(`Events Client Authentication ERROR`);
            return Promise.reject(error);
        });
    }

    subscribe(handle) {
        if (typeof handle !== 'function')
            throw new Error('handle must be an function');
        if(!this.getConnectedInd()){
            console.log(`Events Service Warning: Not connected. Connecting before activating subscription`);
            return this.connect()
                .then(() => {
                    this._events.on('created', handle);
                    console.log('Async: Subscribed successfully to event service')
                })
        }
        else {
            this._events.on('created', handle);
            console.log('Sync: Subscribed successfully to event service')
        }
    }

    getConnectedInd(){
        return !!this._events;
    }


}
export default EventsClient;