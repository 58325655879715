import { rpWrapper as rp } from '../../helper/request.axios.wrapper';
import config from '../config/config';
import routes from '../config/routes';
import { Transaction } from '../../obj/Transaction';
import { TransactionType } from '../../obj/TransactionType';
import { TransactionBatch } from '../../obj/TransactionBatch';
import { ProductCounters } from '../../obj/ProductCounters';
import moment from 'moment-timezone';

class Transactions{
    constructor({auth, microserviceAuthorizationHeader=null}){
        this.auth = auth;
        this.microserviceAuthorizationHeader = microserviceAuthorizationHeader;
    }

    getTransactionTypes(){
        let options = {
            uri: config.baseUrl + routes.types.get,
            method: 'GET',
            headers: {},
            json: true
        };
        options.headers[this.auth.headerName] = this.auth.headerValue;

        return rp(options)
            .then(types => {
                return types.map(t => new TransactionType(t)) ;
            })
            .catch(err => {
                return Promise.reject(err);
            })
    }

    getRecentTransactions(){
        let options = {
            uri: config.baseUrl + routes.transactions.get,
            method: 'GET',
            headers: {},
            json: true
        };
        options.headers[this.auth.headerName] = this.auth.headerValue;

        return rp(options)
            .then(transactions => {
                return transactions.map(t => new Transaction(t)) ;
            })
            .catch(err => {
                return Promise.reject(err);
            })
    }

    search(query){
        let options = {
            uri: config.baseUrl + routes.transactions.get + `?query=${encodeURIComponent(JSON.stringify(query))}`,
            method: 'GET',
            headers: {},
            json: true
        };
        options.headers[this.auth.headerName] = this.auth.headerValue;

        return rp(options)
            .then(transactions => {
                return transactions.map(t => new Transaction(t)) ;
            })
            .catch(err => {
                return Promise.reject(err);
            })
    }

    getTransactionByIdOrRef(idOrRef){
        let options = {
            uri: config.baseUrl + routes.transactions.getSingle.replace(':idOrRef', idOrRef),
            method: 'GET',
            headers: {},
            json: true
        };
        options.headers[this.auth.headerName] = this.auth.headerValue;

        return rp(options)
            .then(transaction => {
                return new Transaction(transaction);
            })
            .catch(err => {
                return Promise.reject(err);
            })
    }

    getOutboundShipmentsForProduct({productId}){
        let options = {
            uri: config.baseUrl + routes.outboundShipments.get.getForProduct.replace(':productId', productId),
            method: 'GET',
            headers: {},
            json: true
        };
        options.headers[this.auth.headerName] = this.auth.headerValue;

        return rp(options)
            .then(outboundShipments => {
                return outboundShipments;
            })
            .catch(err => {
                return Promise.reject(err);
            })
    }

    addComment({idOrRef, comment}){
        let params = {
            action: 'comment',
            comment
        };

        let options = {
            uri: config.baseUrl + routes.transactions.put.update.replace(':idOrRef', idOrRef),
            method: 'PUT',
            headers: {},
            body: params,
            json: true
        };
        options.headers[this.auth.headerName] = this.auth.headerValue;
        if(this.microserviceAuthorizationHeader)
            options.headers["Authorization"] = this.microserviceAuthorizationHeader;

        return rp(options)
            .then(transaction => {
                return new Transaction(transaction);
            })
            .catch(err => {
                return Promise.reject(err);
            })
    }

    validateBatch(batchRequest){
        let options = {
            uri: config.baseUrl + routes.transactionBatches.post.validate,
            method: 'POST',
            body: batchRequest,
            headers: {},
            json: true
        };
        options.headers[this.auth.headerName] = this.auth.headerValue;
        if(this.microserviceAuthorizationHeader)
            options.headers["Authorization"] = this.microserviceAuthorizationHeader;

        return rp(options)
            .then(validation => {
                return validation;
            })
            .catch(err => {
                return Promise.reject(err);
            })
    }

    submitBatch(batchRequest){
        let options = {
            uri: config.baseUrl + routes.transactionBatches.post.create,
            method: 'POST',
            body: batchRequest,
            headers: {},
            json: true
        };
        options.headers[this.auth.headerName] = this.auth.headerValue;
        if(this.microserviceAuthorizationHeader)
            options.headers["Authorization"] = this.microserviceAuthorizationHeader;

        return rp(options)
            .then(batch => {
                return new TransactionBatch(batch);
            })
            .catch(err => {
                return Promise.reject(err);
            })
    }

    createTransactionFromParams(params){
        let options = {
            uri: config.baseUrl + routes.transactions.post.create,
            method: 'POST',
            headers: {},
            body: params,
            json: true
        };
        options.headers[this.auth.headerName] = this.auth.headerValue;
        if(this.microserviceAuthorizationHeader)
            options.headers["Authorization"] = this.microserviceAuthorizationHeader;

        return rp(options)
            .then(transaction => {
                return new Transaction(transaction);
            })
            .catch(err => {
                return Promise.reject(err);
            })
    }

    voidTransaction({idOrRef, comment}){
        let params = {
            action: 'void',
        };
        if(comment)
            params.comment = comment;

        let options = {
            uri: config.baseUrl + routes.transactions.put.update.replace(':idOrRef', idOrRef),
            method: 'PUT',
            headers: {},
            body: params,
            json: true
        };
        options.headers[this.auth.headerName] = this.auth.headerValue;
        if(this.microserviceAuthorizationHeader)
            options.headers["Authorization"] = this.microserviceAuthorizationHeader;

        return rp(options)
            .then(transaction => {
                return new Transaction(transaction);
            })
            .catch(err => {
                return Promise.reject(err);
            })
    }

    getCountersForProductId({productId, onDate=null}){
        let options = {
            uri: config.baseUrl + routes.counters.get.forProduct.replace(':productId', productId),
            method: 'GET',
            headers: {},
            json: true
        };
        options.headers[this.auth.headerName] = this.auth.headerValue;

        if(onDate) {
            const date = new moment(onDate);
            options.uri += `?onDate=${date.format("YYYY-MM-DD")}`
        }

        return rp(options)
            .then(counters => {
                return new ProductCounters(counters);
            })
            .catch(err => {
                return Promise.reject(err);
            })
    }
}

function _errorToString(err){
    if(typeof err.error === "string")
        return err.error;
    else if(typeof  err.message === "string")
        return err.message;
    else
        return JSON.stringify(err);
}

export { Transactions };