import React, {Component} from 'react';
import {Input, Badge, Row, Col, Card, Button, Alert,  FormFeedback, FormGroup, ListGroup, Table, ListGroupItem } from 'reactstrap';
import client from './service/ImClientSingleton'
import UserSelectDropdown from "./atoms/UserSelectDropdown";

class PackingBatch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            manualCount: false,
            count: 0,
            selectedMarketName: null, //TODO Not state
            shipReadyTransactionType: null,  //TODO Not state
            selectedFnsku: null,
            outOfSequenceFnskuErrorInd: false,
            transactionSavedMessage: "",
            transactionSavedError: "",
            selectedUser: null,
            currentUser: null,
            pendingOrderChecked: false
        };
    }

    getSelectedMarketpalceIdForFnsku(fnsku){
        if(!fnsku) return null;

        let marketplaceIds = this.props.product.key.searchIndex[fnsku];
        if(!marketplaceIds) return null;

        if(marketplaceIds.length > 1) {
            throw new Error(`Multiple marketplaces found for FNSKU ${fnsku}`);
        }
        if(marketplaceIds.length === 1)
            return  marketplaceIds[0];
       return null;
    }

    getShipReadyCountForCurrentFnsku(){
        let marketplaceId = this.getSelectedMarketpalceIdForFnsku(this.state.selectedFnsku);

        let counterName;
        if (marketplaceId === client.US_PARENT_MARKETPLACE_ID)
            counterName = "us-shipReady";
        else if (marketplaceId === client.EU_PARENT_MARKETPLACE_ID)
            counterName = "eu-shipReady";

        return this.props.product.counters.counters[counterName] ? this.props.product.counters.counters[counterName] : 0;
    }

    getTransactionTypeForCurrentFnsku(){
        let marketplaceId = this.getSelectedMarketpalceIdForFnsku(this.state.selectedFnsku);

        if (marketplaceId === client.US_PARENT_MARKETPLACE_ID)
            return "SHIP-RDY-US";
        else if (marketplaceId === client.EU_PARENT_MARKETPLACE_ID)
            return"SHIP-RDY-EU";
    }

    getMarketplaceNameForCurrentFnsku(){
        let marketplaceId = this.getSelectedMarketpalceIdForFnsku(this.state.selectedFnsku);

        if (marketplaceId === client.US_PARENT_MARKETPLACE_ID)
            return "US Marketplace";
        else if (marketplaceId === client.EU_PARENT_MARKETPLACE_ID)
            return"EU Marketplace";
    }

    getPendingOrders(){
        return this.props.product.counters.counters.pendingOrders ? this.props.product.counters.counters.pendingOrders : 0;
    }

    selectMarketByFnsku(fnsku) {
        let errorMessage = null;
        let selectedFnsku = null;

        let marketplaceId = this.getSelectedMarketpalceIdForFnsku(fnsku);

        if (!marketplaceId)
            errorMessage = `FNSKU '${fnsku}' is not valid for K${this.props.product.key.id}`;
        else if (marketplaceId === client.US_PARENT_MARKETPLACE_ID || marketplaceId === client.EU_PARENT_MARKETPLACE_ID)
            selectedFnsku = fnsku;
        else if(!errorMessage)
            errorMessage = `Unknown Marketplace Id ${marketplaceId} for ${fnsku}`;

        if(errorMessage)
            PackingBatch.playErrorSound();

        this.setState({
            searchText: "",
            selectedFnskuErrorMessage: errorMessage,
            selectedFnsku: selectedFnsku,
            count: 0,
            manualCount: false
        })
    }


    validateAndIncCountByFnsku(fnsku){
        if(fnsku !== this.state.selectedFnsku){
            PackingBatch.playErrorSound();
            this.setState({
                searchText: "",
                outOfSequenceFnskuErrorInd: true
            });
            return;
        }
        this.setState({
            count: this.state.count + 1,
            outOfSequenceFnskuErrorInd: false,
            searchText: ""
        });
    }

    handleChange(e){
        if(e.target.value.length >= 10){
            if(!this.state.selectedFnsku)
                this.selectMarketByFnsku(e.target.value);
            else
                this.validateAndIncCountByFnsku(e.target.value);
        }
        else{
            this.setState({
                searchText: e.target.value,
            });
        }
    }

    manualCountChange(e){
        this.setState({
            count: parseInt(e.target.value),
        });
    }

    enterManualMode(){
        this.setState({
            manualCount: true
        })
    }

    resetBatch(){
        this.setState({
            manualCount: false,
            count: 0,
            selectedFnsku: null,
            outOfSequenceFnskuErrorInd: false
        })
    }

    decrementCount(){
        let count = this.state.count -1;
        if(count < 0) count = 0;
        this.setState({
            count: count
        })
    }

    submitBatch(){
        const params = {
            typeName: this.getTransactionTypeForCurrentFnsku(),
            productId: this.props.product.key.id,
            itemCount: this.state.count,
            warningsToIgnoreArray: ["us-packRequest", "eu-packRequest"]
        };
        if(this.state.currentUser.id !== this.state.selectedUser.id)
            params.autoReassignUserId = this.state.selectedUser.id;
        client.createTransaction(params)
            .then(transaction => {
                this.setState({
                    transactionSavedMessage: `Transaction with ID ${transaction.ref} saved successfully (${transaction.user.username})`,
                    count: 0
                });
                this.setClearTransactionMessageTimeout();
            })
            .catch(err => {
                console.log(err);
                let errorMessageArray = err.error.validation.message.split('* ').slice(1); //Cut into array. Remove first as it will be a false positive
                this.setState({transactionSavedError: errorMessageArray.map((message, index) => <li key={index}>{message}</li>)});
                this.setClearTransactionMessageTimeout();
            })
    }

    setClearTransactionMessageTimeout(){
        setTimeout(() => {
            this.setState({
                transactionSavedMessage: "",
                transactionSavedError: ""
            }); }, 15000);
    }

    static playErrorSound(){
        let audio = new Audio('http://soundbible.com/mp3/Computer%20Error%20Alert-SoundBible.com-783113881.mp3');
        audio.type = 'audio/mp3';
        return audio.play(); //Promise
    }

    renderSelectedFnskuArea(){
        if(this.state.selectedFnskuErrorMessage){
            return <Col sm={6} md={6} lg={8} className="fnsku-top-alert-pad">
                <Alert color="danger">
                {this.state.selectedFnskuErrorMessage}
                </Alert>
            </Col>
        }
        else if(!this.state.selectedFnsku){
            return <Col sm={6} md={6} lg={8} className="fnsku-top-alert-pad">
                <Alert color="info" >
                No FNSKU Selected
                </Alert>
            </Col>
        }
        else{
            return [
                <Col sm={2} md={2} lg={2} className="fnsku-top-pad">
                    <h4 className="middle pt-1">{this.state.selectedFnsku}</h4>
                </Col>,
                <Col sm={2} md={2} lg={3} className="d-flex justify-content-start fnsku-top-pad">
                    <h4 className="pt-1"><Badge color="info" className="justify-content-start">{this.getMarketplaceNameForCurrentFnsku()}</Badge></h4>
                </Col>,
                <Col sm={2} md={2} lg={3} className="fnsku-top-pad">
                    {!this.state.manualCount && <Button color="warning" onClick={e => this.enterManualMode()}>Manual Count</Button>}
                    {this.state.manualCount && <h4 className="pt-1"><Badge color="warning" pill>Manual Mode Enabled</Badge></h4>}
                </Col>
            ]
        }
    }

    render() {
        return <div>
            <Row >
                <Col sm={6} md={6} lg={4}>
                    <h5>Scan FNSKU Here </h5>
                    <FormGroup>
                        <Input invalid={this.state.outOfSequenceFnskuErrorInd} placeholder="FNSKU" bsSize="lg"
                                      value={this.state.searchText}
                                      onChange={e => this.handleChange(e)}/>
                        <FormFeedback tooltip>FNSKU Mismatch. Check FNSKU Labels</FormFeedback>
                    </FormGroup>
                </Col>
                {this.renderSelectedFnskuArea()}
            </Row>
            <Row className="pt-2">
                <Col className="col-md-4">
                    <Card body style={{ backgroundColor: '#b5cfff', borderColor: '#333' }}>
                        <h3>Current Batch Count</h3>
                        {!this.state.manualCount && <h1 className="center-text">{this.state.count}</h1>}
                        {this.state.manualCount && <h1><Input className="middle" type="number" placeholder="Manual Count" style={{fontSize: "32px"}}
                                                              min={0}Current Batch Count
                                                              value={this.state.count} onChange={e => this.manualCountChange(e)} /></h1>}
                        <Row className="d-flex justify-content-center">
                            <Button outline color="danger" onClick={e => this.resetBatch()} size="sm" className="mr-2">Reset</Button>
                            <Button outline color="danger" onClick={e => this.decrementCount()} size="sm">-1</Button>
                        </Row>
                    </Card>
                </Col>
                <Col className="col-md-4">
                    <Card body>
                        <h3>New Inventory</h3>
                        <h1 className="center-text">{this.props.product.counters.counters.inventory - this.state.count}</h1>
                    </Card>
                </Col>
                {this.state.selectedFnsku && <Col className="col-md-4">
                    <Card body>
                        <h3>New Ship Ready</h3>
                        <h1 className="center-text">{this.getShipReadyCountForCurrentFnsku() + this.state.count}</h1>
                    </Card>
                </Col>}
            </Row>
            <Row className="pt-2">
                <Col lg={4} className="d-flex justify-content-center">
                    <Button color="success" size="lg"
                            disabled={this.state.count === 0 || (this.getPendingOrders() > 0 && !this.state.pendingOrderChecked)}
                            onClick={e => this.submitBatch()} style={{fontSize: "calc(12px + 1.25vw)", "max-height": "4rem", "white-space": "nowrap"}}>Submit Batch</Button>
                </Col>
                <Col className="d-flex justify-content-start">
                    { this.getPendingOrders() > 0 && this.state.count > 0 &&
                    <Alert className="mt-3" color="warning">
                       There are pending orders for this product. Verify enough products remain in inventory and reserve to fill all pending orders. <br/>
                       <Input checked={this.state.pendingOrderChecked} onClick={e => {
                           console.log(e);
                           this.setState({pendingOrderChecked: !this.state.pendingOrderChecked})
                       }}  type="checkbox" className="ml-2" />{' '}
                        <span className="ml-4">Confirmed</span>
                    </Alert>
                    }
                    { this.state.transactionSavedMessage &&
                        <Alert className="mt-3" color="success">
                            {this.state.transactionSavedMessage}
                        </Alert>
                    }
                    { this.state.transactionSavedError &&
                        <Alert className="mt-3" color="danger">
                            {this.state.transactionSavedError}
                        </Alert>
                    }
                </Col>
            </Row>
            <Row className="pt-2 mb-1">
                <Col lg={4}>
                    <UserSelectDropdown userId={this.state.selectedUser ? this.state.selectedUser.id : null}
                                        onUserChange={({user, currentUser}) => {
                                            const newState = {selectedUser: user};
                                            if(currentUser)
                                                newState.currentUser = currentUser;
                                            this.setState(newState);
                                        }}/>
                </Col>
            </Row>
        </div>

    }
}

export default PackingBatch;
