import { UserRef } from './UserRef';
import { Comment } from './Comment';
import { TransactionType } from './TransactionType';
import { TransactionTypeReference } from './TransactionTypeReference';
import { Payment } from './Payment';
import { ErrorWithStatusCode } from './errors/ErrorWithStatusCode';
import { APPROVAL_STATES } from '../domain/TransactionApprovalStates';

class Transaction {
    constructor({
                    id, _id, ref, meta, type, product, dateTime, user, itemCount, reassignment, payment, voided,
                    comments, approval, ignoreAllWarningsInd, warningsToIgnoreArray
                }) {
        //Get the type object - Either full type or only reference depnding on what has been provided
        let typeObj;
        if(type.hasOwnProperty('description')) //This is a full type
            typeObj = type instanceof TransactionType ? type : new TransactionType(type);
        else //Use ref only
            typeObj = type instanceof TransactionTypeReference ? type : new TransactionTypeReference(type);

        this.id = null;
        if(_id) this.id = _id.toString();
        if(id) this.id = id;

        if(ref && typeof ref !== "number")
            throw new Error(`Invalid ref transaction ref ${ref}. Must be int`);
        this.ref = ref;
        this.type = typeObj;
        this.user = user instanceof UserRef ? user : new UserRef(user);

        this.product = product;
        if (typeof product.id !== "number" || !product.shortName)
            throw new Error(`Invalid product information for transaction id ${this.id || this.ref}`);

        this.itemCount = itemCount;
        if (typeof itemCount !== "number" || !Number.isInteger(itemCount) || itemCount < 0) {
            throw "Item count must be a positive integer or zero";
        }

        if(meta != null) {
            if (typeof meta !== 'object')
                throw new Error(`Invalid data for meta. Must be an object for transaction id ${this.id || this.ref}`);
            this.meta = meta;
        }
        else
            this.meta = {};

        if(dateTime)
            this.dateTime = new Date(dateTime);
        else
            this.dateTime = new Date();

        //Optional
        if(reassignment) {//{originalUserId, reassignedByUserId, dateTime}
            if(reassignment.originalUserId && reassignment.reassignedByUserId && reassignment.dateTime) {
                reassignment.dateTime = new Date(reassignment.dateTime);
                this.reassignment = reassignment;
            }
            else if(ref == null && reassignment.newUserId) //Allow reassign on create
                this.reassignment = {newUserId: reassignment.newUserId};
            else {
                //Only throw if one of the values was provided
                if(reassignment.originalUserId || reassignment.reassignedByUserId || reassignment.dateTime )
                    throw new Error(`Invalid reassignment object provided for transaction id ${this.id || this.ref}`);
            }
        }

        //Optional
        if(voided){
            if(!voided.userId || !voided.date)
                throw new Error(`Invalid voided object provided for transaction id ${this.id || this.ref}`);
            this.voided = voided;
        }

        //Optional
        if(payment){ //Since a calculation is performed based on item count a new object should always be instantiated
            let paymentInput = payment;
            if(payment.amount.total){ //When creating a new transaction remove a preset amount. This prevents exception
                let amountClone = Object.assign({}, payment.amount);
                paymentInput = Object.assign({}, payment);
                paymentInput.amount = amountClone;
                delete paymentInput.amount.total;
            }
            this.payment = new Payment({paymentInput: paymentInput, transactionRef: this.ref, itemCount: this.itemCount});
        }

        //Optional - Approval
        if(approval){
            if(!approval.hasOwnProperty("approved") || typeof approval.approved !== "boolean")
                throw new Error(`Invalid approval object provided for transaction id ${this.id || this.ref}`);
            this.approval = {
                approved: approval.approved
            };
            if(approval.userId || approval.date) {
                if(!approval.userId || !approval.date)
                    throw new Error(`Invalid approval information provided for transaction id ${this.id || this.ref}`);
                if(typeof approval.userId !== "string")
                    throw new Error(`Invalid userId provided for transaction id ${this.id || this.ref}`);
                this.approval.userId = approval.userId;
                this.approval.date = new Date(approval.date);

                this.approval.autoApprovalInd = !!approval.autoApprovalInd;
            }
        }
        else if (!this.id && this.type.rules.approvalRequired){ //Add approval to this new transaction
            this.approval = {
                approved: false
            }
        }

        this.comments = [];
        if(comments != null) {
            if (!Array.isArray(comments))
                throw "comments must be an array";

            comments.forEach(comment => {
                if (typeof comment !== "object")
                    throw new Error("Each comment in the comments array must be an object");
                let commentObj = comment instanceof Comment ? comment : new Comment(comment);
                this.comments.push(commentObj);
            });
        }

        //Warning Handling - The warning params (ignoreAllWarningsInd warningsToIgnoreArray) only used for new transactions and are never stored in db
        this.updateWarnings({ignoreAllWarningsInd: ignoreAllWarningsInd, warningsToIgnoreArray: warningsToIgnoreArray});

    }

    cloneTransactionWithNewItemCount(newItemCount){
        let params = Object.assign({}, this);
        params.itemCount = newItemCount;
        return new Transaction(params);
    }

    /**
     * Returns generic js object (Not instance of transaction) with fields that should not be sent to the db
     * removed. This includes the id and warning. It also converts the type to a type reference for storage
     * @returns {Transaction.res}
     */
    getTransactionForDb(){
        let {["id"]:omit, ...res} = this;
        delete res.warning;
        res.user = this.user.getUserRefForDb();
        res.type = res.type.getTransactionTypeReference();
        return res;
    }

    toJSON(){
        let {...res} = this;
        delete res.warning;
        res.user = this.user.getUserRefForDb();
        res.type = res.type.getTransactionTypeReference();
        return res;
    }

    addComment({comment, userId}){
        if(typeof comment !== 'string')
            throw new ErrorWithStatusCode({code: 400, message: `comment must be provided and must be type string`});
        if(typeof userId !== 'string')
            throw new ErrorWithStatusCode({code: 400, message: `userId must be provided and must be type string`});
        let commentObj = new Comment({comment: comment, userId: userId});
        this.comments.push(commentObj);
    }

    updateApproval({approvalUserId, approvedInd, comment, autoApprovalInd=false}){
        if(!this.approval)
            throw new ErrorWithStatusCode({code: 409, message:`Transaction is not approvable. Approval cannot be updated for ${this.ref}`});
        if(this.approval.date || this.approval.userId)
            throw new ErrorWithStatusCode({code: 409, message: `Transaction has already been approved. Approval cannot be changed. Please void transaction if needed.`});
        if(this.approval.approvalUserId)
            throw new ErrorWithStatusCode({code: 409, message: `Approval decision has already been made for transaction ${this.ref}. Update not possible`});
        if(!approvalUserId || approvedInd == null)
            throw new ErrorWithStatusCode({code: 400, message: `updateApproval requires an approvalUserId and approvedInd. Comment is optional`});

        //When the transaction is new (no id) AND approval user id DOES NOT EQUAL transaction user id WHEN manually approving THEN throw error
        if(!this.id && approvalUserId !== this.user.id && !autoApprovalInd)
            throw new ErrorWithStatusCode({code: 400, message:
                    `Transaction created as approved must be approved by the same user that is creating the transaction. approvalUserId: ${approvalUserId}, userId: ${this.user.id}`});

        if(comment) {
            let commentObj = new Comment({comment: `Approval: ${comment}`, userId: approvalUserId});
            this.comments.push(commentObj);
        }

        this.approval.userId = approvalUserId;
        this.approval.approved = approvedInd;
        this.approval.autoApprovalInd = autoApprovalInd;
        //If transaction has not been saved in db the dateTime should be the same as the save dateTime
        this.approval.date = this.id == null ? this.dateTime : new Date();
    }

    getApprovalState(){
        if(!this.approval)
            return APPROVAL_STATES.find(state => state.code === "N/A");
        if(this.voided)
            return APPROVAL_STATES.find(state => state.code === "VOIDED");
        else if(this.approval.approved)
            return APPROVAL_STATES.find(state => state.code === "APPROVED");
        else if(this.approval.date)
            return APPROVAL_STATES.find(state => state.code === "DECLINED");
        else
            return APPROVAL_STATES.find(state => state.code === "PENDING");
    }

    canReassign(){
        if(this._paymentsProcessed())
            return {canReassign: false, message: `Cannot reassign transaction ${this.ref} because it has processed payments`};
        return {canReassign: true};
    }

    reassign({newUserObj, reassignedByUserId, comment}){
        let canReassignStatus = this.canReassign();
        if(!canReassignStatus.canReassign)
            throw canReassignStatus.message;

        if(!this.id)
            throw new ErrorWithStatusCode({code: 400, message: `This transaction cannot be reassigned because it is not persisted and does not have an id set.`});
        if(!(newUserObj instanceof UserRef)) {
            try {
                newUserObj = new UserRef(newUserObj);
            }
            catch (e) {
                throw new Error(`newUserObj must be instance of User for ${this.ref}. Error: ${e.message}`);
            }
        }

        if(comment) {
            let commentObj = new Comment({comment: `Reassign: ${comment}`, userId: reassignedByUserId});
            this.comments.push(commentObj);
        }

        this.reassignment = {
            originalUserId: this.user.id,
            reassignedByUserId: reassignedByUserId,
            dateTime: new Date()
        };
        this.user = newUserObj;
    }

    canVoidStatus(){
        if(this._paymentsProcessed())
            return {canVoid: false, message: `Cannot void transaction ${this.ref} because it has processed payments`};
        if(this.voided)
            return {canVoid: false, message: `Cannot void transaction ${this.ref} because it has already been voided`};
        return {canVoid: true};
    }

    void({voidedByUserId, comment}){
        let canVoidStatus = this.canVoidStatus();
        if(!canVoidStatus.canVoid)
            throw new ErrorWithStatusCode({code: 409, message: canVoidStatus.message});
        if(comment) {
            let commentObj = new Comment({comment: `Void: ${comment}`, userId: voidedByUserId});
            this.comments.push(commentObj);
        }
        this.voided = {date: new Date(), userId: voidedByUserId};
    }

    updateWarnings({ignoreAllWarningsInd, warningsToIgnoreArray}){
        if(ignoreAllWarningsInd || warningsToIgnoreArray){
            if(ignoreAllWarningsInd == null) ignoreAllWarningsInd = false;
            else{
                if(typeof ignoreAllWarningsInd !== "boolean")
                    throw new ErrorWithStatusCode({code: 400, message: `ignoreAllWarningsInd must be a boolean for ${this.id || this.ref}`});
            }
            if(warningsToIgnoreArray == null) warningsToIgnoreArray = [];
            else{
                if(!Array.isArray(warningsToIgnoreArray))
                    throw new ErrorWithStatusCode({code: 400, message: `warningsToIgnoreArray must be an Array for ${this.id || this.ref}`});
            }
            this.warning = {
                ignoreAllWarnings: ignoreAllWarningsInd,
                warningsToIgnore: warningsToIgnoreArray
            }
        }
    }

    isValid(){
        return !(this.voided || (this.approval && !this.approval.approved));
    }

    isFullTransactionTypeSet(){
        return this.type instanceof TransactionType;
    }

    overrideTypeReferenceWithFullType(type){
        if(this.type instanceof TransactionType)
            throw new ErrorWithStatusCode({code: 400, message: "Full transaction type already set"});
        if(!(type instanceof TransactionType))
            throw new ErrorWithStatusCode({code: 500, message: "type must be instance of TransactionType"});
        if(this.type.name !== type.name)
            throw new ErrorWithStatusCode({code: 400, message: `The type of a transaction cannot be changed. Only a type reference may be upgraded to a full type. This (Ref)=${this.type.name}, Type=${type.name}`});
        this.type = type;
    }

    toRefString({chalk}={}){
        let autoApprovalStr = "Automatically Approved";
        let approvedStr = "Approved";
        let declinedStr = "Declined";
        let approvalNeededStr = "Approval Needed";
        if(chalk){
            autoApprovalStr = chalk.green.underline(autoApprovalStr);
            approvedStr = chalk.green(approvedStr);
            declinedStr = chalk.red(declinedStr);
            approvalNeededStr = chalk.blue(approvalNeededStr);
        }

        let metaString = "";
        let approvalString = "";
        let voidString = "";
        for (let prop in this.meta) {
            if(!this.meta.hasOwnProperty(prop)) continue;
            if(typeof this.meta[prop] === 'object')
                metaString = `${metaString}, ${prop}: ${JSON.stringify(this.meta[prop])}`;
            else
                metaString = `${metaString}, ${prop}: ${this.meta[prop]}`
        }
        if(this.approval){
            if(!this.approval.userId) //No decision made
                approvalString = `, ${approvalNeededStr}`;
            else if(this.approval.approved)
                approvalString = `, ${this.approval.autoApprovalInd ? _fixColors(autoApprovalStr) : approvedStr}`;
            else
                approvalString = `, ${declinedStr}`;
        }
        if(this.voided){
            voidString = `, ${chalk.red('VOID')}`
        }
        return `K${this.product.id}, ${this.type.name} ${this.itemCount}x, ${this.user.username}${approvalString}${voidString}${metaString}`;
    }

    _paymentsProcessed(){
        if(!this.hasOwnProperty('payment'))
            return false;
        return this.payment && this.payment.hasOwnProperty("invoiceId"); //Voidable/can be reassigned as long as there is no paid payment
    }
}

function _fixColors(string){
    return string.replace(/\\033\[([0-9]+)m\\033\[([0-9]+)m/g, "\\033[$1;$2m");
}

export { Transaction };