import React, {Component} from 'react';
import PropTypes from "prop-types";
import Button from "reactstrap/es/Button";
import transactionsClient from "../../client/transactionsClient"
import {TransactionVoidDialog} from "../molecules/TransactionVoidDialog";


class TransactionVoidButton extends Component {
    state = {
        voidDialogOpen: false
    };

    toggle(){
        this.setState({voidDialogOpen: !this.state.voidDialogOpen});
    }

    componentDidUpdate(prevProps) {
        const {transaction} = this.props;
    }

    render() {
        const {transaction} = this.props;
        if(!transaction || transaction.voided)
            return "";

        return <div>
            <Button size="sm" onClick={() => this.toggle()} className={this.props.className} color="danger" >Void Transaction</Button>
            {this.state.voidDialogOpen && <TransactionVoidDialog transaction={transaction} toggle={() => this.toggle()} />}
        </div>
    }
}

TransactionVoidButton.propTypes = {
    transaction: PropTypes.object,
    onVoid: PropTypes.func
};

export {TransactionVoidButton};
