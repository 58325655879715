import React, {Component} from 'react';
import {Input, Badge, Row, Col, Card, Button, Alert,  FormFeedback, FormGroup, ListGroup, Table, ListGroupItem } from 'reactstrap';
import UserSelectDropdown from "../atoms/UserSelectDropdown";
import {KitComponentTable} from "../molecules/tables/KitComponentTable";
import PropTypes from "prop-types";
import AppContext from "../../context/AppContext";
const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
});

class KitBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: null
        };
    }

    countChange(e){
        this.setState({
            count: parseInt(e.target.value),
        });
    }

    onSubmit(){
        if(this.props.onSubmit)
            this.props.onSubmit({count: this.state.count});
    }


    render() {
        const {kit} = this.props;
        if(!kit)
            return "";

        return ( <AppContext.Consumer>
            {({user}) => (
                <div>
                    <Row className="pt-2 mb-1">
                        {user.groups.includes("IM_FINANCES") && <Col><Badge color="info"><span style={{"font-size": "20px"}}> Unit Cost: {usdFormatter.format(kit.unitCost)}</span></Badge></Col>}
                        <Col><Badge color="primary">Items in Kit: {kit.itemsInKitCount}</Badge></Col>
                        <Col><Badge color="primary">Available to Build: {kit.availableToBuildCount}</Badge></Col>
                    </Row>
                    <Row className="pt-2 mb-1">
                        <KitComponentTable components={this.props.kit ? this.props.kit.componentsMap : []} hideUnitCost={!user.groups.includes("IM_FINANCES")}/>
                    </Row>
                    {kit.availableToBuildCount > 0 && <Row>
                            <Col ><h4 className="float-right mt-1">Kits to Build Count</h4></Col>
                            <Col><h3><Input className="middle" type="number" placeholder="Kits to Build Count" style={{fontSize: "18px"}}
                                                                  min={0}
                                                                  max={kit.availableToBuildCount}
                                                                  value={this.state.count} onChange={e => this.countChange(e)} /></h3></Col>
                            <Col><Row className="d-flex float-left mt-1">
                                <Button color="success" disabled={this.state.count === null || this.state.count === 0} className="mr-2" onClick={e => this.onSubmit()} size="sm">Submit</Button>
                                <Button outline color="danger" onClick={e => this.setState({count: 0})} size="sm" >Reset</Button>
                            </Row></Col>
                    </Row>}
                    {kit.availableToBuildCount <= 0 && <Row>
                        <h4 className="float-right mt-1 font-italic">Not enough components to build any kits</h4>
                    </Row>}
                </div> )}
                </AppContext.Consumer>
        );
    }
}

KitBuilder.propTypes = {
    product: PropTypes.object.isRequired,
    kit: PropTypes.object.isRequired,
    onSubmit: PropTypes.func
};

export default KitBuilder;
