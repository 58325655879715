import React, {Component} from 'react';
import PropTypes from 'prop-types';
import transactionsClient from "../../client/transactionsClient";
import eventClient from '../service/EventsClientSingleton'
import imClient from "../service/ImClientSingleton"
import {TransactionDetail} from "../organisms/TransactionDetail";
import {TransactionVoidBadge} from "../atoms/TransactionVoidBadge";
import {TransactionApprovalBadge} from "../atoms/TransactionApprovalBadge";
import {Row, Col} from 'reactstrap';
import {TransactionCommentsTable} from "../atoms/TransactionCommentsTable";
import ReactJson from "react-json-view";
import Counters from "../product/Counters";
import {TransactionMetaLinks} from "../atoms/TransactionMetaLinks";

class TransactionPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            transaction: null,
            currentCounters: null,
            onDateCounters: null,
            product: null
        };

        eventClient.subscribe((e) => this.handleTransactionUpdate(e))
    }

    handleTransactionUpdate(e){
        if(e.type === "transaction"){
            if(this.state.transaction && e.data.ref === this.state.transaction.ref){
                this.setState({transaction: e.data});
            }
        }
    }

    componentDidMount() {
        this.refreshSate();
    }

    refreshSate(){
        transactionsClient.transactions.getTransactionByIdOrRef(this.props.match.params.transactionId)
            .then(async transaction => {
                await this.setState({transaction});
                return this.getCountersAndProduct();
            })
            .catch(err => {
                this.setState({transaction: null});
                console.log(err);
            });

    }

    getCountersAndProduct(){
        //Get Current Counters
        transactionsClient.transactions.getCountersForProductId({productId: this.state.transaction.product.id})
            .then(async counters => {
                return this.setState({currentCounters: counters});
            })
            .catch(err => {
                this.setState({currentCounters: null});
                console.log(err);
            });

        transactionsClient.transactions.getCountersForProductId({productId: this.state.transaction.product.id, onDate: this.state.transaction.dateTime})
            .then(async counters => {
                return this.setState({onDateCounters: counters});
            })
            .catch(err => {
                this.setState({onDateCounters: null});
                console.log(err);
            });

        imClient.getProductById(this.state.transaction.product.id)
            .then(async product => {
                return this.setState({product});
            })
            .catch(err => {
                this.setState({product: null});
                console.log(err);
            });
    }

    renderContent(){
        const transaction = this.state.transaction;
        if(!transaction)
            return "";
        return <div>
            <Row>
                <Col md={4} lg={3}>
                    <TransactionDetail transaction={transaction}/>
                </Col>
                <Col md={4} lg={3}>
                    <Counters heading="Current Counters" size="sm" counters={this.state.currentCounters} product={this.state.product}/>
                </Col>
                <Col md={4} lg={3}>
                    <Counters heading={`Counters on ${new Date(this.state.transaction.dateTime).toLocaleDateString()}`}
                              size="sm"  counters={this.state.onDateCounters} product={this.state.product}/>
                </Col>
                <Col>
                    <h4 className="text-left">Links</h4>
                    <TransactionMetaLinks transaction={transaction}/>
                </Col>
            </Row>
            <Row>
                <Col className="mt-1" md={6}>
                    <h4 className="text-left">Meta</h4>
                    <ReactJson src={transaction.meta} theme="monokai" displayObjectSize={false} collapsed={1} />
                </Col>
                <Col>
                    <TransactionCommentsTable transaction={transaction}/>
                </Col>
            </Row>
        </div>
    }

    render() {
        if(this.props.match.params.transactionId === "new")
            return "";

        const transaction = this.state.transaction;
        return (
            <div className="App-Width">
                 <h1 className="d-inline mr-4">Transaction {this.props.match.params.transactionId}&nbsp;</h1>
               <h3 className="d-inline" >
                    <TransactionVoidBadge transaction={transaction} className={"mx-1"}/>&nbsp;
                    <TransactionApprovalBadge transaction={transaction} className={"mx-1"}/>&nbsp;
                </h3>
                {this.renderContent()}
            </div>
        );
    }
}

TransactionPage.propTypes = {
    transactionId: PropTypes.number.isRequired,
};


export default TransactionPage;
