import { Row, Col, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import React from "react";
import PropTypes from 'prop-types';

const ActionAndReturnToPackingButtonsRow = props => {
    return (
        <Row className>
            <Col className="mx-auto center-text">
                {!props.hideSaveButton && <Button color="success" size="lg"
                                                         disabled={props.disabled}
                                                         onClick={e => props.saveOnClick(e)} className="font-medium mr-4">{props.saveLabel}</Button>}
                {props.hideReturnToPacking !== true && <Button outline color="secondary" size="lg"
                        tag={Link} to={`/packing/${props.productId}`} className="font-medium">Return to Packing</Button>}
            </Col>
        </Row>
    )
};

ActionAndReturnToPackingButtonsRow.propTypes = {
    hideSaveButton: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    saveOnClick: PropTypes.func.isRequired,
    saveLabel: PropTypes.string.isRequired,
    productId: PropTypes.number.isRequired
};

export default ActionAndReturnToPackingButtonsRow;