import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, NavLink, ButtonGroup } from 'reactstrap';
import client from '../service/ImClientSingleton'
import { Link } from "react-router-dom";

class LabelButtons extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {};

        this.openUsLabels = this.openUsLabels.bind(this);
        this.openEuLabels = this.openEuLabels.bind(this);
        this.openInventoryLabel = this.openInventoryLabel.bind(this);
    }

    _renderInventoryButton(){
        return <ButtonGroup style={{marginRight: 10}}>
            <Button outline color="secondary" onClick={() => this.openInventoryLabel()}>Inventory Label</Button>
        </ButtonGroup>
    }


    _renderUsButton(){
        return <ButtonGroup style={{marginRight: 10}}>
            <Button outline color="secondary" onClick={() => this.openUsLabels()}>30x US Labels</Button>
        <Button outline  color="secondary" onClick={() => this.openUsLabels("html")}>1x US Label</Button>
        </ButtonGroup>
    }

    _renderEuButton(){
        return this.props.product.marketplaces.hasOwnProperty(client.EU_PARENT_MARKETPLACE_ID) &&
            <ButtonGroup>
                <Button outline color="secondary" onClick={() => this.openEuLabels()}>30x EU Labels</Button>
                <Button outline  color="secondary" onClick={() => this.openEuLabels("html")}>1x EU Label</Button>
            </ButtonGroup>
    }

    render(){
        if(!this.props.product)
            return (
                <div className="row">
                </div>
            );

        return (
                <Row className="justify-content-center" style={{marginTop: "20px"}}>
                    {this._renderUsButton()}
                    {this._renderInventoryButton()}
                    {/*this._renderEuButton()*/}
                </Row>
        );
    }

    openInventoryLabel(){
        let win = window.open(`https://label.kjdelectronics.com/custom/label-printer-callout?callout=${this.props.product.key.kid}&barcode=${this.props.product.key.kid}&line1=${encodeURIComponent(this.props.product.detail.shortName)}&line3=${this.props.product.key.modelNumber}`, '_blank');
        win.focus();
    }

    openUsLabels(format="pdf"){
        let win = window.open(`https://label.kjdelectronics.com/us/${this.props.product.key.kid}.${format}`, '_blank');
        win.focus();
    }

    openEuLabels(format="pdf"){
        let win = window.open(`https://label.kjdelectronics.com/eu/${this.props.product.key.kid}.${format}`, '_blank');
        win.focus();
    }
}

LabelButtons.propTypes = {
    product: PropTypes.object
};

export default LabelButtons;
