import React, {Component} from 'react';
import { FormGroup, Label, Input, Button, CardBody, CardHeader, ListGroup, ListGroupItem, Badge, Row, Col } from 'reactstrap';

const INVENTORY_ADD = "INVENTORY-ADD-FRM-RESV";
const RESERVE_ADD = "STORE-RESV-ADD-FRM-INVENTORY";

class ReserveTransferRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            transferQuantity: 0,
            transactionType: INVENTORY_ADD
        }
    }

    manualCountChange(e){
        let value = parseInt(e.target.value);
        if(value < 0)
            value = 0;

        this.setState({
            transferQuantity: value,
        }, () => this.callTransactionUpdatedCallback());
    }

    callTransactionUpdatedCallback(){
        if(this.props.onTransactionChange)
            this.props.onTransactionChange(this.getTransaction());
    }

    getTransaction(){
        let type = this.state.transactionType;
        if(this.state.transferQuantity === 0) return null;
        return {
            typeName: type,
            itemCount: this.state.transferQuantity,
            productId: this.props.product.key.id
        }
    }

    setTransferAll(){
        this.setState({
            transferQuantity: this.props.product.counters.counters.storeReserved,
            transactionType: INVENTORY_ADD
        }, () => this.callTransactionUpdatedCallback());
    }

    renderTransactionType(){
        if(this.props.savedTransaction)
            return  <Label>{this.props.savedTransaction.type.name}</Label>;
        return  (
            <Input type="select" name="select" id="exampleSelect" value={this.state.transactionType}
                  onChange={e => this.setState({transactionType: e.target.value})}>
                <option value={INVENTORY_ADD}>{INVENTORY_ADD}</option>
                <option value={RESERVE_ADD}>{RESERVE_ADD}</option>
            </Input>);
    }

    renderStatusCol(){
        if(!this.props.saved)
            return "";
        return (<Col>
            <FormGroup>
                <Label><strong>Status</strong></Label>
                <h3 style={{"text-align": "left"}}><Badge color={"success"}>Transaction Saved</Badge></h3>
            </FormGroup>
        </Col>)
    }

    renderAdjustmentInputOrLabel(){
        if(this.props.savedTransaction){
            return  <div className="font-medium">{this.state.transferQuantity}</div>
        }
        else{
            return <div>
                <Row>
                    <Col md={6}>
                        <Input className="middle font-large" type="number" id="reserveTransfer"
                              min={0}
                              value={this.state.transferQuantity}
                              onChange={e => this.manualCountChange(e)} />
                    </Col>
                    <Col md={5}>
                        <Button color="primary" onClick={e => this.setTransferAll()}>Transfer All</Button>
                    </Col>
                </Row>
            </div>
        }
    }

    get inventoryAdjustment(){
        if(this.props.savedTransaction)
            return 0;
        const modifier = this.state.transactionType === INVENTORY_ADD ? 1 : -1;
        return this.transferQuantity * modifier;    }

    get reserveAdjustment(){
        if(this.props.savedTransaction)
            return 0;
        const modifier = this.state.transactionType === RESERVE_ADD ? 1 : -1;
        return this.transferQuantity * modifier;
    }

    get transferQuantity(){
        return !!this.state.transferQuantity ? this.state.transferQuantity : 0;
    }

    render() {
        return (
                <Row>
                    <Col lg={3}>
                        <FormGroup>
                            <Label for="reserveTransfer"><strong>Reserve Transfer</strong></Label>
                            {this.renderAdjustmentInputOrLabel()}
                        </FormGroup>
                    </Col>
                    <Col lg={3}>
                        <FormGroup>
                            <Label><strong>Transaction Type</strong></Label>
                            {this.renderTransactionType()}
                        </FormGroup>
                    </Col>
                    <Col lg={3}>
                        <FormGroup>
                            <Label><strong>New Inventory</strong></Label>
                            <div className="font-large">
                                <strong >{this.props.product.counters.counters.inventory +  this.inventoryAdjustment}</strong>
                                {this.transferQuantity === 0 && !this.props.savedTransaction && " (Unchanged)"}
                            </div>
                        </FormGroup>
                    </Col>
                    <Col lg={3}>
                        <FormGroup>
                            <Label><strong>New Reserve</strong></Label>
                            <div className="font-large">
                                <strong >{this.props.product.counters.counters.storeReserved + this.reserveAdjustment}</strong>
                                <span  className="small align-middle">{this.transferQuantity === 0 && !this.props.savedTransaction  &&  " (Unchanged)"}</span>
                            </div>
                        </FormGroup>
                    </Col>
                    {this.renderStatusCol()}
                </Row>
        );
    }
}

export default ReserveTransferRow;
