import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import {Badge} from "reactstrap";

const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
});
const currency = (cell, row) => usdFormatter.format(cell);


const columns = [
    {
        dataField: 'orderItemId',
        classes: "product-name-small",
        text: 'ID'
    },
    {
        dataField: 'sellerSku',
        text: 'SKU'
    },
    {
        dataField: 'title',
        text: 'Name',
        classes: "product-name-small",
        headerStyle: {"width": "20%"}
    },
    {
        dataField: 'asin',
        text: 'ASIN'
    },
    {
        dataField: 'conditionSubtypeId',
        text: 'Condition',
    },
    {
        dataField: 'quantity',
        text: 'Qty Ordered / Shipped',
        headerStyle: {"width": "15%"}
    },
    {
        dataField: 'shippingTotal',
        text: 'Shipping Total',
        sort: true,
        formatter: currency
    },
    {
        dataField: 'lineTotal',
        text: 'Product Total',
        sort: true,
        formatter: currency
    }
    ];

class MfnOrderItemsTable extends React.Component {
    render() {
        const {order} = this.props;
        const records = order.orderItems.map(oi => {
            const newR = {...oi};
            const complete = oi.quantityOrdered === oi.quantityShipped;

            newR.shippingTotal = oi.shippingPrice && oi.shippingPrice.amount ?  oi.shippingPrice.amount : 0;
            newR.lineTotal = oi.itemPrice && oi.itemPrice.amount ?  oi.itemPrice.amount : 0;
            newR.quantity = <span> {oi.quantityOrdered} / {oi.quantityShipped} <Badge color={complete ? "success" : "warning"} className="ml-1">{complete ? "Complete" : "Pending"} </Badge> </span>

            return newR;
        });

        return (
            <div style={{width: "99%"}}>
                <BootstrapTable condensed={true} bootstrap4={true} keyField='orderItemId' data={ records } columns={ columns } />
            </div> 
            );
    }
}

MfnOrderItemsTable.propTypes = {
    order: PropTypes.object.isRequired,
};

export {MfnOrderItemsTable};