import {round} from 'mathjs';
import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import {Badge, Button, NavItem, NavLink} from 'reactstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from "react-bootstrap-table2-paginator";
import {ManualPackRequestSetter} from "../../atoms/ManualPackRequestSetter";
import {LoadingSpinner} from "../../atoms/LoadingSpinner";
import imClient from "../../service/ImClientSingleton";
import {SupplierOutOfStockCheckbox} from "../../atoms/SupplierOutOfStockCheckbox";

const { SearchBar } = Search;

const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
});

function _mapRecord(r, source) {
    r._source = source;
    return r;
}

class PackRequestManagementTable extends React.Component {

    render() {
        let {products, packRequest} = this.props;
        let combinedPackRequestRecords = [];
        if(packRequest) {
            combinedPackRequestRecords = combinedPackRequestRecords.concat(packRequest.inbound.records.map(r => _mapRecord(r, "inbound")));
            combinedPackRequestRecords = combinedPackRequestRecords.concat(packRequest.lowAndOutOfStock.records.map(r =>  _mapRecord(r, "lowAndOutOfStock")));
            combinedPackRequestRecords = combinedPackRequestRecords.concat(packRequest.noInventoryToPack.records.map(r =>  _mapRecord(r, "noInventoryToPack")));
            combinedPackRequestRecords = combinedPackRequestRecords.concat(packRequest.packing.records.map(r =>  _mapRecord(r, "packing")));
            combinedPackRequestRecords = combinedPackRequestRecords.concat(packRequest.smallPackRequest.records.map(r =>  _mapRecord(r, "smallPackRequest")));
            console.log(combinedPackRequestRecords);
        }

        if(!products || products.length === 0)
            return "";

        products.sort((a, b) => a.key.id - b.key.id);

        const CustomSearchComponent = (props) => {
            let input;
            let timeout;
            const handleChange = (e) => {
                if(timeout)
                    clearTimeout(timeout);
                if(input == null)
                    return;
                const value = input.value;
                const searchFunc = () => imClient.searchForProduct(value)
                    .then(product => {
                        console.log(value);
                        if(product !== null)
                            props.onSearch(product.id);
                        else
                            props.onSearch(value);
                    }).catch(err =>{
                        props.onSearch(value);
                    })
                timeout = setTimeout(searchFunc, 250)
            };
            return (
                <div>
                    <input
                        className="form-control"
                        ref={ n => input = n }
                        type="text"
                        style={{width: "15%"}}
                        placeholder="Search"
                        onChange={ handleChange }
                    />
                </div>
            );
        };

        const records = products.map(i => {
            const newR = {product: i};
            let packRequestRecord = combinedPackRequestRecords.find(r => r.productId === i.key.id);
            newR.packRequestRecord = packRequestRecord;
            newR._packRequest = packRequest;

            newR.id = newR.product.key.id;
            newR.rawId = newR.product.id;
            newR.ranking = packRequestRecord ? packRequestRecord.ranking.overall : "--";
            newR.name = newR.product.detail.shortName;
            newR.currentInbound = getCounter({product: i, name: "inbound"});
            newR.currentInventory = getCounter({product: i, name: "inventory"});
            newR.shipReady = packRequestRecord ? packRequestRecord.planning.shipReady : "--";
            newR.currentFbaStock =  packRequestRecord ? packRequestRecord.planning.currentStock : "--";
            newR.salesQtyPerDayAvg =  packRequestRecord ? Number.parseFloat(packRequestRecord.projection.salesQtyPerDayAvg.toFixed(2)) : "--";
            newR.profitPerDayAvg =  packRequestRecord ? packRequestRecord.finance.profitPerUnitAvg * packRequestRecord.projection.salesQtyPerDayAvg : "--";
            newR.packRequestUnits = packRequestRecord ? packRequestRecord.packRequest.units : "--";
            newR.manualPackRequest = packRequest ? <ManualPackRequestSetter  product={newR.product}
                                                               region={packRequest.request.region}
                                                               manualPackRequest={packRequestRecord ? packRequestRecord.manualPackRequest : false}
                                                               onSaved={() => this.props.onRowUpdated ? this.props.onRowUpdated(newR.id) : null}/> : <LoadingSpinner/>;
            newR.supplierOutOfStock = <SupplierOutOfStockCheckbox  product={newR.product}/> ;

            return newR;
        }).filter(r => {
            if(this.props.showNoPackRequest)
                return true;
            return r.packRequestUnits > 0;
        }).filter( r => {
            if(this.props.showApprovalNeededOnly){
                const health = r.packRequestRecord?.planning?.health;
                if(!health)
                    return true;
                if(health.healthLimited || health.sellThroughLimited || health.weeksOfCoverLimited)
                    return true;
                return false;
            }
            return true;
        })


        let options = {
            sizePerPageList: [ {
                text: '100', value: 100
            },{
                text: '250', value: 250
            },{
                text: '500', value: 500
            }],
        };
        if(records.length <= 10)
            options = null;

        const columns = [
            {
                dataField: 'id',
                text: 'KID',
                formatter: kidFormatter,
                filterValue: (cell) => `K${cell}`,
                headerStyle: () => {
                    return { width: "5%" };
                },
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    if (order === 'asc') {
                        return rowA.product.key.id - rowB.product.key.id;
                    }
                    return rowB.product.key.id - rowA.product.key.id; // desc
                }
            },
            {
                dataField: 'rawId',
                text: 'search',
                sort: false,
                searchable: true,
                hidden: true
            },
            {
                dataField: 'ranking',
                text: 'Rank',
                sort: true,
                headerStyle: () => {
                    return { width: "5%" };
                },
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    let aInt = parseInt(a);
                    let bInt = parseInt(b);

                    if(isNaN(aInt) && isNaN(bInt))
                        return 0;
                    else if(isNaN(aInt))
                        aInt = 999999;
                    else if(isNaN(bInt))
                        bInt = 999999;

                    if (order === 'asc') {
                        return aInt - bInt;
                    }
                    return bInt - aInt; // desc
                }
            },
            {
                dataField: 'name',
                text: 'Name',
                searchable: true,
                sort: true,
                headerStyle: () => {
                    return { width: "25%" };
                }
            },
            {
                dataField: 'currentInbound',
                text: 'Inbound',
                sort: true,
                searchable: false,
                headerStyle: () => {
                    return { width: "8%" };
                }
            },
            {
                dataField: 'currentInventory',
                text: 'Inventory',
                sort: true,
                searchable: false,
                headerStyle: () => {
                    return { width: "8%" };
                }
            },
            {
                dataField: 'shipReady',
                text: 'Ship Ready',
                sort: true,
                searchable: false,
                headerStyle: () => {
                    return { width: "7%" };
                }
            },
            {
                dataField: 'currentFbaStock',
                text: 'FBA Stock',
                sort: true,
                searchable: false,
                headerStyle: () => {
                    return { width: "10%" };
                }
            },
            {
                dataField: 'salesQtyPerDayAvg',
                text: 'Sales/Day Avg',
                sort: true,
                searchable: false,
                headerStyle: () => {
                    return { width: "10%" };
                }
            },
            {
                dataField: 'profitPerDayAvg',
                text: 'Profit/Day Avg',
                formatter: profitFormatter,
                sort: true,
                searchable: false,
                headerStyle: () => {
                    return { width: "10%" };
                }
            },
            {
                dataField: 'packRequestUnits',
                text: 'Pack Request',
                sort: true,
                searchable: false,
                formatter: packRequestFormatter,
                headerStyle: () => {
                    return { width: "10%" };
                }
            },
            {
                dataField: 'product',
                text: 'Health',
                sort: false,
                searchable: false,
                formatter: healthFormatter,
                headerStyle: () => {
                    return { width: "20%" };
                }
            },
            {
                dataField: 'supplierOutOfStock',
                text: 'Supplier Out of Stock',
                sort: false,
                searchable: false,
                headerStyle: () => {
                    return { width: "10%" };
                }
            },
            {
                dataField: 'manualPackRequest',
                text: 'New Pack Request',
                sort: true,
                searchable: false,
                headerStyle: () => {
                    return { width: "12%" };
                }
            },
        ];

        return (
            <div style={{width: "99%"}}>
                <ToolkitProvider
                    keyField="id"
                    data={ records }
                    columns={ columns }
                    search={{

                    }}>
                    {
                        props => (
                            <div>
                                <CustomSearchComponent { ...props.searchProps } />
                                <BootstrapTable
                                    { ...props.baseProps }
                                    condensed={true}
                                    bootstrap4={true}
                                    classes="table-fixed"
                                    rowClasses={ (row, rowIndex) => this.rowClasses(row, rowIndex) }
                                    striped={true}
                                    pagination={ options ? paginationFactory(options) : null }
                                    defaultSorted={[{ dataField: "ranking", order: "asc" }]}
                                />
                            </div>
                        )
                    }

                </ToolkitProvider>
            </div>
        );
    }

    rowClasses(row, rowIndex){
        if(this.props.dirtyRowsByProductId.indexOf(row.id) !== -1)
            return "dirty-pr-row";
    }
}

function kidFormatter(cell){
    const id = cell.id ? cell.id : cell;
    return <a href={`https://sales.kjdelectronics.com/products/${id}`}
              target="_blank">K{cell}</a>
}

function getCounter({product, name}){
    if(!product.counters || !product.counters.counters)
        return 0;
    return product.counters.counters[name] ? product.counters.counters[name] : 0;
}

function profitFormatter(cell, row){
    if(isNaN(cell))
        return "--";
    return <span className="profit-text">{usdFormatter.format(cell)}</span>
}

function newPackRequestFormatter(cell, row){
    return <span className="">{usdFormatter.format(cell)}</span>
}

function packRequestFormatter(cell, row){
    const badge = row.packRequestRecord && row.packRequestRecord.manualPackRequest ? <Badge color="warning" className="ml-2" size="lg">Manual</Badge> : "";
    const ceMarkBadge = row.packRequestRecord && row._packRequest.request.region.toUpperCase() === "EU" &&
        row.product.detail.indicators.euCeMarkRequired ? <Badge color="danger" className="ml-2" size="lg">CE Mark</Badge> : "";
    return <div className="font-weight-bold">
        {Math.round(cell)} {badge} {ceMarkBadge}
    </div>
}

function healthFormatter(cell, row){
    const product = cell;
    const req = row._packRequest;
    const marketplaceId = req ? req.request.parentMarketplaceIdForRegion : null;

    if(!row.packRequestRecord || !row.packRequestRecord.planning)
        return "--";

    const marketplace = product.marketplaces[marketplaceId] ? product.marketplaces[marketplaceId] : null;
    if(!marketplace)
        return "";
    const storageHealth = marketplace.storageHealth;
    if(!storageHealth)
        return "";


    const packRequestHealth = row.packRequestRecord.planning.health;
    const weeksOfCover = storageHealth.weeksOfCover.from90DaySales;

    const warnBadge = <Badge className="ml-2 float-right" color={"danger"}>Warn</Badge>;

    return <div>
        <p className="mt-1 mb-0">Sell Through: {safeRound(storageHealth.sellThrough, 2)}{packRequestHealth.sellThroughLimited ? warnBadge : null}</p>
        <p className="mt-1 mb-0">Cover Weeks (90): {safeRound(weeksOfCover, 2)}{packRequestHealth.weeksOfCoverLimited ? warnBadge : null}</p>
    </div>
}

function safeRound(num, places){
    if(num === "Infinite")
        return "Inf";
    if(Number.isNaN(num) || Number.isNaN(Number.parseFloat(num)))
        return "N/A";
    return round(num, places);
}


PackRequestManagementTable.propTypes = {
    products: PropTypes.array.isRequired,
    packRequest: PropTypes.object,
    showNoPackRequest: PropTypes.bool,
    showApprovalNeededOnly: PropTypes.bool,
    dirtyRowsByProductId: PropTypes.array,
    onRowUpdated: PropTypes.func
};

export {PackRequestManagementTable};