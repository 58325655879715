import React, {Component} from 'react';
import { Card, Alert, Row, Col } from 'reactstrap';
import Counters from "../product/Counters";
import imClient from "../service/ImClientSingleton";
import ActionAndReturnToPackingButtonsRow from "../shared/ActionAndReturnToPackingButtonsRow";
import ReserveTransferRow from "./ReserveTransferRow";


class ReserveTransferDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            transaction: null,
            savedTransaction: null
        }
    }

    save(){
        if(!this.state.transaction){
            throw new Error("Attempt to save without transaction");
        }

        imClient.createTransaction(this.state.transaction)
            .then(transaction => {
                this.setState({
                    savedTransaction: transaction,
                })
            })
            .catch(err => {
                console.error(err);
                alert("Transaction Save Failed. Please check your values");
            })
    }

    setTransaction(t){
        this.setState({
            transaction: t
        });
    }

    getSubmittedAlert(){
        if (!this.state.savedTransaction) return "";
        return  <Row className="d-flex justify-content-center">
            <Alert color="success">
                Transfer Transaction Saved with Transaction ID {this.state.savedTransaction.ref}
            </Alert>
        </Row>
    }

    isSaveValid(){
        if(!this.state.transaction) return false;
        return this.state.transaction.itemCount > 0;
    }

    render() {
        if(!this.props.product)
            return <div></div>;

        return (
            <div>
                <Row >
                    <Col lg={3}>
                        <Counters product={this.props.product}/>
                    </Col>
                    <Col>
                        <h4>Reserve Transfer</h4>
                        <Card body>
                            <ReserveTransferRow product={this.props.product} savedTransaction={this.state.savedTransaction}
                                                onTransactionChange={(transaction => this.setTransaction(transaction))}/>
                            {this.getSubmittedAlert()}
                            <ActionAndReturnToPackingButtonsRow saveLabel="Submit Transaction" saveOnClick={e => this.save()} productId={this.props.product.key.id}
                                                                disabled={!this.isSaveValid()} hideSaveButton={this.state.savedTransaction}/>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ReserveTransferDetails;
