import React, {Component} from 'react';
import {Alert, NavItem, NavLink} from 'reactstrap';
import PropTypes from "prop-types";

class TransactionMetaLinks extends Component {
    render() {
    const meta = this.props.transaction.meta;
    return <ul className={this.props.className}>
                {meta.returnLpn ? <li> <NavLink  href={`https://returns.kjdelectronics.com/lpn/${meta.returnLpn}`} className="pl-1"
                                                 target="_blank">{meta.returnLpn}</NavLink></li> : ""}
                {meta.lotCode ? <li> <NavLink  href={`https://returns.kjdelectronics.com/lots?search=${meta.lotCode}`} className="pl-1"
                                                 target="_blank">{meta.lotCode}</NavLink></li> : ""}
                {meta.invoiceId ? <li> <NavLink  href={`https://sales.kjdelectronics.com/orders?search=${meta.invoiceId}`} className="pl-1"
                                               target="_blank">{meta.invoiceId}</NavLink></li> : ""}
                {meta.shipment ? <li> <NavLink  href={`https://mws-service.kjdelectronics.com/v1/fba/inbound/history/${this.props.transaction.product.id}`} className="pl-1"
                                                 target="_blank">Shipment History</NavLink></li> : ""}
                {meta.poRef ? <li> <NavLink  href={`https://purchasing.kjdelectronics.com/po/view/${meta.poRef}`} className="pl-1"
                                                target="_blank">PO {meta.poRef}</NavLink></li> : ""}
            </ul>
    }
}

TransactionMetaLinks.propTypes = {
    transaction: PropTypes.object.isRequired,
    className: PropTypes.string
};

export {TransactionMetaLinks};
