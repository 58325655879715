import React, {Component} from 'react';
import PropTypes from "prop-types";
import Button from "reactstrap/es/Button";
import transactionsClient from "../../client/transactionsClient"
import {TransactionVoidDialog} from "../molecules/TransactionVoidDialog";
import {TransactionCommentDialog} from "../molecules/TransactionCommentDialog";


class TransactionCommentButton extends Component {
    state = {
        dialogOpen: false
    };

    toggle(){
        this.setState({dialogOpen: !this.state.dialogOpen});
    }

    componentDidUpdate(prevProps) {
        const {transaction} = this.props;
    }

    render() {
        const {transaction} = this.props;
        if(!transaction )
            return "";

        return <div className={this.props.className}>
            <Button size="sm" onClick={() => this.toggle()} className={this.props.className} color="primary" >Add Comment</Button>
            {this.state.dialogOpen && <TransactionCommentDialog transaction={transaction} toggle={() => this.toggle()} />}
        </div>
    }
}

TransactionCommentButton.propTypes = {
    transaction: PropTypes.object,
    onVoid: PropTypes.func,
    className: PropTypes.string
};

export {TransactionCommentButton};
