import React, {Component} from 'react';
import { Badge, Tooltip  } from 'reactstrap';
import PropTypes from "prop-types";
import moment from "moment";
import ssoClient from "../service/SsoClientSingleton"

class TransactionVoidBadge extends Component {
    constructor(props){
        super(props);

        this.state = {
            tooltipOpen: false,
            username: null
        };
        this._id = `VOID-BADGE-${Math.floor(Math.random() * 5000)}`;
    }


    toggle(){
        this.setState({tooltipOpen: !this.state.tooltipOpen});
    }

    get toolTipId(){
        return this._id;
    }

    get user(){
        if(this.state.username)
            return this.state.username;
        return this.props.transaction.voided.userId
    }

    componentDidUpdate(prevProps) {
        const {transaction} = this.props;

        if(!transaction || !transaction.voided)
            return;

        if(this.state.username && prevProps.transaction && transaction.ref === prevProps.transaction.ref)
            return;

        ssoClient.getUserById(transaction.voided.userId)
            .then(user => {
                this.setState({username: user.displayName});
            })
    }

    render() {
        const {transaction} = this.props;
        if(!transaction || !transaction.voided)
            return "";
        const date = new moment(transaction.voided.date);


        return <Badge className={this.props.className} color="danger" id={this.toolTipId}>
          Voided
            <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={this.toolTipId} toggle={() => this.toggle()}>
                {date.format("YYYY/MM/DD HH:mm:ss A")} by {this.user}
            </Tooltip>
        </Badge>
    }
}

TransactionVoidBadge.propTypes = {
    transaction: PropTypes.object
};

export {TransactionVoidBadge};
