export const defaults = {
    imageNotAvailableUrl: "https://education.kjdelectronics.com/image-not-available.png",
    marketplaceInStockDaysTarget: 45,
    marketplaceMinimumStockThreshold: 5,
    productCosts: {
        perItemPackingCommission: 0.25,
        perItemPrepCost: 0,
        perItemShippingCost: 0,
        perItemInventoryManagementCost: 0
    },
    shellDescription: "***This product is a product shell requiring additional information***",
    msrpPrices: {
        USD: null,
        EUR: null,
        GBP: null
    }
};