import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Badge} from "reactstrap";

class TransactionApprovalBadge extends Component {
    get statusColor(){
        const statusCode = this.props.transaction.getApprovalState().code;

        if(statusCode === "PENDING")
            return "warning";
        if(statusCode === "DECLINED")
            return "danger";
        if(statusCode === "APPROVED")
            return "success";
        if(statusCode === "VOIDED")
            return "secondary";
    }

    render() {
        const transaction = this.props.transaction;
        if(!transaction || !transaction.approval)
            return '';
        return <span>
            &nbsp;
            <Badge className={this.props.className} color={this.statusColor}>{transaction.getApprovalState().name}</Badge>&nbsp;
            {transaction.approval.autoApprovalInd && <Badge className={this.props.className} color="success">Automatically Approved</Badge> } &nbsp;
            </span>

    }
}

TransactionApprovalBadge.propTypes = {
    transaction: PropTypes.object,
};

export {TransactionApprovalBadge};
