import { ProductPurchasing } from './ProductPurchasing';
import { ProductCosts } from '../../common/ProductCosts';
import { defaults as DEFAULTS } from '../../../config/options/productDefaults';
import { round } from 'mathjs';

//Since purchasing and costs are from the product level they are assumed to be in base currency
class ProductFinance{
    constructor({purchasing, costs=DEFAULTS.productCosts, msrpPrices=DEFAULTS.msrpPrices}={}) {
        this.purchasing = new ProductPurchasing(purchasing);
        this.costs = new ProductCosts(costs);

        if(Object.keys(msrpPrices).length === 0)
            msrpPrices = DEFAULTS.msrpPrices;
        this.msrpPrices = msrpPrices;
    }

    get combinedTotalCost(){
        return calculateCombinedTotalCost({purchasing: this.purchasing, costs: this.costs});
    }

    get msrpUSD(){
        return this.msrpPrices['USD'];
    }

    /**
     * Sets/Updates both the msrpPrices dict and msrpPricesArray
     * @param currencyCode
     * @param msrpPrice
     */
    setMsrpPriceForCurrency({currencyCode, msrpPrice}){
        if(!msrpPrice) {
            this.msrpPrices[currencyCode.toUpperCase()] = null;
        }
        const parsed = Number(msrpPrice);
        if(isNaN(parsed))
            throw new Error("msrpPrice must be null or a valid number");
        this.msrpPrices[currencyCode.toUpperCase()] = parsed;
    }

    getForDb(){
        let res = Object.assign({}, this);
        res.combinedTotalCost = this.combinedTotalCost;
        return res;
    }

    toJSON(){
        let res = Object.assign({}, this);
        res.combinedTotalCost = this.combinedTotalCost;
        return res;
    }
}

function calculateCombinedTotalCost({purchasing, costs}) {
    return round(purchasing.mostRecentUnitCost + costs.total, 10);
}

export { ProductFinance };