import React, {Component} from 'react';
import { Badge, Row, Col, Card, CardHeader,  CardBody, ListGroup, Table, ListGroupItem } from 'reactstrap';
import Detail from "./Detail";
import Counters from "./Counters";
import PackingBatch from "../PackingBatch";
import KeyAttributeHeader from "./KeyAttributeHeader";


class Product extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if(!this.props.product)
            return <div></div>

        return <div>
            <KeyAttributeHeader product={this.props.product}/>
            <Detail product={this.props.product}/>
            <Row>
                <Col lg={5}>
                    <Counters product={this.props.product} heading={"Counters"}/>
                </Col>
                <Col lg={7}>
                    {this.props.children}
                </Col>
            </Row>
        </div>

    }
}

export default Product;
