import { ProductCounters } from '@kjdelectronics/im-transactions/obj/ProductCounters';
import moment from 'moment';

class ProductDetailManualCount{
    constructor({date, userId, countersSnapshot, discrepancyTransactionBatchId, manualCounts={}}){
        this.date = new Date(date);
        this.userId = userId;
        if(!countersSnapshot)
            throw new Error("countersSnapshot is required for a manual count");
        this.countersSnapshot =  new ProductCounters(countersSnapshot);
        this.manualCounts = {};

        if(manualCounts.hasOwnProperty('inventory'))
            this.manualCounts.inventory = manualCounts.inventory;
        if(manualCounts.hasOwnProperty('storeReserved'))
            this.manualCounts.storeReserved = manualCounts.storeReserved;

        this.discrepancyTransactionBatchId = discrepancyTransactionBatchId;
    }

    getForDb(){
        let {["id"]:omit, ...res} = this;
        res.countersSnapshot = this.countersSnapshot.getForDb();
        return res;
    }

    get toInventoryString(){
        return this._stringHelper("inventory");
    }

    get toReserveString(){
        return this._stringHelper("storeReserved");
    }

    _stringHelper(counterName){
        if(this.manualCounts.hasOwnProperty(counterName))
            return `Manually counted ${this.manualCounts[counterName]} on ${moment(this.date).format('MMM Do YYYY')}`;
        return "";
    }
}

export { ProductDetailManualCount };