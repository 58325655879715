import { rpWrapper as rp } from '../../helper/request.axios.wrapper';
import config from '../config/config';
import routes from '../config/routes';

class Payments{
    constructor({auth, microserviceAuthorizationHeader=null}){
        this.auth = auth;
        this.microserviceAuthorizationHeader = microserviceAuthorizationHeader;
    }

    getMyPayment({noCache=false}={}){
        let options = {
            uri: config.baseUrl + routes.payments.me,
            method: 'GET',
            headers: {},
            json: true
        };
        if(noCache)
            options.uri = options.uri + "?noCache=true";
        options.headers[this.auth.headerName] = this.auth.headerValue;
        return rp(options);
    }

    getPayments(){
        let options = {
            uri: config.baseUrl + routes.payments.get,
            method: 'GET',
            headers: {},
            json: true
        };
        options.headers[this.auth.headerName] = this.auth.headerValue;
        return rp(options);
    }
}

function _errorToString(err){
    if(typeof err.error === "string")
        return err.error;
    else if(typeof  err.message === "string")
        return err.message;
    else
        return JSON.stringify(err);
}

export { Payments };