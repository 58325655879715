import React, {Component} from 'react';
import {Badge, Row, Col, Card, CardHeader, CardBody, ListGroup, Table, ListGroupItem, NavLink} from 'reactstrap';
import client from '../service/ImClientSingleton'
import {Link} from "react-router-dom";
import moment from 'moment';


class Counters extends Component {
    constructor(props) {
        super(props);
    }

    renderPendingCounter(){
        const pending = this.counters.counters.pendingOrders ? this.counters.counters.pendingOrders : 0;
        if(!this.props.product || !pending)
            return "";

        const inventory = this.counters.counters.inventory;
        const storeReserved = this.counters.counters.storeReserved;
        const available = inventory + storeReserved - pending;
        return  <tr>
            <th scope="row">Pending Orders</th>
            <td>
                <Row>
                    <Col>  {pending} </Col>
                    <Col> Available: {available} { available < 0 ? <Badge className="mt-1 float-right" color="danger">Backorder</Badge> : "" }</Col>
                </Row>
            </td>
        </tr>
    }


    renderUsShipReadyCounter(){
        if(!this.props.product || !this.props.product.marketplaces.hasOwnProperty(client.US_PARENT_MARKETPLACE_ID))
            return "";

        let counters = this.counters.counters;
        return  <tr>
            <th scope="row">US Ship Ready</th>
            <td>
            <Row>
                <Col> {counters["us-shipReady"] ? counters["us-shipReady"] : 0} </Col>
                <Col> <Badge className="mt-1 float-right" color="secondary">{this.props.product.marketplaces[client.US_PARENT_MARKETPLACE_ID].productIds.fnsku}</Badge> </Col>
            </Row>
            </td>
        </tr>
    }

    renderEuPackRequestCounter(){
        if(!this.props.product || !this.props.product.marketplaces.hasOwnProperty(client.EU_PARENT_MARKETPLACE_ID))
            return "";

        let counters = this.counters.counters;
        return <tr>
            <th scope="row">EU Pack Request</th>
            <td>
                <Row>
                    <Col>{counters["eu-packRequest"] ? counters["eu-packRequest"] : 0}
                        { this.props.product.detail.indicators.euCeMarkRequired ?
                            <NavLink className="float-right p-0" href={`https://label.kjdelectronics.com/ce/label-printer`} target="_blank">CE Mark Label</NavLink> : ""}
                    </Col>
                </Row>
            </td>
        </tr>
    }

    renderEuShipReadyCounter(){
        if(!this.props.product || !this.props.product.marketplaces.hasOwnProperty(client.EU_PARENT_MARKETPLACE_ID))
            return "";

        let counters = this.counters.counters;
        return <tr>
            <th scope="row">EU Ship Ready</th>
            <td>
                <Row>
                    <Col>{counters["eu-shipReady"] ? counters["eu-shipReady"] : 0}</Col>
                    <Col><Badge className="mt-1 float-right" color="secondary">{this.props.product.marketplaces[client.EU_PARENT_MARKETPLACE_ID].productIds.fnsku}</Badge></Col>
                </Row>
            </td>
        </tr>
    }

    renderCaPackRequestCounter(){
        if(!this.props.product || !this.props.product.marketplaces.hasOwnProperty(client.CA_PARENT_MARKETPLACE_ID))
            return "";

        let counters = this.counters.counters;
        if(counters["ca-packRequest"] == null && counters["ca-shipReady"] == null)
            return "";
        return <tr>
            <th scope="row">CA Pack Request</th>
            <td>
                <Row>
                    <Col>{counters["ca-packRequest"] ? counters["ca-packRequest"] : 0}</Col>
                </Row>
            </td>
        </tr>
    }

    renderCaShipReadyCounter(){
        if(!this.props.product || !this.props.product.marketplaces.hasOwnProperty(client.CA_PARENT_MARKETPLACE_ID))
            return "";

        let counters = this.counters.counters;
        if(counters["ca-packRequest"] == null && counters["ca-shipReady"] == null)
            return "";
        return <tr>
            <th scope="row">CA Ship Ready</th>
            <td>
                <Row>
                    <Col>{counters["ca-shipReady"] ? counters["ca-shipReady"] : 0}</Col>
                    <Col><Badge className="mt-1 float-right" color="secondary">{this.props.product.marketplaces[client.CA_PARENT_MARKETPLACE_ID].productIds.fnsku}</Badge></Col>
                </Row>
            </td>
        </tr>
    }


    renderLocationBadge(locationType="PRIMARY"){
        if(!this.props.product)
            return "";
        if(this.props.product.detail.locations.length === 0)
            return <Badge className="mt-1 float-right" color="info">No Location</Badge>;
        let location = this.props.product.detail.locations.find(x => x.locationType === locationType);
        if(!location) return "";
        return <Badge className="mt-1 float-right" color="info">{location.name}</Badge>;
    }

    get counters(){
        if(this.props.counters)
            return this.props.counters;
        if(this.props.product)
            return this.props.product.counters;
        return null;
    }

    get _mostRecentPurchaseString(){
        const history = this.props.product && this.props.product.finance
            && this.props.product.finance.purchasing && this.props.product.finance.purchasing.purchaseHistory;
        if(!history || history.length === 0)
            return null;
        const result = history[0];
        return <NavLink className="float-right p-0" href={`https://purchasing.kjdelectronics.com/receiving/edit/${result.poId}`}
                        target="_blank"> Purchased {result.quantity} on {moment(result.purchaseDate).format('MMM Do YYYY')}</NavLink>;


    }



    render() {
        if(!this.counters)
            return "";

        const inventoryManualCount = this.props.product && this.props.product.detail.mostRecentInventoryManualCount;
        const reserveManualCount = this.props.product && this.props.product.detail.mostRecentStoreReservedManualCount;

        return  <div className={ this.props.size === "sm" ? "table-responsive-sm padding-4px" :"table-responsive-lg"}>
            <h4>{this.props.heading ? this.props.heading : "Heading"}</h4>
            <Table bordered>
            <tbody>
            <tr>
                <th scope="row">Inbound</th>
                <td>
                    <Row>
                        <Col> {this.counters.counters.inbound}</Col>
                        <Col xs={9}  className={"manual-count"}>{this._mostRecentPurchaseString}</Col>
                    </Row>
                </td>
            </tr>
            <tr>
                <th scope="row">Inventory</th>
                <td>
                    <Row>
                        <Col>{this.counters.counters.inventory}</Col>
                        <Col>{this.renderLocationBadge()}</Col>
                    </Row>
                    <Row>
                        <Col className={"manual-count"}>
                            {inventoryManualCount ? inventoryManualCount.toInventoryString : ""}</Col>
                    </Row>
                </td>
            </tr>
            <tr>
                <th scope="row">Reserved</th>
                <td>
                    <Row>
                        <Col>{this.counters.counters.storeReserved}</Col>
                        <Col>{this.renderLocationBadge("RESERVE")}</Col>
                    </Row>
                    <Row>
                        <Col className={"manual-count"}>
                            {reserveManualCount ? reserveManualCount.toReserveString : ""}</Col>
                    </Row>
                </td>
            </tr>
            {this.renderPendingCounter()}
            {this.renderUsShipReadyCounter()}
            {false && this.renderCaPackRequestCounter()}
            {this.renderCaShipReadyCounter()}
            {false && this.renderEuPackRequestCounter()}
            {false && this.renderEuShipReadyCounter()}
            </tbody>
        </Table>
        </div>
    }
}

export default Counters;
