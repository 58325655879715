class Comment{
    constructor({id, comment, userId, dateTime, updatedDateTime}){
        if(!comment || !userId)
            throw new Error("Comment must have a comment and userId");

        this.id = id || null;
        this.comment = comment;
        this.userId = userId;
        this.dateTime = dateTime || new Date();
        this.updatedDateTime = updatedDateTime || new Date();
    }

    static initFromLegacy({Comment, CommentId}){
        let comment = {
            id: null,
            comment: Comment,
            legacyRef: CommentId,
            userId: "5a00e3c1db1ba1df2d24e83c", //kjdAdmin Id
            dateTime: null,
            updatedDateTime: new Date()
        };

        return new module.exports.Comment(comment);
    }
}

export { Comment };