import React, {Component} from 'react';
import { Form, FormGroup, Label, Input, Card, CardBody, CardHeader, ListGroup, ListGroupItem, Badge, InputGroupAddon, InputGroup } from 'reactstrap';
import client from '../service/ImClientSingleton'
import eventClient from '../service/EventsClientSingleton'
import {ProductSearchModal} from "./ProductSearchModal";
import PropTypes from "prop-types";
import {MfnOrderItemsTable} from "../molecules/tables/MfnOrderItemsTable";
import Button from "reactstrap/es/Button";

class MfnShipmentForm extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if(this.props.initialProductId) {
            let t = this;
            client.getProductById(this.props.initialProductId)
                .then(product => {
                    t._product = product;
                    t.props.onProductChange(product);
                });
        }
    }

    handleChange(e, name) {
        const regex = /^[0-9]\d*(\.\d+)?$/;
        const text = e.target.value;

        if((regex.test(text) || text[text.length - 1] === "."  )&& this.props.onChange)
            this.props.onChange({value: text, name})
    }

    handleKeyPress(e) {
        if (e.key === "Enter") {
            this.props.onGetQuotes()
        }
    }


    render() {
        const {weight, length, width, height } = this.props;
        return <div>
            <Form inline>
                <FormGroup className="mr-4">
                    <Label for="exampleEmail" className="mr-2">Weight </Label>
                    <InputGroup>
                        <Input placeholder="1.5" className="mfn-shipping-input" value={weight} onChange={e => this.handleChange(e, "weight")} />
                        <InputGroupAddon addonType="append">lbs</InputGroupAddon>
                    </InputGroup>
                </FormGroup>
                <FormGroup className="ml-4 mr-2">
                    <Label for="exampleEmail" className="mr-2">Length</Label>
                    <InputGroup >
                        <Input placeholder="7" className="mfn-shipping-input" value={length} onChange={e => this.handleChange(e, "length")}/>
                        <InputGroupAddon addonType="append">in</InputGroupAddon>
                    </InputGroup>
                </FormGroup>
                <FormGroup className="ml-2 mr-2">
                    <Label for="exampleEmail" className="mr-2">Width</Label>
                    <InputGroup>
                        <Input placeholder="4" className="mfn-shipping-input" value={width} onChange={e => this.handleChange(e, "width")}/>
                        <InputGroupAddon addonType="append">in</InputGroupAddon>
                    </InputGroup>
                </FormGroup>
                <FormGroup className="ml-2 mr-4">
                    <Label for="exampleEmail" className="mr-2">Height</Label>
                    <InputGroup>
                        <Input placeholder="3" className="mfn-shipping-input" value={height} onKeyPress={(e) => this.handleKeyPress(e)} onChange={e => this.handleChange(e, "height")}/>
                        <InputGroupAddon addonType="append">in</InputGroupAddon>
                    </InputGroup>
                </FormGroup>
                <Button onClick={() => this.props.onGetQuotes()} color="primary" className="mr-2 ml-4" disabled={this.props.loading}>Get Rates</Button>
            </Form>
        </div>

    }
}

MfnShipmentForm.propTypes = {
    loading: PropTypes.bool,
    weight: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onGetQuotes: PropTypes.func.isRequired,
    onBuyShipping: PropTypes.func.isRequired,
};

export default MfnShipmentForm;
