import { rpWrapper as rp } from '../../helper/request.axios.wrapper';
import config from '../config/config';
import routes from '../config/routes';
import { ProductCounters } from '../../obj/ProductCounters';

class ProductCountersClient{
    constructor({auth}){
        this.auth = auth;
    }

    getProductCounters(){
        let options = {
            uri: config.baseUrl + routes.productCounters.get.list,
            method: 'GET',
            headers: {},
            json: true
        };
        options.headers[this.auth.headerName] = this.auth.headerValue;

        return rp(options)
            .then(rawProductCounters => {
                let productCounters = [];
                rawProductCounters.forEach(rawCounter => {
                    let product = new ProductCounters(rawCounter);
                    productCounters.push(product);
                });
                return productCounters;
            })
            .catch(err => {
                return Promise.reject(err);
            })
    }

    getProductCountersAbs({startDate, endDate}={}){
        let options = {
            uri: config.baseUrl + routes.productCounters.get.listAbs + "?",
            method: 'GET',
            headers: {},
            json: true
        };
        options.headers[this.auth.headerName] = this.auth.headerValue;
        if(startDate)
            options.uri += `startDate=${startDate.toISOString().substring(0,10)}&`;
        if(endDate)
            options.uri += `endDate=${startDate.toISOString().substring(0,10)}&`;

        return rp(options)
            .then(rawProductCounters => {
                let productCounters = [];
                rawProductCounters.forEach(rawCounter => {
                    let product = new ProductCounters(rawCounter);
                    productCounters.push(product);
                });
                return productCounters;
            })
            .catch(err => {
                return Promise.reject(err);
            })
    }

    getProductCountersDict(){
        return this.getProductCounters()
            .then(productCounters => {
                let dict = {};
                productCounters.forEach(productCounter => {
                    dict[productCounter.productId] = productCounter;
                });
                return dict;
            })
    }

    getCountersForProductId(productId){
        let options = {
            uri: config.baseUrl + routes.counters.get.forProduct.replace(":productId", productId),
            method: 'GET',
            headers: {},
            json: true
        };
        options.headers[this.auth.headerName] = this.auth.headerValue;

        return rp(options)
            .then(counters => {
                return new ProductCounters(counters);
            })
    }
}

export { ProductCountersClient };