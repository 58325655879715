import { validateArrayOfType } from '../../helper/arrayOps';
class ProductIdentifiers{
    constructor({id, upcEanGtin, modelNumber, barcodes, keywords=[], customIdentifiers={}, searchIndex}){
        if(id && typeof id !== "number")
            throw `Product key.id must be a number if provided`;
        this.id = id ? parseInt(id) : null;
        this.kid = `K${id}`;

        this.upcEanGtin = upcEanGtin;
        this.modelNumber = modelNumber;

        if(barcodes == null) barcodes = [];
        barcodes = barcodes.map(x => x && x.trim ? x.trim() : x).filter(x => !!x);
        validateArrayOfType({array: barcodes, typeName: 'string', messageDetail: "Must be type string", propName: "barcodes"});
        this.barcodes = barcodes;

        if(keywords == null) keywords = [];
        keywords = keywords.map(x => x && x.trim ? x.trim() : x).filter(x => !!x);
        validateArrayOfType({array: keywords, typeName: 'string', messageDetail: "Must be type string", propName: "keywords"});
        this.keywords = keywords;

        if(customIdentifiers == null) customIdentifiers = {};
        this.customIdentifiers = customIdentifiers;

        this.buildSearchArray(this);
        this.searchIndex = searchIndex; //Search index is built and maintained by storage layer. May be done async
    }

    buildSearchArray(){
        this.searchArray = _buildSearchArray(this);
        return this.searchArray;
    }

    toJSON(){
        let {["searchArray"]:omit, ...res} = this;
        return res;
    }
}

//Moved function out of class to avoid possible this issues
function _buildSearchArray(obj){
    let searchSet = new Set([obj.kid,]); //Note: obj.id is intentional excluded as it should only be searchable (string) as the KID
    if(obj.upcEanGtin) searchSet.add(obj.upcEanGtin);
    if(obj.modelNumber) searchSet.add(obj.modelNumber);

    obj.barcodes.forEach((barcode) => {
        searchSet.add(barcode);
    });

    obj.keywords.forEach((keyword) => {
        searchSet.add(keyword);
    });

    for (let key in obj.customIdentifiers) {
        if (!obj.customIdentifiers.hasOwnProperty(key)) continue;
        searchSet.add(key);
    }

    return Array.from(searchSet);
}


export { ProductIdentifiers };