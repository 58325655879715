import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Button, Row, NavLink, ButtonGroup, Badge} from 'reactstrap';
import client from '../service/ImClientSingleton'
import { Link } from "react-router-dom";
import AppContext from "../../context/AppContext";

class Links extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    render(){
        if(!this.props.product)
            return (
                <div className="row">
                </div>
            );

        return ( <AppContext.Consumer>
            {({user}) => (
            <Row className="justify-content-center">
                {!this.props.hideDiscrepancyLink &&
                <NavLink className="p-0"  style={{marginRight: 20}} tag={Link} to={`/discrepancies/report/${this.props.product.key.id}`}>Report Discrepancy</NavLink>}
                {!this.props.hideReserveTransferLink &&
                <NavLink className="p-0" style={{marginRight: 20}} tag={Link} to={`/reserve/transfer/${this.props.product.key.id}`}>Reserve Transfer</NavLink>}
                {!this.props.hideShipmentHistoryLink &&
                <NavLink className="p-0" style={{marginRight: 20}} href={`https://mws-service.kjdelectronics.com/v1/fba/inbound/history/${this.props.product.key.id}`}
                         target="_blank">FBA Shipment History</NavLink>}
                {!this.props.hidePurchaseHistory &&
                <NavLink className="p-0" style={{marginRight: 20}} href={`https://purchasing.kjdelectronics.com/history/${this.props.product.key.id}`}
                         target="_blank">Purchase History</NavLink>}
                {user.groups.indexOf("IM_FINANCES") !== -1 &&
                <NavLink className="p-0" style={{marginRight: 20}} href={`https://sales.kjdelectronics.com/products/${this.props.product.key.id}`}
                         target="_blank">Sales</NavLink>}
                {this.props.product.bundleComposition.length !== 0 &&
                <NavLink className="p-0" style={{marginRight: 20}} tag={Link} to={`/kits/${this.props.product.key.id}`}>Kit Builder</NavLink>}
            </Row>
            )}
            </AppContext.Consumer>
        );
    }
}

Links.propTypes = {
    product: PropTypes.object
};

export default Links;
