import ImClient from '../../client/ImClient';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

let sessionKey = cookies.get('kjd_session');
if(!sessionKey){
    cookies.set(process.env.REACT_APP_SOO_REDIRECT_TARGET_COOKIE_NAME, process.env.REACT_APP_UI_URL, { path: '/', maxAge: 1800 }); //30min max age
    window.location = process.env.REACT_APP_SSO_BASE;
}

validateUserAuthorized();

//TODO Make sure session is valid
function validateUserAuthorized(){
    let url = process.env.REACT_APP_SSO_BASE + `validate`;
    return fetch(url,
        {
            method: 'GET',
            credentials: 'include'
        })
        .then(results => {
            if(results.ok) return results.json();
            else return results.json().then(body => Promise.reject({body: body, status: results.status}));
        }).then(user => {
            //TODO
            /*let newUserObj = new User(user);
            this.setState({user: newUserObj});
            return newUserObj;*/
            return user;
        }).catch(err => {
            if(err.status === 401) {
                cookies.set(process.env.REACT_APP_SOO_REDIRECT_TARGET_COOKIE_NAME, process.env.REACT_APP_UI_URL, { path: '/', maxAge: 1800 }); //30min max age
                window.location = err.body.ssoLoginUrl;
            }
            else {
                console.log(err);
                return Promise.reject({message: "Cannot authenticate user"});
            }
        })
}

export default new ImClient({sessionKey: sessionKey});