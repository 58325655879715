import cloneDeep from 'clone-deep';
import { ErrorWithStatusCode } from './errors/ErrorWithStatusCode';

class ProductCountersProxy {
    constructor(handler) {
        return new Proxy(this, handler);
    }
}

class ProductCounters extends ProductCountersProxy{
    constructor({productId, counters={}}, {suppressNoCountersWarningLog=false}={}){
        if(typeof productId !== "number")
            throw new Error("productId is required and must be a number");

        if(counters == null && !suppressNoCountersWarningLog) {
            console.log(`ProductCounters: No counters provided for counters with productId ${productId}. Use empty object`);
            counters = {};
        }

        if(typeof counters !== "object")
            throw new Error("counters must be an object");

        const proxyHandler = {
            get: (target, name) => {
                if(target[name])
                    return target[name];
                if(typeof name === 'symbol')
                    return target[name];
                return this.getCounter(name);
            },
            set: (obj, prop, value) => {
                if(prop === "productId" || prop === "_lookupDict" || prop === "counters")
                    obj[prop] = value;
                else
                    this.setCounter(prop, value);
                return true; //Indicate success
            }

        };
        super(proxyHandler);

        this.productId = productId;
        this.counters = cloneDeep(counters);

        this._lookupDict = {};
        for (let counterName in this.counters) {
            if (!this.counters.hasOwnProperty(counterName)) continue;

            let parsedValue = parseInt(this.counters[counterName]); //Force into int
            if(isNaN(parsedValue))
                throw new Error('All counters must be an integer or can be parsed into an integer');

            this.counters[counterName] = parsedValue;
            this._lookupDict[counterName.toUpperCase()] = counterName;
        }
    }

    getCounter(counterName){
        if(typeof counterName !== 'string')
            throw new Error(`counterName must be a string`);
        let uCaseName = counterName.toUpperCase();
        return this._lookupDict[uCaseName] ? this.counters[this._lookupDict[uCaseName]] : 0
    }

    instanceClone(){
        let productId = this.productId;
        let counters = cloneDeep(this.counters);
        return new ProductCounters({productId: productId, counters: counters});
    }

    setCounter(counterName, value){
        if(typeof value !== 'number' || isNaN(value))
            throw new ErrorWithStatusCode({code: 400, message: 'Cannot set a counter to a non numeric value'});

        let uCaseName = counterName.toUpperCase();
        let realCounterName = this._lookupDict[uCaseName];
        if(realCounterName) //Lookup case insensitive
            this.counters[realCounterName] = value;
        else { //Use provided and add to lookup dict
            this._lookupDict[counterName.toUpperCase()] = counterName;
            this.counters[counterName] = value;
        }
    }

    getForDb(){
        let {["_lookupDict"]:omit, ...res} = this;
        return res;
    }

    toJSON(){
        let {["_lookupDict"]:omit, ...res} = this;
        return res;
    }

    toString(){
        let str = "";
        for(let counterName in this.counters){
            if(!this.counters.hasOwnProperty(counterName)) continue;
            str += `\n${counterName}: ${this.counters[counterName]}`;
        }
        return str;
    }
}


export { ProductCounters };