import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import {Badge, NavItem, NavLink} from 'reactstrap';
import paginationFactory from "react-bootstrap-table2-paginator";
import {Link} from "react-router-dom";
import ssoClient from "../service/SsoClientSingleton"
import {TransactionCommentDialog} from "../molecules/TransactionCommentDialog";
import {TransactionCommentButton} from "./TransactionCommentButton";

class TransactionCommentsTable extends React.Component {
    state = {
        users: [],
        showDialog: false
    };

    componentDidMount() {
        if(this.state.users.length === 0){
            ssoClient.getActiveUsers().then(users => {
                this.setState({users});
            })
        }
    }

    render() {
        const {transaction} = this.props;

        if(!transaction)
            return "";

        const records = transaction.comments.map(i => {
            const newR = {...i};
            const date = new Date(i.dateTime);

            newR.user = this.state.users.find(u => u.id === i.userId);
            newR.date = date;
            newR.username = newR.user ? newR.user.displayName : "";
            newR.dateString =  <span style={{'font-size': '10pt'}}>{date.toLocaleDateString()} {date.toLocaleTimeString()}</span>;
            return newR;
        });
        let options = {
            sizePerPageList: [ {
                text: '10', value: 10
            },{
                text: '25', value: 25
            }]
        };
        if(transaction.comments.length <= 10)
            options = null;

        const columns = [
            {
                dataField: 'dateString',
                text: 'Date',
                headerStyle: () => {
                    return { width: "15%" };
                },
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    if (order === 'asc') {
                        return rowB.date.getTime() - rowA.date.getTime();
                    }
                    return rowA.date.getTime() - rowB.date.getTime(); // desc
                }
            },
            {
                dataField: 'username',
                text: 'User',
                headerStyle: () => {
                    return { width: "15%" };
                }
            },
            {
                dataField: 'comment',
                text: 'Comment',
                headerStyle: () => {
                    return { width: "50%" };
                }

            },
        ];

        return (
            <div style={{width: "99%"}}>
                <h4 className="text-left">Comments  <TransactionCommentButton transaction={transaction} className="d-inline ml-1" /></h4>
                <BootstrapTable
                    condensed={true}
                    bootstrap4={true} keyField='id'
                    rowClasses={"padding-2px"}
                    defaultSorted={[{dataField: "dateString", order: "desc"}]}
                    data={ records } pagination={ options ? paginationFactory(options) : null } columns={ columns } />
            </div>
        );
    }
}


TransactionCommentsTable.propTypes = {
    transaction: PropTypes.object.isRequired
};

export {TransactionCommentsTable};