import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import {Badge, NavItem, NavLink} from 'reactstrap';
import paginationFactory from "react-bootstrap-table2-paginator";
import {TransactionApprovalBadge} from "../../atoms/TransactionApprovalBadge";
import {Link} from "react-router-dom";
import {CommentsCollapse} from "../../atoms/CommentsCollapse";
import {TransactionMetaLinks} from "../../atoms/TransactionMetaLinks";
import client from '../../service/ImClientSingleton'

const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
});
const currency = (cell, row) => usdFormatter.format(cell);

class KitComponentTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productDict: {}
        };
    }

    async componentWillMount() {
        if(this.props.components)
            await this._getProducts(this.props.components);
    }


    async componentDidUpdate(prevProps, nextProps) {
        if(nextProps.components)
            await this._getProducts(nextProps.components);
    }

    async _getProducts(components) {
        const dict = {};
        for (let i = 0; i < components.length; i++) {
            const component = components[i];
            dict[component.productId]= await client.getProductById(component.productId);
        }
        this.setState({productDict: dict})
    }

    render() {
        const {components} = this.props;
        const {productDict} = this.state;

        if(!components || components.length === 0)
            return <h3 className="font-italic">There are no components</h3>;

        const records = components.map(i => {
            const newR = {...i};

            newR.kid =  <NavLink className="compact" tag={Link} to={`/packing/${i.productId}`}>K{i.productId}</NavLink> ;
            newR.name = productDict.hasOwnProperty(i.productId) ? productDict[i.productId].detail.shortName : "" ;
            newR.maxBuildableAmount = i.quantity ? i.maxBuildableAmount : <Badge color="secondary">N/A</Badge>;
            newR.inboundCount = i.counters.counters.hasOwnProperty("inbound") ? i.counters.counters.inbound : 0;
            newR.inventoryCount = i.counters.counters.hasOwnProperty("inventory") ? i.counters.counters.inventory : 0;

            return newR;
        });

        let options = {
            sizePerPageList: [ {
                    text: '10', value: 10
                },{
                    text: '25', value: 25
                },
            ],
            sizePerPage: this.props.sizePerPage ? this.props.sizePerPage :  25
        };
        if(components.length <= 10)
            options = null;

        const columns = [
            {
                dataField: 'kid',
                text: 'KID',
                headerStyle: () => {
                    return { width: "5%" };
                },
                sort: true
            },
            {
                dataField: 'name',
                text: 'Name',
                headerStyle: () => {
                    return { width: "25%" };
                },
                sort: true
            },
            {
                dataField: 'quantity',
                text: 'Quantity',
                headerStyle: () => {
                    return { width: "8%" };
                },
                sort: true
            },
            {
                dataField: 'inboundCount',
                text: 'Inbound',
                headerStyle: () => {
                    return { width: "8%" };
                },
                sort: true
            },
            {
                dataField: 'inventoryCount',
                text: 'Inventory',
                headerStyle: () => {
                    return { width: "8%" };
                },
                sort: true
            },
            {
                dataField: 'maxBuildableAmount',
                text: 'Max Buildable',
                headerStyle: () => {
                    return { width: "8%" };
                },
                sort: true
            },
            { //TODO Conditionally Hide
                dataField: 'unitCost',
                text: 'Unit Cost',
                headerStyle: () => {
                    return { width: "8%" };
                },
                sort: true,
                hidden: this.props.hideUnitCost,
                formatter: currency
            },
        ];

        return (
            <div style={{width: "99%"}}>
                <BootstrapTable
                    condensed={true}
                    bootstrap4={true} keyField='id'
                    classes={"padding-2px-table"}
                    defaultSorted={[{dataField: "dateString", order: "desc"}]}
                    data={ records } pagination={ options ? paginationFactory(options) : null } columns={ columns } />
            </div> 
            );
    }
}

function centerFormatter(cell){
    return <div className="text-center">{cell}</div>;
}

KitComponentTable.propTypes = {
    components: PropTypes.array.isRequired,
    sizePerPage: PropTypes.number,
    hideUnitCost: PropTypes.bool
};

export {KitComponentTable};