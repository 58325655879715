import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import {Button,} from 'reactstrap';
import paginationFactory from "react-bootstrap-table2-paginator";
import {AutoSaveReserveEdit} from "../../atoms/AutoSaveReserveEdit";
import {LoadingSpinner} from "../../atoms/LoadingSpinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCaretUp, faCaretDown, faCheck} from '@fortawesome/free-solid-svg-icons'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {ReserveTransferModal} from "../../organisms/ReserveTransferModal";
import eventClient from "../../service/EventsClientSingleton";
import {DiscrepancyModal} from "../../organisms/DiscrepancyModal";

const { SearchBar } = Search;

const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
});

class ReserveManagementTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showXferModal: false,
            showDiscrepancyModal: false,
            selectedProductId: null,
            counterUpdates: {}
        };
        eventClient.subscribe((e) => this.handleProductCountersUpdate(e))
    }

    handleProductCountersUpdate(e){
        if(e.type === "counters.product"){
            this._updateCounters(e.data);
        }
    }

    _updateCounters(counters){
        let counterUpdates = Object.assign({}, this.state.counterUpdates);
        counterUpdates[counters.productId] = counters;
        this.setState({counterUpdates});
    }

    getCounter({product, name}){
        if(!product.counters || !product.counters.counters)
            return 0;
        if(this.state.counterUpdates[product.key.id])
            return this.state.counterUpdates[product.key.id].counters[name] ? this.state.counterUpdates[product.key.id].counters[name] : 0;
        return product.counters.counters[name] ? product.counters.counters[name] : 0;
    }

    getReserveTarget(product){
        if(this.state[product.key.id.toString()])
            return this.state[product.key.id.toString()];
        return product.targets.storeReservedTarget;
    }

    getStatusIcon(product){
        const targetDiff = this.getReserveTargetDiff(product);
        if(targetDiff === 0)
            return  <span className="ml-2"><FontAwesomeIcon icon={faCheck}/></span>;
        else if(targetDiff > 0)
            return <span className="ml-2"><FontAwesomeIcon icon={faCaretUp} size="lg" className="profit-text mr-1"/> {targetDiff}</span>;
        else
            return <span className="ml-2"><FontAwesomeIcon icon={faCaretDown} color="darkred" className="mr-1" size="lg"/> {targetDiff}</span>;
    }

    getXferButton(product){
        return <Button color="link" size="sm" className="float-right" onClick={() => this.setState({selectedProductId: product.key.id, showXferModal: true})}>XFER</Button>
    }

    getDisrepnacyButton(product){
        return <Button color="link" size="sm" className="float-right" onClick={() => this.setState({selectedProductId: product.key.id, showDiscrepancyModal: true})}>DSCRP</Button>
    }

    getReserveTargetDiff(product){
        const target = this.getReserveTarget(product);
        const current = this.getCounter({product, name: "storeReserved"});
        return current - target;
    }

    render() {
        let {products, productDirectSales1yr, absProductCounters1yr} = this.props;
        if(!productDirectSales1yr)
            productDirectSales1yr = {};
        if(!absProductCounters1yr)
            absProductCounters1yr = {};

        if(!products || products.length === 0)
            return <LoadingSpinner/>;

        products.sort((a, b) => a.key.id - b.key.id);
        if(this.props.excludeFullyStockedReserves)
            products = products.filter(p => this.getReserveTargetDiff(p) < 0);

        const records = products.map(i => {
            const newR = {product: i};
            let sales = productDirectSales1yr[i.key.id];
            let absCounters = absProductCounters1yr[i.key.id];

            newR.id = newR.product.key.id; 
            newR.name = newR.product.detail.shortName;
            newR.currentInventory = this.getCounter({product: i, name: "inventory"});
            newR.currentReserve = <span>
                {this.getCounter({product: i, name: "storeReserved"})}
                {this.getStatusIcon(newR.product)}
                {this.getDisrepnacyButton(newR.product)}
                {this.getXferButton(newR.product)}
            </span>;
            newR.directSalesUnits1yr = sales ? sales.unitsOrdered : "";
            newR.directSalesProfit1yr = sales ? sales.base.profit : "";
            newR.reserveVolume1yr = absCounters ? absCounters.counters.storeReserved : "";
            newR.reserveTarget = <AutoSaveReserveEdit
                value={newR.product.targets.storeReservedTarget}
                onSaved={newValue => {
                        const key = `${newR.product.key.id}`;
                        const obj = {};
                        obj[key] = newValue;
                        this.setState(obj);
                    }
                }
                productId={newR.product.key.id}
            />;

            return newR;
        });

        let options = {
            sizePerPageList: [ {
                text: '100', value: 100
            },{
                text: '250', value: 250
            },{
                text: '500', value: 500
            }],
        };
        if(products.length <= 10)
            options = null;

        const columns = [
            {
                dataField: 'id',
                text: 'KID',
                formatter: kidFormatter,
                filterValue: (cell) => `K${cell}`,
                headerStyle: () => {
                    return { width: "5%" };
                },
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    if (order === 'asc') {
                        return rowA.product.key.id - rowB.product.key.id;
                    }
                    return rowB.product.key.id - rowA.product.key.id; // desc
                }
            },
            {
                dataField: 'name',
                text: 'Name',
                sort: true,
                headerStyle: () => {
                    return { width: "33%" };
                }
            },
            {
                dataField: 'currentInventory',
                text: 'Inventory',
                sort: true,
                searchable: false,
                headerStyle: () => {
                    return { width: "10%" };
                }
            },
            {
                dataField: 'currentReserve',
                text: 'Reserve',
                sort: true,
                searchable: false,
                headerStyle: () => {
                    return { width: "15%" };
                },
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    if(!this.props.excludeFullyStockedReserves){
                        if (order === 'asc') {
                            return this.getCounter({product: rowA.product, name: "storeReserved"}) - this.getCounter({product: rowB.product, name: "storeReserved"});
                        }
                        return this.getCounter({product: rowB.product, name: "storeReserved"}) - this.getCounter({product: rowA.product, name: "storeReserved"}); //desc
                    }
                    else{
                        if (order === 'asc') {
                            return this.getReserveTargetDiff(rowB.product) - this.getReserveTargetDiff(rowA.product);
                        }
                        return this.getReserveTargetDiff(rowA.product) - this.getReserveTargetDiff(rowB.product); //desc
                    }
                }
            },
            {
                dataField: 'directSalesUnits1yr',
                text: '1 Yr Direct Sales (Units)',
                sort: true,
                searchable: false,
                headerStyle: () => {
                    return { width: "15%" };
                }
            },
            {
                dataField: 'directSalesProfit1yr',
                text: '1 Yr Direct Profit',
                formatter: profitFormatter,
                sort: true,
                searchable: false,
                headerStyle: () => {
                    return { width: "12%" };
                }
            },
            {
                dataField: 'reserveVolume1yr',
                text: '1 Yr Resv Volume',
                sort: true,
                searchable: false,
                headerStyle: () => {
                    return { width: "12%" };
                }
            },
            {
                dataField: 'reserveTarget',
                text: 'Reserve Target',
                sort: true,
                searchable: false,
                headerStyle: () => {
                    return { width: "10%" };
                },
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    if (order === 'asc') {
                        return this.getReserveTarget(rowA.product) - this.getReserveTarget(rowB.product);
                    }
                    return this.getReserveTarget(rowB.product) - this.getReserveTarget(rowA.product); //desc
                }
            }
        ];

        return (
            <div style={{width: "99%"}}>
                {this.state.showXferModal && <ReserveTransferModal
                    productId={this.state.selectedProductId}
                    onCancel={() => this.setState({showXferModal: false})}
                    onCounterUpdate={counters => this._updateCounters(counters)}/>}
                {this.state.showDiscrepancyModal && <DiscrepancyModal
                    productId={this.state.selectedProductId}
                    onCancel={() => this.setState({showDiscrepancyModal: false})}
                    onCounterUpdate={counters => this._updateCounters(counters)}/>}
                <ToolkitProvider
                    keyField="id"
                    data={ records }
                    columns={ columns }
                    search={{

                    }}>
                    {
                        props => (
                            <div>
                                <SearchBar { ...props.searchProps } />
                                <BootstrapTable
                                    { ...props.baseProps }
                                    condensed={true}
                                    bootstrap4={true}
                                    classes="table-fixed"
                                    striped={true}
                                    pagination={ options ? paginationFactory(options) : null }
                                />
                            </div>
                        )
                    }

                </ToolkitProvider>
            </div>
        );
    }
}

function kidFormatter(cell){
    const id = cell.id ? cell.id : cell;
    return <a tabIndex={-1} href={`https://sales.kjdelectronics.com/products/${id}`}
              target="_blank">K{cell}</a>
}

function reserveTargetFormatter(cell, row){
    return <a href={`https://im.kjdelectronics.com/app/products/edit/${row.id}`}
              target="_blank">{cell}</a>
}

function profitFormatter(cell, row){
    return <span className="profit-text">{usdFormatter.format(cell)}</span>
}




ReserveManagementTable.propTypes = {
    products: PropTypes.array.isRequired,
    productDirectSales1yr: PropTypes.object, //Products Dict
    excludeFullyStockedReserves: PropTypes.bool,
    absProductCounters1yr: PropTypes.object, //Products Dict
};

export {ReserveManagementTable};