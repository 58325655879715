import React, {Component} from 'react';
import { FormGroup, Label, Input, Card, Alert, FormFeedback, Row, Col } from 'reactstrap';
import Counters from "../product/Counters";
import InventoryAdjustmentRow from "./InventoryAdjustmentRow";
import imClient from "../service/ImClientSingleton";
import ActionAndReturnToPackingButtonsRow from "../shared/ActionAndReturnToPackingButtonsRow";
import InventoryAdjustmentLogic from "../../domain/discrepancy/InventoryAdjustmentLogic";
import ReserveAdjustmentLogic from "../../domain/discrepancy/ReserveAdjustmentLogic";

class DiscrepancyDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rawReserveTransaction: null,
            rawInventoryTransaction: null,
            comment: "",
            saveInvalid: false,
            savedInventoryTransaction: null,
            savedReserveTransaction: null,
            discrepancySaved: false,
            manualCountSaved: false,
            inventoryManuallyCountedInd: false,
            reserveManuallyCountedInd: false
        }
    }

    onCommentChange(e) {
        let saveInvalid = e.target.value.length === 0;
        this.setState({comment: e.target.value, saveInvalid: saveInvalid});
    }

    setReserveTransaction(t){
        this.setState({rawReserveTransaction: t})
    }

    setInventoryTransaction(t){
        this.setState({rawInventoryTransaction: t})
    }

    setInventoryManualCount(manuallyCounted){
        const statePatch = {inventoryManuallyCountedInd: manuallyCounted};
        if(manuallyCounted && this.state.comment === "")
            statePatch.comment = "Manual Count";
        this.setState(statePatch);
    }

    setReserveManualCount(manuallyCounted){
        const statePatch = {reserveManuallyCountedInd: manuallyCounted};
        if(manuallyCounted && this.state.comment === "")
            statePatch.comment = "Manual Count";
        this.setState(statePatch);
    }

    _newFinalValueForInventory(){
        if(!this.state.rawInventoryTransaction)
            return this.props.product.counters.counters.inventory;
        return this.state.rawInventoryTransaction._newFinalValueForCounter;
    }

    _newFinalValueForReserve(){
        if(!this.state.rawReserveTransaction)
            return this.props.product.counters.counters.storeReserved;
        return this.state.rawReserveTransaction._newFinalValueForCounter;
    }

    save(){
        if(this.state.comment.length === 0){
            this.setState({saveInvalid: true});
            return;
        }
        const batch = this.getBatch();

        if(batch.length > 0) {
            imClient.submitBatch(batch)
                .then(batch => {
                    console.log(batch);
                    let inventory = batch.transactions.find(t => t.type.name.includes("INVENTORY"));
                    let reserve = batch.transactions.find(t => t.type.name.includes("RESV"));

                    this.setState({
                        savedInventoryTransaction: inventory || false,
                        savedReserveTransaction: reserve || false,
                        discrepancySaved: true
                    });

                    let manualCountPromise = Promise.resolve(null);
                    if (this._manualCount)
                        manualCountPromise = imClient.insertManualCount(this._getManualCount({discrepancyTransactionBatchId: batch.id}));

                    return manualCountPromise;
                })
                .then(product => {
                    if (product)
                        this.setState({manualCountSaved: true});
                })
        }
        else
            this.submitStandaloneManualCount();
    }

    getBatch(){
        let inventoryTransaction = Object.assign({}, this.state.rawInventoryTransaction);
        let reserveTransaction = Object.assign({}, this.state.rawReserveTransaction);

        let batch = [];
        if(this.state.rawReserveTransaction) {
            reserveTransaction.comment = this.state.comment;
            batch.push(reserveTransaction);
        }
        if( this.state.rawInventoryTransaction) {
            inventoryTransaction.comment = this.state.comment;
            batch.push(inventoryTransaction);
        }
        return batch;
    }

    submitStandaloneManualCount(){
        imClient.insertManualCount(this._getManualCount())
            .then(product => {
                this.setState({manualCountSaved: true});
            })
    }

    _getManualCount({discrepancyTransactionBatchId=null}={}){
        const manualCount = {
            productId: this.props.product.key.id,
            discrepancyTransactionBatchId: discrepancyTransactionBatchId,
        };

        if(this._inventoryManualCountInd)
            manualCount.inventoryCount = this._newFinalValueForInventory();
        if(this._reserveManualCountInd)
            manualCount.storeReservedCount = this._newFinalValueForReserve();
        return manualCount;
    }

    isSaveDisabled(){
        if(this._manualCountOnly)
            return false;
        return this.state.saveInvalid || this.state.comment === "" || (this.state.rawReserveTransaction == null && this.state.rawInventoryTransaction == null);
    }

    getCommentSection() {
        if (this.state.discrepancySaved || this.state.manualCountSaved) return "";
        return (
            <Row>
                <Col md={8}>
                    <FormGroup>
                        <Label for="comment"><strong>Comment</strong></Label>
                        <Input type="textarea" name="text" id="comment" value={this.state.comment} onChange={(e) => this.onCommentChange(e)}
                               invalid={this.state.saveInvalid}/>
                        <FormFeedback tooltip>Comment is required to save discrepancy</FormFeedback>
                    </FormGroup>
                </Col>
            </Row>
        );
    }

    getSubmittedAlert(){
        const {discrepancySaved, manualCountSaved} = this.state;
        if (!discrepancySaved && !manualCountSaved) return "";
        return  <Row className="d-flex justify-content-center">
            {discrepancySaved && <Alert color="success">
                Discrepancy Submitted
            </Alert>}
            {manualCountSaved && <Alert color="primary">
                Manual Count Saved
            </Alert>}
        </Row>
    }

    getButtonText(){
        const {manualCount} = this.state;
        if(this._manualCountOnly)
            return "Save Manual Count Only";
        return `Save Discrepancy ${manualCount ? "and Manual Count" : ""}`
    }

    get _manualCountOnly(){
        return !!(this._manualCount && this.getBatch().length === 0);
    }

    get _manualCount(){
        return this._inventoryManualCountInd || this._reserveManualCountInd;
    }

    get _inventoryManualCountInd(){
        const {inventoryManuallyCountedInd} = this.state;
        return inventoryManuallyCountedInd
    }

    get _reserveManualCountInd(){
        const {reserveManuallyCountedInd} = this.state;
        return reserveManuallyCountedInd;
    }

    render() {
        if(!this.props.product)
            return <div></div>;

        const saved = this.state.discrepancySaved || this.state.manualCountSaved;
        return (
            <div>
                <Row >
                    {this.props.hideCounters !== true && <Col lg={3}>
                        <Counters product={this.props.product}/>
                    </Col>}
                    <Col>
                        <h4>Discrepancy Details</h4>
                        <Card body>
                            <InventoryAdjustmentRow product={this.props.product} transaction={this.state.savedReserveTransaction}
                                                  adjustmentLogic={ReserveAdjustmentLogic}
                                                  saved={saved}
                                                  onTransactionChange={(transaction => this.setReserveTransaction(transaction))}
                                                  onManualCountChange={(ind => this.setReserveManualCount(ind))}
                            />
                            <InventoryAdjustmentRow product={this.props.product} transaction={this.state.savedInventoryTransaction}
                                                    adjustmentLogic={InventoryAdjustmentLogic}
                                                    saved={saved}
                                                    onTransactionChange={(transaction => this.setInventoryTransaction(transaction))}
                                                    onManualCountChange={(ind => this.setInventoryManualCount(ind))}

                            />

                            {this.getSubmittedAlert()}
                            {this.getCommentSection()}
                            <ActionAndReturnToPackingButtonsRow saveLabel={this.getButtonText()} saveOnClick={e => this.save()} productId={this.props.product.key.id}
                                                                disabled={this.isSaveDisabled()} hideSaveButton={saved} hideReturnToPacking={this.props.hideCounters}/>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default DiscrepancyDetails;
