function getNewCount({product, adjustment, isAutomaticallyApprovedInd}){
    return product.counters.counters.storeReserved  + ( isAutomaticallyApprovedInd ? 0 : adjustment);
}

function getTransactionType(adjustment){
    if(adjustment === 0)
        return "N/A";
    if(adjustment < 0)
        return "STORE-RESV-RM";
    else
        return "STORE-RESV-ADD-ADJ"

}

function getName(){
    return "Reserve"
}

const ReserveAdjustmentLogic = {
    getNewCount, getTransactionType, getName
};

export default ReserveAdjustmentLogic;