//TODO To be competitive this functionality should support "hard" and "soft" bundling.
//todo hard bundle: Child Inventory is allocated with transactions to parent products.
//todo this could be automated/manual based on demand. Example, if bundle A is popular
//todo it will present itself on the purchase rec as the child sub-components (things that make up bundle A).
//todo The system will then dynamically ALLOC inventory to the bundle. This is best suited for FBA since
//todo the products need to be shipped to Amazon.
//todo soft bundle: A parent SKU has a virtual inventory counter (virtual-inventory) added to its counters in the
//todo transactions system. The virtual-inventory is the hard availability (inventory) plus the moment in time
//todo availability of child components. For example, if bundle B is made up of widget X and two (2) of widget Z.
//todo There are 5 of widget X and 3 of widget Z the virtual. The inventory (hard) counter for bundle B is 5.
//todo Therefore, the virtual-inventory counter for bundle B is 5 + 1 = 6. If 2 of widget Z are sold (new inventory
//todo for widget Z is 1) the virtual-inventory for bundle B is now 5 + 0 = 5. There is also an allocated-inventory
//todo counter for child products. For widget Z the allocated-inventory counter would be 10. The total-inventory counter
//todo for widget Z would be inventory: 1, allocated-inventory: 10. 10 + 1 -> total-inventory: 11.
//TODO Management tools and transaction dependency will need to be added to transactions to support this behavior automatically.
//todo this is a perfect candidate for Mongodb ACID in MDB 4.0.

class ProductBundleMap{
    constructor({productId, quantity}){
        this.productId = productId;
        this.quantity = quantity;
    }

}

export { ProductBundleMap };