import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import {Badge, NavItem, NavLink} from 'reactstrap';
import paginationFactory from "react-bootstrap-table2-paginator";
import {TransactionApprovalBadge} from "../../atoms/TransactionApprovalBadge";
import {Link} from "react-router-dom";
import {CommentsCollapse} from "../../atoms/CommentsCollapse";
import {TransactionMetaLinks} from "../../atoms/TransactionMetaLinks";
import {TooltipWrapper} from "../../atoms/ToolTipLink";

class AmazonOrdersTable extends React.Component {
    static lpnLink(transaction){
        if(transaction.meta.returnLpn){
            return  <NavLink href={`https://returns.kjdelectronics.com/lpn/${transaction.meta.returnLpn}`}
                             target="_blank"><Badge>{transaction.meta.returnLpn}</Badge></NavLink>
        }
        return '';
    }

    render() {
        const {orders} = this.props;

        if(!orders || orders.length === 0)
            return <h3 className="font-italic">There are No Orders</h3>;

        const records = orders.map(o => {
            const newR = {...o};

            newR.id =  <NavLink className="compact" tag={Link} to={`/mfn/orders/${o.amazonOrderId}`}>{o.amazonOrderId}</NavLink> ;
            newR.date = new Date(o.purchaseDate).toLocaleDateString();
            newR.totalItems = o.orderItems.map(oi => oi.quantityOrdered).reduce((a, b) => a + b);
            newR.shippedItems = o.orderItems.map(oi => oi.quantityShipped).reduce((a, b) => a + b);
            newR.purchaseDate = new Date(o.purchaseDate);
            newR.orderItemList = o.orderItems.map(oi => {
                return <p>{oi.quantityOrdered}x - {oi.sellerSku} - {oi.title}</p>
            });

            return newR;
        });

        let options = {
            sizePerPageList: [ {
                    text: '10', value: 10
                },{
                    text: '25', value: 25
                },
            ],
            sizePerPage: this.props.sizePerPage ? this.props.sizePerPage :  25
        };
        if(orders.length <= 10)
            options = null;

        const columns = [
            {
                dataField: 'id',
                text: 'Order Id',
                headerStyle: () => {
                    return { width: "10%" };
                },
                sort: true
            },
            {
                dataField: 'orderStatus',
                text: 'Order Status',
                headerStyle: () => {
                    return { width: "8%" };
                },

            },
            {
                dataField: 'date',
                text: 'Date',
                headerStyle: () => {
                    return { width: "12%" };
                },
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    if (order === 'desc') {
                        return rowB.date.getTime() - rowA.date.getTime();
                    }
                    return rowA.date.getTime() - rowB.date.getTime();
                }
            },
            {
                dataField: 'numberOfItemsUnshipped',
                text: 'Unshipped Items',
                headerStyle: () => {
                    return { width: "8%" };
                },

            },

            {
                dataField: 'orderItemList',
                text: 'Items',
                headerStyle: () => {
                    return { width: "20%" };
                }
            },
        ];

        return (
            <div style={{width: "99%"}}>
                <BootstrapTable
                    condensed={true}
                    bootstrap4={true} keyField='id'
                    classes={"padding-2px-table"}
                    defaultSorted={[{dataField: "dateString", order: "desc"}]}
                    data={ records } pagination={ options ? paginationFactory(options) : null } columns={ columns } />
            </div> 
            );
    }
}

function centerFormatter(cell){
    return <div className="text-center">{cell}</div>;
}

AmazonOrdersTable.propTypes = {
    orders: PropTypes.array.isRequired,
    sizePerPage: PropTypes.number
};

export {AmazonOrdersTable};