import { ProductFinance } from '../../../product/finance/ProductFinance';
import { ProductCosts } from '../../../common/ProductCosts';
import { round } from 'mathjs';

class ProductMarketplaceFinanceCosts{
    constructor({baseCurrency:{productCombinedTotalCost,
                        marketplacePerUnitCostAdditions=ProductCosts.getZeroedProductCostsObject(),
                        }={}, baseToMarketExchangeRate}, {productFinance}){
        if(productFinance && !(productFinance instanceof ProductFinance))
            productFinance = new ProductFinance(productFinance);

        this.baseCurrency = {
            //The source of this data is from Marketplace level and should be kept up to date by Marketplace storage. This is a denormalization/repeated reference data for simplicity/readability
            marketplacePerUnitCostAdditions: marketplacePerUnitCostAdditions = new ProductCosts(marketplacePerUnitCostAdditions)
        };
        if(productFinance)
            this.baseCurrency.productCombinedTotalCost = round(productFinance.combinedTotalCost ,2) || round(productCombinedTotalCost, 2);
        else if(productCombinedTotalCost) //When a reference to the product object is not available use passed in value
            this.baseCurrency.productCombinedTotalCost = productCombinedTotalCost;
        else
            throw new Error('Cannot build ProductMarketplaceFinanceCosts. Must either provide a reference to the parents ProductFinance ' +
                'object or specify baseCurreny.productCombinedTotalCost');

        this.baseToMarketExchangeRate = baseToMarketExchangeRate;
        this.calculate();
    }

    calculate(){
        this.baseTotal = round(this.baseCurrency.productCombinedTotalCost + this.baseCurrency.marketplacePerUnitCostAdditions.total /*+ this.baseCurrency.additions.total*/, 2);
        this.marketTotal = round(this.baseTotal * this.baseToMarketExchangeRate, 2);
    }

    getForDb(){
        let {["baseToMarketExchangeRate"]:omit, ...res} = this;
        return res;
    }

}

export { ProductMarketplaceFinanceCosts };