import { round } from 'mathjs';
class ProductMarketplaceFinancePrice{
    constructor({validDate, marketVatInclusive, vatExclusiveRate,}, {baseToMarketExchangeRate}){
        this.validDate = new Date(validDate);
        this.marketVatInclusive = round(marketVatInclusive, 2);
        this.market = round(marketVatInclusive * vatExclusiveRate, 2);
        this.base = round(this.market * (1/baseToMarketExchangeRate), 2);
        this.vatExclusiveRate = vatExclusiveRate;
    }

}

export { ProductMarketplaceFinancePrice };