class ProductDetailSizeWeight{
    constructor({rawWeightGrams=null, shippingWeightGrams=null, sizeClassification=null} = {}){
        if(rawWeightGrams && typeof rawWeightGrams !== "number" )
            throw new Error("Type Error: rawWeightGrams Must be a number");
        if(shippingWeightGrams && typeof shippingWeightGrams !== "number" )
            throw new Error("Type Error: shippingWeightGrams Must be a number");

        this.rawWeightGrams = rawWeightGrams;
        this.shippingWeightGrams = shippingWeightGrams;
        this.sizeClassification = sizeClassification;
    }

}

export { ProductDetailSizeWeight };