import React, {Component} from 'react';
import {Button, Modal, ModalHeader, ModalFooter, ModalBody, Card, Row, Col, Input, Alert} from 'reactstrap';
import PropTypes from "prop-types";
import ProductsTable from "../product/ProductsTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowLeft, faArrowRight, faCaretUp, faSync} from '@fortawesome/free-solid-svg-icons'
import imClient from "../service/ImClientSingleton";
import eventClient from "../service/EventsClientSingleton";
import DiscrepancyDetails from "../discrepancy/DiscrepancyDetails";
import Badge from "reactstrap/es/Badge";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

class FbaInboundShipmentDetailModal extends Component {
    constructor(props) {
        super(props);
    }

    handleProductCountersUpdate(e){

    }

    async componentDidMount() {

    }

    componentDidUpdate(prevProps){

    }

    renderBody(){
        let options = {
            sizePerPageList: [ {
                text: '10', value: 10
            },{
                text: '25', value: 25
            }],
            sizePerPage: this.props.sizePerPage ? this.props.sizePerPage :  25,
        };

        const records = this.props.shipment.shipmentItems.map((i, index) => {
            const newR = {...i};

            newR.id = i.product.key.id;
            newR.name = i.product.detail.name;
            newR.usShipReadyCount = i.product.counters ? i.product.counters.counters ? i.product.counters.counters["us-shipReady"] ? i.product.counters.counters["us-shipReady"] : 0 : 0 : 0;

            return newR;
        });


        const columns = [
            {
                dataField: 'id',
                text: 'KID',
                headerStyle: () => {
                    return { width: "5%" };
                },
                formatter: kidFormatter,
                sort: true
            },
            {
                dataField: 'name',
                text: 'Name',
                headerStyle: () => {
                    return { width: "30%" };
                }
            },
            {
                dataField: 'usShipReadyCount',
                text: 'US Ship Ready',
                headerStyle: () => {
                    return { width: "7%" };
                }
            },
            {
                dataField: 'quantityShipped',
                text: 'Shipment Count',
                headerStyle: () => {
                    return { width: "7%" };
                }
            },
            {
                dataField: 'quantityReceived',
                text: 'Units Received By Amazon',
                headerStyle: () => {
                    return { width: "7%" };
                }
            },
        ];

        return (
            <div style={{width: "99%"}}>
                <BootstrapTable
                    condensed={true}
                    bootstrap4={true}
                    keyField='id'
                    classes={"padding-2px-table"}
                    data={ records } pagination={ options ? paginationFactory(options) : null } columns={ columns } />
            </div>
        );
    }

    render() {
        const {onCancel, shipment} = this.props;
        const validation = shipment.importStatus.validation;
        return (
            <div>
                <Modal isOpen={true} toggle={e => onCancel()} className={this.props.className} size="lg">
                    <ModalHeader toggle={e => onCancel()}>FBA Inbound Shipment Detail - {shipment.shipmentId}</ModalHeader>
                    <ModalBody>
                        <h3>Shipment Items</h3>
                        {this.renderBody()}
                        {shipment.importStatus.isFullyImported && <h4><Badge color="success">Fully Imported</Badge></h4>}
                        {validation &&
                            <div>
                            <h3> Validation</h3>
                            {validation && validation.valid && <h4><Badge color="success">Valid to Import</Badge></h4>}
                            {validation && validation.valid === false &&
                               <Alert color="warning">
                                   <ul>
                                        {validation.errors.map(error => <li>{error.message}</li>)}
                                   </ul>
                               </Alert>
                            }
                            </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={e => onCancel()}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}


function kidFormatter(id){
    return <a tabIndex={-1} href={`https://sales.kjdelectronics.com/products/${id}`}
              target="_blank">K{id}</a>
}

FbaInboundShipmentDetailModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    shipment: PropTypes.object.isRequired,
};

export {FbaInboundShipmentDetailModal};
