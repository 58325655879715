import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import {Badge, Button} from "reactstrap";

const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
});
const currency = (cell, row) => usdFormatter.format(cell);


const columns = [
    {
        dataField: 'shippingServiceOfferId',
        text: 'id',
        hidden: true
    },
    {
        dataField: 'carrierName',
        text: 'Carrier'
    },
    {
        dataField: 'shippingServiceName',
        text: 'Service',
        headerStyle: {"width": "20%"}
    },
    {
        dataField: 'deliveryEstimate',
        text: 'Delivery Estimate'
    },
    {
        dataField: 'rate',
        text: 'Rate',
        sort: true,
        formatter: currency

    },
    {
        dataField: 'buyButton',
        text: 'Buy Label'
    }
    ];

class MfnShipmentRateQuotesTable extends React.Component {
    render() {
        const {serviceList} = this.props;
        if(!serviceList || serviceList.length === 0)
            return "";
        const records = serviceList.map(quote => {
            const newR = {...quote};
            const deliveryEstimate = new Date(quote.earliestEstimatedDeliveryDate);

            newR.deliveryEstimate = deliveryEstimate.toLocaleDateString();
            newR.rate = quote.rate ? quote.rate.amount : 0;
            newR.buyButton = <Button color="primary" onClick={() => this.props.buyLabelFunc(newR)}>Buy Label</Button>

            return newR;
        });
        return (
            <div style={{width: "99%"}}>
                <BootstrapTable
                    defaultSorted={[{dataField: "rate", order: "asc"}]}
                    condensed={true} bootstrap4={true} keyField='shippingServiceOfferId' data={ records } columns={ columns } />
            </div> 
            );
    }
}

MfnShipmentRateQuotesTable.propTypes = {
    serviceList: PropTypes.object.isRequired,
    buyLabelFunc: PropTypes.func.isRequired
};

export {MfnShipmentRateQuotesTable};