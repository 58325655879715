class ProductDetailIndicators{
    constructor({archived = false, ignoreShopifyListingRequired = false, supplierOutOfStock = false, isManualCountRequired = false, euCeMarkRequired = false}={}){
        if(typeof archived !== 'boolean' || typeof ignoreShopifyListingRequired !== 'boolean' ||
            typeof supplierOutOfStock !== 'boolean' )
            throw 'Invalid indicator (must be boolean)';

        this.archived = archived;
        this.ignoreShopifyListingRequired = ignoreShopifyListingRequired;
        this.supplierOutOfStock = supplierOutOfStock;
        this.isManualCountRequired = isManualCountRequired;
        this.euCeMarkRequired = euCeMarkRequired;
    }

}

export { ProductDetailIndicators };