import React, {Component} from 'react';
import { Tooltip } from 'reactstrap';
import PropTypes from "prop-types";

class TooltipWrapper extends Component {
    state = {
        tooltipOpen: false
    };

    render() {
        return <div>
            {this.props.children}
            <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={this.props.id} toggle={() => this.setState({tooltipOpen: !this.state.tooltipOpen})}>
                {this.props.tooltip}
            </Tooltip>
        </div>
    }
}

TooltipWrapper.propTypes = {
    tooltip: PropTypes.string,
    id: PropTypes.string.isRequired
};

export {TooltipWrapper};
