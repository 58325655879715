class UserRef{
    constructor({id, username, displayName, groups=null, firstname=null, lastname=null}){
        this.id = id;
        if(!this.id)
            throw "User id is required";

        this.username = username;
        if(!username)
            throw "Username (name) must be provided for reference";

        this.groups = groups;
        if(displayName)
            this.displayName = displayName;
        else
            this.displayName = `${firstname} ${lastname}`;

        if(!this.displayName)
            throw "displayName or first + last names must be provided for reference";
    }

    isMemberOfGroup(groupName){
        if(this.groups == null)
            throw new Error('Cannot check group membership. This User Reference object does not have group information');
        return UserRef.groupNameIsMemberOfGroupsArray({groupName: groupName, groupsArray: this.groups});
    }

    getUserRefForDb(){
        let {["groups"]:omit, ...res} = this;
        return res;
    }

    toJson(){
        return this.getUserRefForDb();
    }

    //TODO These methods are duplicated from User. Removed reference to user to make this a pure domain object.
    static standardizeGroupName(groupName){
        groupName = groupName.toUpperCase();
        groupName = groupName.replace(/[\s-]/g, '_'); //Replace whitespace and - with underscore
        groupName = groupName.replace(/_+/g,'_'); //Replace consecutive underscore with single
        return groupName;
    }

    static groupNameIsMemberOfGroupsArray({groupName, groupsArray}){
        return groupsArray.indexOf(UserRef.standardizeGroupName(groupName)) > -1;
    }
}

export { UserRef };