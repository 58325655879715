import React, {Component} from 'react';
import { FormGroup, Label, Input, Card, CardBody, CardHeader, ListGroup, Alert, Table, Row, Col, Button } from 'reactstrap';
import ImClient from "../service/ImClientSingleton"
import ApprovableListItem from "../discrepancy/ApprovableListItem";
import ApprovablesTable from "../discrepancy/ApprovablesTable";

class ReserveActivityPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        this.refreshSate();
    }

    refreshSate(){
    }

    render() {
        return (
            <div className="App-Width">
                <h1>Reserve Activity</h1>
            </div>
        );
    }
}

export default ReserveActivityPage;
