import React, {Component} from 'react';
import {ReserveManagementTable} from "../molecules/tables/ReserveManagementTable";
import transactionsClient from "../../client/transactionsClient";
import salesLiteClient from "../../client/sales.lite.client";
import ImClient from "../service/ImClientSingleton"
import {FormGroup, Input, Label} from "reactstrap";

class ReserveManagementPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            productDirectSales1yr: null,
            absProductCounters1yr: null,
            showArchived: false,
            excludeFullyStockedReserves: false
        };
    }

    componentDidMount() {
        return this.refreshSate();
    }

    async refreshSate(){
        const products = await ImClient.getAllProducts({noCache: true});
        await this.setState({products});

        let startDate = new Date();
        startDate.setFullYear(startDate.getFullYear() - 1);
        const absProductCounters1yrArray = await transactionsClient.productCounters.getProductCountersAbs({startDate});
        const absProductCounters1yr = {};
        absProductCounters1yrArray.forEach(p => absProductCounters1yr[p.productId] = p);
        await this.setState({absProductCounters1yr});

        const regions = await salesLiteClient.getRegions();
        const usDirectRegion = regions.find(r => r.region === "us-direct");
        const usDirectMarketplaceIds = usDirectRegion.marketplaces.map(m => m.marketplaceId);
        const salesResult = await salesLiteClient.getProductSales({lookBackDays: 365, marketplaceIds: usDirectMarketplaceIds});
        const productDirectSales1yr = {};
        salesResult.products.forEach(p => productDirectSales1yr[p.productId] = p);
        return this.setState({productDirectSales1yr});
    }

    render() {
        const {products, productDirectSales1yr, absProductCounters1yr, excludeFullyStockedReserves} = this.state;
        let shownProducts = products;
        if(!this.state.showArchived)
            shownProducts = products.filter(p => !p.detail.indicators.archived);

        //TODO Add Filter checkbox to remove archived products
        return (
            <div className="App-Width">
                <div className="d-inline-block">
                    <h1 className="d-inline-block">Reserve Management</h1>
                    <FormGroup className="d-inline-block pb-2">
                        <Label className="ml-3 pr-4"><strong>Show Archived</strong></Label>
                        <Input type="checkbox" checked={this.state.showArchived} onChange={(e) => this.setState({showArchived: e.target.checked})}/>
                    </FormGroup>
                    <FormGroup className="d-inline-block pb-2">
                        <Label className="ml-3 pr-4"><strong>Exclude Fully Stocked Reserve</strong></Label>
                        <Input type="checkbox" checked={excludeFullyStockedReserves} onChange={(e) => this.setState({excludeFullyStockedReserves: e.target.checked})}/>
                    </FormGroup>
                </div>
                <ReserveManagementTable products={shownProducts} excludeFullyStockedReserves={excludeFullyStockedReserves}
                                        absProductCounters1yr={absProductCounters1yr} productDirectSales1yr={productDirectSales1yr}/>
            </div>
        );
    }
}

export default ReserveManagementPage;
