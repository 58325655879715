import React, {Component} from 'react';
import {Button, Modal, ModalHeader, ModalFooter, ModalBody, Card, Row, Col, Input, Alert} from 'reactstrap';
import PropTypes from "prop-types";
import ProductsTable from "../product/ProductsTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowLeft, faArrowRight, faCaretUp} from '@fortawesome/free-solid-svg-icons'
import imClient from "../service/ImClientSingleton";
import eventClient from "../service/EventsClientSingleton";
import DiscrepancyDetails from "../discrepancy/DiscrepancyDetails";

class DiscrepancyModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: null
        };
        //eventClient.subscribe((e) => this.handleProductCountersUpdate(e))
    }

    handleProductCountersUpdate(e){
        if(e.type === "counters.product"){
            if(this.state.product && e.data.productId === this.state.product.key.id){
                let product = Object.assign({}, this.state.product);
                product.counters = e.data;
                this.setState({product});
            }
        }
    }

    async componentDidMount() {
        await this._getProduct();
        return this._getProductCounters();
    }

    componentDidUpdate(prevProps){

    }

    async _getProduct(){
        const {productId} = this.props;

        this.setState({isLoading: true});
        return imClient.getProductById(productId)
            .then(product => {
                this.setState({
                    isLoading: false,
                    product
                });
            }).catch(error => {
                this.setState({
                    isLoading: false,
                    error: error
                });
            });
    }

    async _getProductCounters(){
        return imClient.getProductCounters(this.props.productId)
            .then(counters => {
                let product = Object.assign({}, this.state.product);
                product.counters = counters;
                this.setState({product});
                if(this.props.onCounterUpdate)
                    this.props.onCounterUpdate(counters);
            }).catch(error => {
                this.setState({
                    error: error
                });
            });
    }

    render() {
        const {product} = this.state;
        const {onCancel} = this.props;
        return (
            <div>
                <Modal isOpen={true} toggle={e => onCancel()} className={this.props.className} size="lg">
                    <ModalHeader toggle={e => onCancel()}>Discrepancy - K{this.props.productId} - {product ? product.detail.shortName : ""}</ModalHeader>
                    <ModalBody>
                        <DiscrepancyDetails product={this.state.product} hideCounters={true}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={e => onCancel()}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

DiscrepancyModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    productId: PropTypes.number.isRequired,
    onCounterUpdate: PropTypes.func
};

export {DiscrepancyModal};
