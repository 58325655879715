import React, {Component} from 'react';
import {
    Button,
    NavLink
} from 'reactstrap';
import ImClient from "../service/ImClientSingleton"
import ApprovableListItem from "../discrepancy/ApprovableListItem";
import ApprovablesTable from "../discrepancy/ApprovablesTable";
import transactionsClient from "../../client/transactionsClient";
import {TransactionsTable} from "../molecules/tables/TransactionsTable";
import {Link} from "react-router-dom";
import eventClient from "../service/EventsClientSingleton";

class TransactionsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            recentTransactions: []
        };

        eventClient.subscribe((e) => this.handleTransactionUpdate(e))
    }

    componentDidMount() {
        this.refreshSate();
    }

    handleTransactionUpdate(e){
        let recentTransactions = [...this.state.recentTransactions];
        if(e.type === "transaction"){
            if(recentTransactions){
                //Update Existing In List
                const indexOf = recentTransactions.findIndex(t => t.ref === e.data.ref);
                if(indexOf !== -1) {
                    recentTransactions[indexOf] = e.data;
                }
                else{
                    recentTransactions.unshift(e.data);
                }
                this.setState({recentTransactions});
            }
        }
    }

    refreshSate(){
        transactionsClient.transactions.getRecentTransactions()
            .then(recentTransactions => {
                this.setState({recentTransactions});
            })
    }

    get newTransactionButton(){
        return <Button size="sm" color="success"  data-toggle="tooltip" data-placement="right" title="New Transaction">
            <NavLink style={{color: "white"}} className="compact" tag={Link} to={`/transactions/new`}>New</NavLink>
        </Button>
    }

    render() {
        return (
            <div className="App-Width">
                <h1>Transactions {this.newTransactionButton}</h1>
                <TransactionsTable transactions={this.state.recentTransactions}/>
            </div>
        );
    }
}

export default TransactionsPage;
