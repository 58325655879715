import { UserRef } from '../obj/UserRef';
import { Transaction } from './Transaction';

class TransactionBatch{
    constructor({_id, id, batchName, transactions, user, createdDate, voidedDate}){
        this.id = _id || id;
        if(this.id)
            this.id = this.id.toString(); //Make sure it is not an objectId

        this.batchName = batchName;

        if(transactions) {
            if (!Array.isArray(transactions))
                throw new Error(`transactions must be an array of Transaction objects for TransactionBatch`);
            this.transactions = [];
            transactions.forEach(t => {
                if(t instanceof Transaction)
                    this.transactions.push(t);
                else
                    this.transactions.push(new Transaction(t));
            });
        }

        this.user = user instanceof UserRef ? user : new UserRef(user);
        this.createdDate = createdDate ? new Date(createdDate) : new Date();

        if(voidedDate)
            this.voidedDate = new Date(voidedDate);
    }

    getForDb(){
        let {["id"]:omit, ...res} = this;
        delete res.transactions;
        res.user = this.user.getUserRefForDb();
        return res;
    }

    isVoidable(){
        return !this.voidedDate;
    }

    toString(){
        let str = "";
        this.transactions.forEach(t => {
            if(str !== "")
                str += "\r\n";
            str += '- ' + t.toRefString();
        });
        return str;
    }

    toHtmlString(){
        let str = "";
        this.transactions.forEach(t => {
            str +=  `<li>${t.toRefString()}</li>`;
        });
        return `<ul>${str}</ul>`;
    }
}

export { TransactionBatch };