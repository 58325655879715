class ProductMarketplaceFbaStorageHealth {
    constructor({asinLimit, isHazmat, itemVolume,  volumeUnits, salesRank, removalInProgressCount, healthyInventoryLevel,
                    inboundRecommendedQuantity, sellThrough, inventoryAge={}, unitsShipped={}, weeksOfCover={}, longTermStorage={},
                    updatedDate=new Date()}){
        this.asinLimit = asinLimit;
        this.isHazmat = isHazmat;
        this.itemVolume = itemVolume;
        this.volumeUnits = volumeUnits;
        this.salesRank = salesRank;
        this.sellThrough = sellThrough;
        this.removalInProgressCount = removalInProgressCount;
        this.healthyInventoryLevel = healthyInventoryLevel;
        this.inboundRecommendedQuantity = inboundRecommendedQuantity;
        this.inventoryAge = inventoryAge;
        this.unitsShipped = unitsShipped;
        this.weeksOfCover = weeksOfCover;

        this.updatedDate = new Date(updatedDate);
    }

    //The statics below could move to mws3 but keeping them here enables better auto completion. This object is used in mws3 record
    static buildInventoryAgeFromMws(mwsReportData){
        return {
            age0To90: mwsReportData['inv-age-0-to-90-days'],
            age91To180: mwsReportData['inv-age-91-to-180-days'],
            age181To270: mwsReportData['inv-age-181-to-270-days'],
            age271To365: mwsReportData['inv-age-271-to-365-days'],
            age365Plus: mwsReportData['inv-age-365-plus-days'],
        }
    }

    static buildUnitsShippedFromMws(mwsReportData){
        return {
            last7Days: mwsReportData['units-shipped-last-7-days'],
            last30Days: mwsReportData['units-shipped-last-30-days'],
            last90Days: mwsReportData['units-shipped-last-90-days'],
        }
    }

    static buildWeeksOfCoverFromMws(mwsReportData){
        return {
            from7DaySales: mwsReportData['weeks-of-cover-t7'],
            from30DaySales: mwsReportData['weeks-of-cover-t30'],
            from90DaySales: mwsReportData['weeks-of-cover-t30'],
            from180DaySales: mwsReportData['weeks-of-cover-t180'],
            from365DaySales: mwsReportData['weeks-of-cover-t365'],
        }
    }
}

export { ProductMarketplaceFbaStorageHealth };