import React, {Component} from 'react';
import { FormGroup, Label, Input, Card, CardBody, CardHeader, ListGroup, Alert, Table, Row, Col, Button } from 'reactstrap';
import ApprovableListItem from "../discrepancy/ApprovableListItem";

class ApprovablesTable extends Component {
    constructor(props) {
        super(props);
    }

    getItems(){
        if(!this.props.approvables)
            return "";
        return this.props.approvables.map((approvable) =>
            <ApprovableListItem approvable={approvable}/>
        );
    }

    render() {
        return (
            <Table striped>
                <thead>
                <tr>
                    <th>Transaction ID</th>
                    <th>KID</th>
                    <th>Product</th>
                    <th>Type</th>
                    <th>QTY</th>
                    <th>Date</th>
                    <th>User</th>
                    <th>Comment</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                    {this.getItems()}
                </tbody>
            </Table>
        );
    }
}

export default ApprovablesTable;
