let controller = {};

controller.validateCounters = function (counters) {
    if(!counters || !Array.isArray(counters))
        throw "counters must be an array";

    counters.forEach((counter, index)=> {
        if(!counter.name || typeof counter.value !== "number")
            throw `Invalid counter in Counters array at index ${index}`
    });
};

export default controller;