import React, {Component} from 'react';
import { FormGroup, Label, Input, Badge, Row, Col } from 'reactstrap';

class InventoryAdjustmentRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            adjustment: 0,
            manualCountChecked: false
        };

        this._checkBoxName = `manualCount${Math.floor(Math.random() * 1000000)}`
    }

    onManualCheckboxChange(e) {
        let checked = e.target.checked;
        this.setState({manualCountChecked: checked});
        if(this.props.onManualCountChange)
            this.props.onManualCountChange(checked);
    }

    onAdjustmentChange(e){
        this.setState({
            adjustment: parseInt(e.target.value),
        }, () => this.callTransactionUpdatedCallback());
    }

    callTransactionUpdatedCallback(){
        if(this.props.onTransactionChange)
            this.props.onTransactionChange(this.getTransaction());
    }

    getTransaction(){
        const {adjustment, manualCountChecked} = this.state;
        const {product} = this.props;
        let type = this._transactionType;
        if(type === "N/A")
            return null;
        return {
            typeName: type,
            itemCount: Math.abs(adjustment),
            productId: product.key.id,
            _newFinalValueForCounter: this._newCount()
        }
    }

    _isAutomaticallyApproved(){
        if(!this.props.saved || !this.props.transaction)
            return false;
        return this.props.transaction.approval.autoApprovalInd;
    }


    renderStatusCol(){
        if(!this.props.saved)
            return "";
        let color, text;
        if(!this.props.transaction){
            color = "default";
            text = "Unchanged";
        }
        else {
            color = this._isAutomaticallyApproved() ? "success" : "primary";
            text = this._isAutomaticallyApproved() ? "Automatically Approved" : "Pending Approval";
        }
        if(this.state.manualCountChecked)
            text = text + " (Manual Count)";

        return (<Col>
            <FormGroup>
                <Label><strong>Status</strong></Label>
                <h3 style={{"text-align": "left"}}><Badge color={color}>{text}</Badge></h3>
            </FormGroup>
        </Col>)
    }

    renderAdjustmentInputOrLabel(){
        let qty = this.props.saved ? this.state.adjustment : "N/A";
        if(this.props.saved){
            return  <div className="font-medium">{qty}</div>
        }
        else{
            return <Input className="middle font-large" type="number"
                          value={this.state.adjustment}
                          max={this.props.hasOwnProperty('maxAdjustment') ? this.props.maxAdjustment : null}
                          onChange={e => this.onAdjustmentChange(e)} />
        }
    }

    renderManualCountCol(){
        if(!this.props.saved){
            return  <Col>
                <FormGroup>
                    <Label for={this._checkBoxName} className="pr-4"><strong>Manual Count</strong></Label>
                    <Input type="checkbox" name={this._checkBoxName} id={this._checkBoxName} checked={this.state.manualCountChecked} onChange={(e) => this.onManualCheckboxChange(e)}/>
                </FormGroup>
            </Col>
        }
    }

    _newCount(){
        const {product, adjustmentLogic} = this.props;
        const {adjustment} = this.state;
        return adjustmentLogic.getNewCount({product, adjustment, isAutomaticallyApprovedInd: this._isAutomaticallyApproved()})
    }

    get _transactionType(){
        return this.props.adjustmentLogic.getTransactionType(this.state.adjustment);
    }



    render() {
        const {adjustmentLogic} = this.props;
        return (
                <Row>
                    <Col lg={3}>
                        <FormGroup>
                            <Label><strong>{adjustmentLogic.getName()} Adjustment</strong></Label>
                            {this.renderAdjustmentInputOrLabel()}
                        </FormGroup>
                    </Col>
                    <Col lg={3}>
                        <FormGroup>
                            <Label><strong>Transaction Type</strong></Label>
                            <div className="font-medium">{this._transactionType}</div>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label><strong>New {adjustmentLogic.getName()}</strong></Label>
                            <div className="font-large">
                                <strong >{this._newCount()}</strong>
                                {this.state.adjustment === 0 && " (Unchanged)"}
                            </div>
                        </FormGroup>
                    </Col>
                    {this.renderManualCountCol()}
                    {this.renderStatusCol()}
                </Row>
        );
    }
}

export default InventoryAdjustmentRow;
