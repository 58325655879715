import React, {Component} from 'react';
import {
    Button,

    Badge
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faDownload, faSync, faMinusCircle, faEye} from '@fortawesome/free-solid-svg-icons'
import mws3Client from "../../client/mws3.client";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {FbaInboundShipmentDetailModal} from "./FbaInboundShipmentDetailModal";
import {LoadingSpinner} from "../atoms/LoadingSpinner";
const AMAZON_US = "ATVPDKIKX0DER";
const AUTO_FETCH_SHIPMENT_COUNT = 5;

class FbaInboundShipmentTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recentShipments: null,
            updateInProgress: false,
            modalShipment: null
        };
    }

    async componentDidMount() {
        await this._getShipments();
        let shipmentsUpdated = 0;

        if(this.state.recentShipments == null)
            return;

        for (let i = 0; i < this.state.recentShipments.length; i++) {
            const shipment = this.state.recentShipments[i];
            if(!shipment.transactions || shipment.transactions.length === 0) {
                await this._getShipment(shipment.shipmentId);
                shipmentsUpdated++;
            }
            if(shipmentsUpdated >= AUTO_FETCH_SHIPMENT_COUNT)
                return;
        }
    }

    componentDidUpdate(prevProps){

    }

    async _getShipments(){
        this.setState({isLoading: true});
        return mws3Client.getRecentShipments({marketplaceId: AMAZON_US})
            .then(recentShipments => {
                this.setState({
                    isLoading: false,
                    recentShipments
                });
            }).catch(error => {
                this.setState({
                    isLoading: false,
                    error: error
                });
            });
    }

    async _getShipment(shipmentId){
        this.setState({updateInProgress: true});
        mws3Client.getShipment({marketplaceId: AMAZON_US, shipmentId})
            .then(shipment => {
                const shipments = [...this.state.recentShipments];
                const index = shipments.findIndex(s => s.shipmentId === shipmentId);
                shipments[index] = shipment;
                this.setState({recentShipments: shipments});
            })
            .finally(() => this.setState({updateInProgress: false}))
    }

    async _importShipment(shipmentId){
        this.setState({updateInProgress: true});
        mws3Client.importShipment({marketplaceId: AMAZON_US, shipmentId})
            .then(shipment => {
                const shipments = [...this.state.recentShipments];
                const index = shipments.findIndex(s => s.shipmentId === shipmentId);
                shipments[index] = shipment;
                this.setState({recentShipments: shipments});
            })
            .finally(() => this.setState({updateInProgress: false}))
    }

    async _voidShipment(shipmentId){
        this.setState({updateInProgress: true});
        mws3Client.voidShipmentImport({marketplaceId: AMAZON_US, shipmentId})
            .then(shipment => {
                const shipments = [...this.state.recentShipments];
                const index = shipments.findIndex(s => s.shipmentId === shipmentId);
                shipments[index] = shipment;
                this.setState({recentShipments: shipments});
            })
            .finally(() => this.setState({updateInProgress: false}))
    }


    getImportStatusBadge(shipment){
        if(shipment.importStatus && shipment.importStatus.isFullyImported)
            return <Badge color="success">Fully Imported</Badge>;
        else if(shipment.importStatus && shipment.importStatus.validation){
            if(shipment.importStatus.validation.valid)
                return <Badge color="primary">Ready for Import</Badge>;
            else
                return <Badge color="danger">Import Invalid</Badge>;
        }
        else if(shipment.transactions && shipment.transactions.length > 0)
            return <Badge color="success">Imported</Badge>;
        else
            return <Badge color="warning">Import Needed</Badge>;


    }

    getActionButtons({shipment, index}){
        return <div>
            {!shipment.shipmentItemCount &&
            <Button disabled={this.state.updateInProgress} onClick={() => this._getShipment(shipment.shipmentId)} size="sm"
                    color="secondary"><FontAwesomeIcon icon={faSync}/></Button> }
            {shipment.shipmentItemCount > 0 && !shipment.importStatus.isFullyImported && shipment.importStatus.validation?.valid &&
            <Button disabled={this.state.updateInProgress} size="sm" color="success" onClick={() => this._importShipment(shipment.shipmentId)}
                ><FontAwesomeIcon icon={faDownload}/></Button> }
            {shipment.transactionCount !== 0 &&
            <Button disabled={this.state.updateInProgress} size="sm" color="danger" onClick={() => this._voidShipment(shipment.shipmentId)}
                ><FontAwesomeIcon icon={faMinusCircle}/></Button>}
            {shipment.shipmentItemCount > 0 &&
            <Button size="sm" color="info" onClick={() => {this.setState({modalShipment: shipment})}}><FontAwesomeIcon icon={faEye} /></Button>}
        </div>
    }

    render() {
        const {recentShipments} = this.state;

        if(!recentShipments || recentShipments.length === 0)
            return <div className="text-center mb-3 pb-5 font-italic"><LoadingSpinner/>Loading Shipments from Amazon...</div>;

        const records = recentShipments.map((i, index) => {
            const newR = {...i};

            if(i == null){
                console.log(`Received ${i} shipment`);
                return {}
            }

            newR.shipmentId = i.shipmentId; //TODO Link to Shipment Import Page with Full Detail
            newR.shipmentItemCount = i.shipmentItems ? i.shipmentItems.length : null;
            newR.transactionCount = i.transactions ? i.transactions.length : 0;
            newR.statusBadge = this.getImportStatusBadge(newR);
            newR.actions = this.getActionButtons({shipment: newR, index}); //TODO Refresh (gets with items), import when indicated, void when transactions

            return newR;
        });

        let options = {
            sizePerPageList: [ {
                text: '10', value: 10
            },{
                text: '25', value: 25
            }],
            sizePerPage: this.props.sizePerPage ? this.props.sizePerPage :  25,
        };

        const columns = [
            {
                dataField: 'shipmentId',
                text: 'Shipment Id',
                headerStyle: () => {
                    return { width: "5%" };
                },
                sort: true
            },
            {
                dataField: 'shipmentName',
                text: 'Name',
                headerStyle: () => {
                    return { width: "12%" };
                },
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    if (order === 'desc') {
                        return rowB.date.getTime() - rowA.date.getTime();
                    }
                    return rowA.date.getTime() - rowB.date.getTime();
                }
            },
            {
                dataField: 'destinationFulfillmentCenterId',
                text: 'Destination',
                headerStyle: () => {
                    return { width: "7%" };
                }
            },
            {
                dataField: 'shipmentItemCount',
                text: 'SKU Count',
                headerStyle: () => {
                    return { width: "14%" };
                },
                formatter: (cell, row) => {
                    if(cell == null)
                        return <span className="font-italic">Click <FontAwesomeIcon icon={faSync} className="mx-1"/> to see items and validation</span>;
                    return cell;
                }
            },
            {
                dataField: 'transactionCount',
                text: 'Transactions',
                headerStyle: () => {
                    return { width: "7%" };
                }
            },
            {
                dataField: 'statusBadge',
                text: 'Status',
                headerStyle: () => {
                    return { width: "10%" };
                }
            },
            {
                dataField: 'actions',
                text: 'Actions',
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
        ];

        return (
            <div style={{width: "99%"}}>
                Shipments
                <BootstrapTable
                    condensed={true}
                    bootstrap4={true}
                    keyField='id'
                    classes={"padding-2px-table"}
                    data={ records } pagination={ options ? paginationFactory(options) : null } columns={ columns } />
                {this.state.modalShipment && <FbaInboundShipmentDetailModal shipment={this.state.modalShipment} onCancel={() => this.setState({modalShipment: null}) } />}
            </div>
        );
    }
}

FbaInboundShipmentTable.propTypes = {

};

export {FbaInboundShipmentTable};
