class Payment{
    constructor({paymentInput, transactionRef, itemCount}){
        if(!paymentInput || !paymentInput.amount)
            throw `Invalid payment object (no data)  for transaction id ${transactionRef}`;

        if( typeof paymentInput.amount.perItemCommission !== "number" ||
            typeof paymentInput.amount.perItemPurchaseCost !== "number" ||
            typeof paymentInput.amount.other !== "number")
            throw `Invalid payment.amount.perItemCommission, payment.amount.perItemPurchaseCost or payment.amount.other (NaN) for transaction id ${transactionRef}`;

        if(typeof itemCount !== "number" && itemCount < 0)
            throw `Invalid item count constructing payment for transaction id ${transactionRef}`;

        this.amount = {
            perItemCommission: paymentInput.amount.perItemCommission,
            perItemPurchaseCost: paymentInput.amount.perItemPurchaseCost,
            other: paymentInput.amount.other,
            total: null
        };

        //Calculate total
        let total = (this.amount.perItemCommission + this.amount.perItemPurchaseCost ) * itemCount + this.amount.other;

        //Validate
        if(paymentInput.amount.total && paymentInput.amount.total !== total)
            throw `Invalid payment.amount.total does not equal the calculated total for transaction id ${transactionRef}. This means the total was manually altered and is incorrect`;

        this.amount.total = total;

        if(paymentInput.invoice){
            if(!paymentInput.invoice.id || !paymentInput.invoice.date)
                throw `Invalid payment.invoice object provided for transaction id ${transactionRef}`;

            this.invoice = {
                id: paymentInput.invoice.id,
                date: new Date(paymentInput.invoice.date)
            };
        }
    }
}

export { Payment };