import React, {Component} from 'react';
import { FormGroup, Label, Input, Card, CardBody, CardHeader, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import client from '../service/ImClientSingleton'
import eventClient from '../service/EventsClientSingleton'
import {ProductSearchModal} from "./ProductSearchModal";

class ProductSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            showSearchModal: false
        };
        this.currentSearchTimestamp = null;
        this.searchPending = false;

        this._product = null;

        eventClient.subscribe((e) => this.handleProductCountersUpdate(e))
    }

    componentDidMount() {
        if(this.props.initialProductId) {
            let t = this;
            client.getProductById(this.props.initialProductId)
                .then(product => {
                    t._product = product;
                    t.props.onProductChange(product);
                });
        }
    }

    handleProductCountersUpdate(e){
        if(e.type === "counters.product"){
            if(this._product && e.data.productId === this._product.key.id){
                console.log("Updating counters for selected product");
                this._product.counters = e.data;
                this.props.onProductChange(this._product);
            }
        }
    }

    handleChange(e) {
        this.setState({
            searchText: e.target.value,
        });
        this.currentSearchTimestamp = new Date().getTime();
        const scopeSearch = this.currentSearchTimestamp;

        if(e.target.value === ""){
            this.props.onProductChange(null);
            return;
        }
        this.searchPending = true;
        let t = this;

        client.searchForProduct(e.target.value)
            .then(product => {
                if(scopeSearch === t.currentSearchTimestamp) {
                    this._product = product;
                    t.props.onProductChange(product);
                    this.setClearTimeout();
                }
            })
            .catch(err => {
               if(err.statusCode !== 404){
                    return; //TODO Error message
                }
                if(scopeSearch === t.currentSearchTimestamp) {
                    this._product = null;
                    t.props.onProductChange(null);
                }
            })
    }

    setClearTimeout(){
        clearTimeout(this.clearTimeout);
        this.clearTimeout = setTimeout(() => this.clearText({keepProductSelected: true}), 3000);
    }

    clearText({keepProductSelected=false}={}){
        this.setState({searchText: ''});
        if(!keepProductSelected)
            this.props.onProductChange(null);
    }

    _renderSearchModal(){
        if(!this.state.showSearchModal)
            return;
        return  <ProductSearchModal onProductSelected={(kid) => {
                                         this.handleChange({target: {value: `K${kid}`}});
                                         this.setState({showSearchModal: false})
                                    }}
                                    query={this.state.searchText}
                                    onCancel={() => this.setState({showSearchModal: false})}
                                    productsClient={client}/>;
    }

    _handleKeyPress(event){
        if(event.key === 'Enter'){
            this.setState({showSearchModal: true});
            event.preventDefault();
        }
    }

    render() {
        return <div>
                {this._renderSearchModal()}
                <Input placeholder="K123, MPN, FNSKU, etc" bsSize="lg" className={`${this.props.className}`}
                       id={this.props.id}
                       value={this.state.searchText}
                       onKeyPress={e => this._handleKeyPress(e)}
                       onChange={e => this.handleChange(e)}/>
        </div>

    }
}

export default ProductSearch;
