import { ProductMarketplaceFinance } from './finance/ProductMarketplaceFinance';
import { ProductMarketplaceInventory } from './inventory/ProductMarketplaceInventory';
import { ProductMarketplaceFbaStorageHealth } from './inventory/ProductMarketplaceFbaStorageHealth';
import { ProductMarketplaceIdentifiers } from './ProductMarketplaceIdentifiers';
import { ProductMarketplaceStockLevels } from './inventory/ProductMarketplaceStockLevels';
import { PerDaysProductSalesTargets } from '../../target/PerDaysProductSalesTargets';
import { ProductMarketplaceTargets } from './ProductMarketplaceTargets';
import { ProductSalesPerDayRange } from '../ProductSalesPerDayRange';
import { Sanitizable } from '@kjdelectronics/kjd-auth/obj';
let propertyPermissionsArrayBulder = require('@kjdelectronics/kjd-auth/obj').PropertyPermission.propertyPermissionsArrayBulder;

let propertyPermissions = [];
propertyPermissions = propertyPermissions.concat(propertyPermissionsArrayBulder(
    {   arrayOfProperties: ['marketplaceId', 'name', 'productIds', 'inventory', 'stockLevels'],
        allowedByGroup: '*'})
);
propertyPermissions = propertyPermissions.concat(propertyPermissionsArrayBulder(
    {   arrayOfProperties: ['finance', 'perDaySalesTargets', 'targets', 'sales', 'storageHealth'],
        allowedByGroup: 'IM_FINANCES'})
);
const PROPERTY_PERMISSIONS = propertyPermissions;

class ProductMarketplace extends Sanitizable{
    constructor({marketplaceId, name, productIds, finance, inventory, storageHealth, stockLevels, perDaySalesTargets, targets, sales,}, productFinance){
        super({propertyPermissions: PROPERTY_PERMISSIONS});
        this.setObjectRefForSanitization(this);

        //Set by source channel synchronizationS
        this.marketplaceId = marketplaceId;
        this.name = name; //Name of the product in the marketplace
        this.productIds = new ProductMarketplaceIdentifiers(productIds);
        this.finance = finance ? new ProductMarketplaceFinance(finance, productFinance) : null;
        this.inventory = inventory ? new ProductMarketplaceInventory(inventory) : null;
        this.stockLevels = stockLevels ? new ProductMarketplaceStockLevels(stockLevels) : null;
        this.storageHealth = storageHealth ? new ProductMarketplaceFbaStorageHealth(storageHealth) : null;
        this.sales = sales || null;

        //Set and maintained manually by user
        this.perDaySalesTargets = perDaySalesTargets ? new PerDaysProductSalesTargets(perDaySalesTargets) : null;
        this.targets = targets ? new ProductMarketplaceTargets(targets) : null;
    }

    getForDb(){
        let res = Object.assign({}, this);
        Sanitizable.removeSanitizablePrivateFieldsFromObject(res);
        if(this.finance)
            res.finance = this.finance.getForDb();
        for (const [key, value] of Object.entries(res)) {
            if(value === null)
                delete res[key]; //Remove nulls from get for db
        }
        return res;
    }

    get isOutOfStock(){
        if(!this.inventory) return false; //There has never been inventory
        return this.inventory.isOutOfStock;
    }
}

export { ProductMarketplace };