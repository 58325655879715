import { ProductIdentifiers } from './ProductIdentifiers';
import { ProductMarketplace } from './marketplace/ProductMarketplace';
import { ShopifyMarketplace, SHOPIFY_MARKETPLACE_ID } from './marketplace/ShopifyMarketplace';
import { ProductDetail } from './detail/ProductDetail';
import { ProductFinance } from './finance/ProductFinance';
import { ProductTargets } from '../target/ProductTargets';
import { ProductCounters } from '@kjdelectronics/im-transactions/obj/ProductCounters';
import { ProductSalesPerDayRange } from './ProductSalesPerDayRange';
import { ProductBundleMap } from './ProductBundleMap';
import { ProductInventoryProcessing } from './ProductInventoryProcessing';
import { Sanitizable } from '@kjdelectronics/kjd-auth/obj';
import { PropertyPermission } from '@kjdelectronics/kjd-auth/obj';

const propertyPermissionsArrayBuilder = PropertyPermission.propertyPermissionsArrayBulder;

let propertyPermissions = [];
propertyPermissions = propertyPermissions.concat(propertyPermissionsArrayBuilder(
    {   arrayOfProperties: ['id', 'key', 'marketplaces', 'detail', 'counters', 'bundleComposition', 'created', 'updated', 'inventoryProcessing'],
        allowedByGroup: '*'})
);
propertyPermissions = propertyPermissions.concat(propertyPermissionsArrayBuilder(
    {   arrayOfProperties: ['finance', 'targets', 'sales'],
        allowedByGroup: 'IM_FINANCES'})
);
const PROPERTY_PERMISSIONS = propertyPermissions;

class Product extends Sanitizable{
    constructor({_id, id, key, marketplaces={}, detail, finance, targets, counters, sales, bundleComposition, inventoryProcessing,
                    created=new Date(), updated=null }={}, {legacyImport=false}={}){
        super({propertyPermissions: PROPERTY_PERMISSIONS});
        this.setObjectRefForSanitization(this);

        try {
            if(_id) this.id = _id.toString();
            else if(id) this.id = id;
            if (!this.id) this.id = null;

            this.key = new ProductIdentifiers(key);

            this.detail = new ProductDetail(detail);
            this.targets = targets ? new ProductTargets(targets) : {};
            if(this.key.id){
                if(counters == null){ //TODO Cleanup once legacyImport removed
                    counters = new ProductCounters({productId: this.key.id}, {suppressNoCountersWarningLog: legacyImport});
                    if(!legacyImport)
                        console.log(`Product Object Constructor: No counters (null) for productId K${this.key.id}. Using empty counters`);
                }
                this.counters = new ProductCounters(counters, {suppressNoCountersWarningLog: legacyImport});
            }
            else this.counters = null; //A new product will not have counters


            this.finance = new ProductFinance(finance);

            this.sales = {};
            for (let salesPastDateRangeInt in sales) {
                if (!sales.hasOwnProperty(salesPastDateRangeInt)) continue;
                if (!Number.isInteger(salesPastDateRangeInt)) {
                    if (Number.isInteger(parseInt(salesPastDateRangeInt)))
                        salesPastDateRangeInt = parseInt(salesPastDateRangeInt);
                    else
                        throw `For productId ${this.id} a key in sales is not a valid int`;
                }
                this.sales[salesPastDateRangeInt] = new ProductSalesPerDayRange(sales[salesPastDateRangeInt]);
            }

            this.marketplaces = {};
            for (let marketplaceId in marketplaces) {
                if (!marketplaces.hasOwnProperty(marketplaceId)) continue;

                this.marketplaces[marketplaceId] = _productMarketplaceBuilder({productFinance: this.finance, marketplaceId, marketplaces});
            }

            //Migrate existing data from empty object to empty arra
            if(!Array.isArray(bundleComposition))
                bundleComposition = [];

            this.bundleComposition = bundleComposition ? bundleComposition.map(i => new ProductBundleMap(i))  : [];
            this.created = new Date(created);

            this.updated = {
                date: updated && updated.date ? new Date(updated.date) : new Date(),
                userId: updated ? updated.userId : null
            }

            this.inventoryProcessing =  new ProductInventoryProcessing(inventoryProcessing, this);
        }
        catch (e) {
            let message = `An error has occurred while trying to instantiate product with Id ${this.id || this.key.id}. Message: ${e.message || e}`;
            console.log(message);
            if(e.stack) console.log(e.stack);
            throw e;
        }
    }

    setId(id){
        if(typeof id !== "number" || isNaN(id) || id <= 0)
            throw new Error(`Provided id is invalid for a product. Id ${id}`);

        let currentKey = this.key;
        currentKey.id = id;
        this.key = new ProductIdentifiers(currentKey);
    }

    setUpdated({userId}){
        if(!userId || typeof userId !== "string")
            throw new Error("userId must be provided and must be a string");
        this.updated = {
            date: new Date(),
            userId: userId
        }
    }

    getForDb(){
        let clone = new Product(this); //Clone
        let {["id"]:omit, ...res} = clone;
        Sanitizable.removeSanitizablePrivateFieldsFromObject(res);
        //Call get for db on child objects
        if(res.counters instanceof ProductCounters)
            res.counters = res.counters.getForDb();
        for (let id in this.marketplaces) {
            if (!this.marketplaces.hasOwnProperty(id)) continue;
            res.marketplaces[id] = this.marketplaces[id].getForDb();
        }
        res.finance = res.finance.getForDb();
        res.detail = res.detail.getForDb();
        res.inventoryProcessing = this.inventoryProcessing.getForDb();
        return res;
    }

    getUserEditableFieldsForDb(){
        let base = this.getForDb(); //Start with generic db object then remove fields that are system fields
        base.finance.purchasing = this.finance.purchasing.getUserEditableFieldsForDb();
        _deleteReadOnlyFields(base);
        return base;
    }

    getSanitizedUserEditableFieldsForUser(user){
        let sanitized = this.getSanitizedBasicJsObjectFromUser(user);
        if(sanitized.finance && sanitized.finance.purchasing)
            sanitized.finance.purchasing = this.finance.purchasing.getUserEditableFieldsForDb();
        _deleteReadOnlyFields(sanitized);
        return sanitized;
    }
}

function _productMarketplaceBuilder({productFinance, marketplaces, marketplaceId}) {
    if(marketplaceId === SHOPIFY_MARKETPLACE_ID)
        return new ShopifyMarketplace(marketplaces[marketplaceId], productFinance);
    return new ProductMarketplace(marketplaces[marketplaceId], productFinance);
}

function _deleteReadOnlyFields(base){
    delete base.counters;
    delete base.sales;
    delete base.marketplaces;
    delete base.created;
    delete base.detail.manualCounts; //Updated via separate post
    return base;
}

export { Product };