import React, {Component} from 'react';
import { Badge, Row, Col, Card, CardHeader,  CardBody, ListGroup, Table, ListGroupItem, Labels } from 'reactstrap';
import nl2br from 'react-nl2br';
import LabelButtons from "./LabelButtons";
import SanitizeHtml from "../atoms/SanitizeHtml";
import Links from "./Links";


class Detail extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if(!this.props.product)
            return <div></div>;

        const {...props} = this.props;

        return <Row >
            <Col sm="6">
                <Row>
                    <Col><h4>{this.props.product.detail.shortName}</h4></Col>
                </Row>
                <Row>
                    <Col><h5>{this.props.product.detail.name}</h5></Col>
                </Row>
                <Row>
                    <Col xs="6" style={{borderStyle: "solid", borderWidth: "thin"}}>
                        <h4>Description</h4>
                        <SanitizeHtml html={this.props.product.detail.descriptions.internalDescription}/>
                    </Col>
                    <Col xs="6" style={{borderStyle: "solid", borderWidth: "thin"}}>
                        <h4>Packing Instructions</h4>
                        <SanitizeHtml html={this.props.product.detail.descriptions.packingInstructions}/>
                    </Col>
                </Row>
                <LabelButtons {...props}/>
                <Links {...props}/>
            </Col>
            <Col sm={6}>
                <Row className="justify-content-center">
                    <img src={this.props.product.detail.images.primaryImageUrl} alt="productImage" style={{"max-height": "250px"}}/>
                </Row>
            </Col>
        </Row>

    }
}

export default Detail;
