class ProductMarketplaceFbaInventory {
    static build({updatedDate, fufillable, type, unsellable, inbound={}, reserved={}, _sourceData=null, futureSupply}){
        updatedDate = updatedDate != null ?  new Date(updatedDate) : new Date();
        fufillable = fufillable != null ? fufillable : 0;
        unsellable = unsellable != null ? unsellable : 0;

        let detail = {
            inbound: {
                working: inbound.working != null ? inbound.working : 0,
                shipped: inbound.shipped != null ? inbound.shipped : 0,
                receiving: inbound.receiving != null ? inbound.receiving : 0,
            },
            reserved:{
                customers: reserved.customers != null ? reserved.customers : 0,
                transfers: reserved.transfers != null ? reserved.transfers : 0,
                processing: reserved.processing != null ? reserved.processing : 0
            }
        };

        if(!futureSupply){
            futureSupply = {
                buyable: 0,
                reserved: 0,
            }
        }

        detail.total = {
            inboundAndAvailable: fufillable + reserved.transfers + inbound.shipped + inbound.receiving
        };

        let ProductMarketplaceInventory = require('./ProductMarketplaceInventory').ProductMarketplaceInventory;
        return new ProductMarketplaceInventory({
            updatedDate: updatedDate,
            inbound: detail.inbound.working + detail.inbound.shipped + detail.inbound.receiving,
            fufillable: fufillable,
            reserved: detail.reserved.customers + detail.reserved.transfers + detail.reserved.processing,
            unsellable: unsellable,
            detail: detail,
            type: type,
            _sourceData: _sourceData,
            futureSupply: futureSupply
        });
    }


}

export { ProductMarketplaceFbaInventory };