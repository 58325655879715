import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Tooltip ,} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment } from '@fortawesome/free-solid-svg-icons'

class CommentsCollapse extends Component {
    state = {isOpen: false};

    render() {
        const transaction = this.props.transaction;
        if(transaction.comments.length === 0)
            return "";

        const {isOpen} = this.state;
        return <div className="d-inline">
            <span style={{textDecoration: "underline",}} href="#" id={`c-tt-${transaction.ref}`}><FontAwesomeIcon icon={faComment} /></span>
            <Tooltip  placement="right" isOpen={isOpen} target={`c-tt-${transaction.ref}`} toggle={() => this.setState({isOpen: !isOpen})}>
                <ul className="text-left">
                    {transaction.comments.map(c => <li className="my-2" key={c.id} id={c.id}>{c.comment}</li>)}
                </ul>
            </Tooltip>
        </div>
    }
}

CommentsCollapse.propTypes = {
    transaction: PropTypes.object,
};

export {CommentsCollapse};
