//Name is free to change. Logic is bound on code
const APPROVAL_STATES = [
    {
        name: "Approved",
        code: "APPROVED"
    },
    {
        name: "Declined",
        code: "DECLINED"
    },
    {
        name: "Pending",
        code: "PENDING"
    },
    {
        name: "Voided",
        code: "VOIDED"
    },
    {
        name: "N/A",
        code: "N/A"
    }
];

export { APPROVAL_STATES };