import React, {Component} from 'react';
import {Input, FormGroup, Button} from 'reactstrap';
import PropTypes from "prop-types";
import {LoadingSpinner} from "./LoadingSpinner";
import ImClient from "../service/ImClientSingleton"

class SupplierOutOfStockCheckbox extends Component {
    state = {
        toggle: false,
        saveInProgress: false
    };

    onInput(e){
        return this.saveValue(!this.props.product.detail.indicators.supplierOutOfStock);
    }

    async saveValue(value){
        const {onSaved, product} = this.props;
        await this.setState({saveInProgress: true, toggle: !this.state.toggle});

        const patchRequest = {};
        patchRequest[`detail.indicators.supplierOutOfStock`] = value;

        const updatedProduct = await ImClient.patchProduct({productId: product.key.id, patchRequest});

        if(onSaved && typeof onSaved === "function")
            onSaved(updatedProduct);
        return this.setState({saveInProgress: false});
    }

    render() {
        const {product} = this.props;
        const {saveInProgress, toggle} = this.state;
        let value = product.detail.indicators.supplierOutOfStock;
        if(product.key.id === 42)
            console.log(product);
        if(toggle)
            value = !value;

        return <FormGroup inline className="form-group-sm text-center">
            <Input type="checkbox" onClick={e => this.onInput(e)} disabled={saveInProgress} checked={!!value} />}
            {saveInProgress && <LoadingSpinner/>}
        </FormGroup>
    }
}

function validInt(value){
    return !(!Number.isInteger(value) || isNaN(value) || value < 0);
}

SupplierOutOfStockCheckbox.propTypes = {
    product: PropTypes.object.isRequired,
    onSaved: PropTypes.func
};

export {SupplierOutOfStockCheckbox};
