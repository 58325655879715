
//Custom minimal class of Warehouse Product Location for storing in Products
class ProductDetailLocation{
    constructor({locationType, locationCode, binCode, name, audit, updated, binLocation}){
        if(locationCode)
            this.locationCode = locationCode;
        if(binCode)
            this.binCode = binCode;

        this.locationType = locationType;
        this.name = name;
        if(audit)
            this.updated = audit && audit.updated ? new Date(audit.updated.date) : null;
        else
            this.updated = updated ? new Date(updated) : null;

        //Need to be able constructing from object binLocation or reconstruct from string
        if(binLocation) {
            if(typeof binLocation === "string")
                this.binLocation = binLocation;
            else
                this.binLocation = binLocation.code;
        }
    }

    toLocationString(){
        if(this.binLocation && this.binCode)
            return `${this.binLocation}/${this.binCode}`;
        else if(this.binCode)
            return `NOLOC/${this.binCode}`;
        else if(this.locationCode)
            return this.locationCode;
        else
            return "NOLOC";
    }
}

export { ProductDetailLocation };