import React, {Component} from 'react';
import { FormGroup, Label, Input, Card, CardBody, CardHeader, ListGroup, ListGroupItem, Badge, Row, Col } from 'reactstrap';
import ProductSearch from "../organisms/ProductSearch";
import Product from "../product/Product";
import {withRouter} from 'react-router-dom'
import Links from "../Links";
import transactionsClient from "../../client/transactionsClient";
import {TransactionsTable} from "../molecules/tables/TransactionsTable";
import PackingBatch from "../PackingBatch";
import ProductManagement from "../organisms/ProductManagement";

class ProductManagementPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: null,
            transactions: []
        };
        this.productSearchRef = React.createRef();
    }

    getInitialProductId(){
        if(this.props.match)
            return this.props.match.params.productId;
        return null;
    }

    onProductChange(product){
        if(!product)
            return;
        this.setState({product: product});
        this._getTransactionsForProduct(product);
        if(this.props.history.location.pathname.includes('/packing/'))
            this._delayedUrlChange(product.key.id);
    }
    
    _getTransactionsForProduct(product){
        if(!product)
            return;
        transactionsClient.transactions.search({productId: this.state.product.key.id})
            .then(transactions => {
                if(product.key.id === this.state.product.key.id)
                    this.setState({transactions});
            })
    }

    _delayedUrlChange(productId){
        const {path} = this.props;
        setTimeout(() => {
            if(this.state.product.key.id === productId)
                this.props.history.push(`/${path ? path : "packing"}/${productId}`);
        }, 750);
    }

    handleEnter(){
        this.productSearchRef.current.clearText();
    }

    render() {
        const {path} = this.props;
        const {product} = this.state;
        if(!this.props.location.pathname.includes('/management/'))
            this.props.history.replace(`/${path ? path : "management"}/0`); //Redirect URL

        return (
            <div className="App-Width">
                <h1>FBA Packing</h1>
                <Row className="align-items-center">
                    <Col className="col-lg-9 col-md-8 col-sm-7">
                        <ProductSearch id={this.props.searchId} className="top-margin" ref={this.productSearchRef}
                                   onEnter={e => this.handleEnter()}
                                   initialProductId={this.getInitialProductId()}
                                   onProductChange={product => this.onProductChange(product)}/>
                    </Col>
                    <Col><Links/></Col>
                </Row>
                <Product product={this.state.product}>
                   <ProductManagement product={this.state.product}></ProductManagement>
                </Product>
                {this.state.transactions.length > 0 && <h2>Recent Transactions</h2>}
                <TransactionsTable transactions={this.state.transactions} sizePerPage={10}/>
            </div>
        );
    }
}

export default withRouter(ProductManagementPage);
