import React, {Component} from 'react';
import { FormGroup, Label, Input, Card, CardBody, CardHeader, ListGroup, Alert, Table, Row, Col, Button } from 'reactstrap';
import ImClient from "../service/ImClientSingleton"
import ApprovableListItem from "../discrepancy/ApprovableListItem";
import ApprovablesTable from "../discrepancy/ApprovablesTable";

function _filterReserveApprovals(approvables) {
    return approvables.filter(a => a.transaction.type.name === "STORE-RESV-ADD-FRM-INVENTORY-INBOUND");
}

function _filterReservePendingApprovals(approvables) {
    return approvables.filter(a => a.status.code === "PENDING" && a.transaction.type.name === "STORE-RESV-ADD-FRM-INVENTORY-INBOUND");
}

class MangeInboundReserveProcessing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            recentApprovables: [],
            pendingApprovables: []
        }
    }

    componentDidMount() {
        this.refreshSate();
    }

    refreshSate(){
        ImClient.getApprovables()
            .then(approvables => {
                console.log(approvables);
                this.setState({recentApprovables: _filterReserveApprovals(approvables), pendingApprovables: _filterReservePendingApprovals(approvables)})
            });
    }

    renderPendingApprovals(){
        if(this.state.pendingApprovables.length === 0)
            return "";

        return (
            <div>
                <h2>Pending Inbound Reserve Processing</h2>
                <ApprovablesTable approvables={this.state.pendingApprovables}/>
            </div>
        )
    }

    render() {
        return (
            <div className="App-Width">
                <h1>Inbound Reserve Processing</h1>
                {this.renderPendingApprovals()}
                <h2>Recent Inbound Reserve Transactions</h2>
                <ApprovablesTable approvables={this.state.recentApprovables}/>
            </div>
        );
    }
}

export default MangeInboundReserveProcessing;
