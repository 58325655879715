import { ProductMarketplace } from './ProductMarketplace';
const SHOPIFY_MARKETPLACE_ID = "SHOPIFY";

class ShopifyMarketplace extends ProductMarketplace{
    constructor(...params){
        super(...params);
        const allArguments = params[0];

        //Persist the Shopify source data to make all additional attributes available
        this.sourceData = allArguments.sourceData;
    }

    get links(){
        return {
            admin: _getAdminLink(this)
        };
    }

    getForDb(){
        return ProductMarketplace.prototype.getForDb.call(this);
    }
}

function _getAdminLink(marketplace) {
    return `https://kjdelectronics.myshopify.com/admin/products/${marketplace.productIds.productId}`
}

export { ShopifyMarketplace, SHOPIFY_MARKETPLACE_ID };
