export default {
    transactions: {
        get: 'transactions',
        getSingle: 'transactions/:idOrRef',
        post: {
            create: 'transactions',
            validate: 'transactions/validation',
            search: 'transactions/search',
        },
        put: {
            update: 'transactions/:idOrRef'
        }
    },
    transactionBatches: {
        post: {
            create: 'transactions/batches',
            validate: 'transactions/batches/validation',
        },
        put:{
            voidBatch: 'batches/:batchId/void'
        }
    },
    counters: {
        get: {
            list: 'counters',
            forProduct: 'counters/products/:productId'
        }
    },
    types: {
        get: 'types'
    },
    productCounters: {
        get: {
            list: 'counters/products',
            listAbs: 'counters/abs/products',
            getForProduct: 'counter/products/:productId'
        }
    },
    outboundShipments:{
        get:{
            getForProduct: 'outbound-shipments/:productId'
        }
    },
    payments: {
        me: 'payments/me',
        get: "payments",
        unpaid: "payments/unpaid"
    }
};