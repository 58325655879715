import {Button, Card, Col, Input, Row} from "reactstrap";

import React, {Component} from 'react';
import PropTypes from "prop-types";
import transactionsClient from "../../client/transactionsClient"
import {Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Label,} from "reactstrap";
import {LoadingSpinner} from "../atoms/LoadingSpinner";

class ProductManagementCard extends Component {
    state = {
        comment: ""
    };

    async addComment(){
        transactionsClient.transactions.addComment({idOrRef: this.props.transaction.ref, comment: this.state.comment})
            .then(r => console.log("Comment added"))
            .catch(e => console.log(e))
            .finally(r => this.props.toggle())
    }

    render() {
        const {transaction, toggle, onVoid, className} = this.props;

        return <Card body style={{ backgroundColor: '#b5cfff', borderColor: '#333' }}>
            <h3>Options</h3>
            <Row className="d-flex justify-content-center">
                <Col className="justify-content-center">
                    <FormGroup check className="d-inline-block font-medium">
                        <Label check><strong>Archived</strong>
                        <Input type="checkbox"  className="ml-2 checkbox-medium" onChange={(e) => this.setState({showArchived: e.target.checked})}/>
                        </Label>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center mt-0">
                <Col className="justify-content-center">
                    <FormGroup check className="d-inline-block font-medium">
                        <Label check><strong>MFG OOS</strong>
                        <Input type="checkbox"  className="ml-2 checkbox-medium" onChange={(e) => this.setState({showArchived: e.target.checked})}/>
                        </Label>
                    </FormGroup>
                </Col>
            </Row>

            <Row className="d-flex justify-content-center mt-2 mb-0 border-top">
                <Col className="d-flex justify-content-center">
                    <Label className="ml-3 pr-4"><strong>Reserve Target</strong></Label>
                    <Input className="middle font-size-large-mgmt" type="text" id="reserveTransfer"
                           value={53}
                           onChange={e => this.manualCountChange(e)} />
                </Col>
            </Row>
            <Row className="d-flex justify-content-center mt-1">
                <Col className="d-flex justify-content-center">
                    <Button  color="primary" onClick={e => this.decrementCount()} size="md">SET TARGET</Button>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center mt-2">
                <Col className="d-flex justify-content-center">
                    <Button  className="mr-2" color="primary" onClick={e => this.decrementCount()} size="md">0</Button>
                    <Button  className="mr-2" color="primary" onClick={e => this.decrementCount()} size="md">5</Button>
                    <Button  className="mr-2" color="primary" onClick={e => this.decrementCount()} size="md">10</Button>
                    <Button  className="mr-2" color="primary" onClick={e => this.decrementCount()} size="md">25</Button>
                </Col>
            </Row>
        </Card>
    }
}

ProductManagementCard.propTypes = {
    product: PropTypes.object.isRequired,
    counterName: PropTypes.string
};

export {ProductManagementCard};