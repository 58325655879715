import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/darkly.bootstrap.min.css';
import './style/darkly.changes.css';
import './style/compact.css'
import '../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-notifications/lib/notifications.css';
import './style/App.css';

ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker(); Do not use service worker
