import { ProductMarketplaceFbaInventory } from './ProductMarketplaceFbaInventory';

//TODO Refactor these objects to use inheritance and constructors. Should not be using static builders where avoidable
class ProductMarketplaceInventory{
    constructor({updatedDate, inbound=0, fufillable=0, reserved=0, unsellable=0, type, futureSupply, detail=null, _sourceData}){
        //This should never be called directly. Always through buildInventoryObject
        this.updatedDate = updatedDate;
        this.inbound = inbound;
        this.fufillable = fufillable;
        this.reserved = reserved;
        this.unsellable = unsellable;
        this.type = type;
        this.futureSupply = futureSupply;
        this.detail = detail;
        this._sourceData = _sourceData;
    }

    static buildInventoryObject(data){
        if(data.type === 'fba') {
            return ProductMarketplaceFbaInventory.build({
                updatedDate: data.updatedDate,
                inbound: {
                    working: data.inboundWorking,
                    shipped: data.inboundShipped,
                    receiving: data.inboundReceiving
                },
                fufillable: data.fufillable,
                unsellable: data.unsellable,
                reserved: {
                    customers: data.reservedCustomers,
                    transfers: data.reservedTransfers,
                    processing: data.reservedProcessing
                }
            });
        }
        else if(data.type === "mws3"){ //TODO Tests
            const invDt = data.inventoryDetails;
            const params = {
                updatedDate: data.lastUpdatedTime,
                inbound: {
                    working: invDt.inboundWorkingQuantity,
                    shipped: invDt.inboundShippedQuantity,
                    receiving: invDt.inboundReceivingQuantity
                },
                fufillable: invDt.fulfillableQuantity,
                type: "mws3",
                unsellable: invDt.unfulfillableQuantity ? invDt.unfulfillableQuantity.totalUnfulfillableQuantity : 0,
                reserved: {
                    customers: invDt.reservedQuantity ? invDt.reservedQuantity.pendingCustomerOrderQuantity : 0,
                    transfers: invDt.reservedQuantity ? invDt.reservedQuantity.pendingTransshipmentQuantity : 0,
                    processing: invDt.reservedQuantity ? invDt.reservedQuantity.fcProcessingQuantity : 0,
                },
                futureSupply: {
                    buyable: invDt.futureSupplyQuantity? invDt.futureSupplyQuantity.futureSupplyBuyableQuantity : 0,
                    reserved: invDt.futureSupplyQuantity? invDt.futureSupplyQuantity.reservedFutureSupplyQuantity : 0,
                },
                _sourceData: data
            };
            return ProductMarketplaceFbaInventory.build(params);
        }
        else throw new Error(`Unknown inventory type ${data.type}`);
    }

    static buildInventoryObjectFromMws3(data){

    }

    get isOutOfStock(){
        //Todo make this generic and work with the refactor TODO above
        if(this.detail && this.detail.total && this.detail.total.inboundAndAvailable)
            return this.detail.total.inboundAndAvailable === 0;
        return this.fufillable === 0
    }
}

export { ProductMarketplaceInventory };