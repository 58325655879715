 import React, {Component} from 'react';
import { Badge, Row, Col, Card, CardHeader,  CardBody, ListGroup, Table, ListGroupItem } from 'reactstrap';
import Detail from "./Detail";
import Counters from "./Counters";
import PackingBatch from "../PackingBatch";


class KeyAttributeHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if(!this.props.product)
            return <div></div>;

        return (
            <Row className="p-2" >
                <Col lg={4}>{}</Col>
                <Col lg={4} className="d-flex justify-content-center">
                    <h1> <Badge color="primary" className="" style={{fontSize: 30}}>{this.props.product.key.kid}</Badge></h1>
                </Col>
                <Col lg={4} className="d-flex justify-content-end">
                    <div>
                        {_getBadge({value: this.props.product.key.upcEanGtin})}
                        {_getBadge({value: this.props.product.key.modelNumber})}
                        {_getBadge({value: this.props.product.key.keywords})}
                        {_getBadge({value: this.props.product.detail.indicators.archived, color: "danger"})}
                    </div>
                </Col>
            </Row>);

    }
}

function _getBadge({value, color="secondary"}){
    if(!value)
        return;
    //&nbsp; prevents double click from selecting on adjacent divs
    return <div className="d-inline">
            <Badge color={color} className="mr-1 mt-3" style={{fontSize: 20}}>{value}</Badge>
            &nbsp;
    </div>
}

export default KeyAttributeHeader;
