import { validateArrayOfType } from '../../../helper/arrayOps';
const NO_IMAGE_URL = require('../../../config/options/productDefaults').defaults.imageNotAvailableUrl;
class ProductDetailImages{
    constructor({primaryImageUrl, secondaryImageUrls=[]}={}){
        if(primaryImageUrl && typeof primaryImageUrl !== 'string')
            throw `primaryImageUrl must be of type string`;
        if(primaryImageUrl == null) this.primaryImageUrl = NO_IMAGE_URL;
        else this.primaryImageUrl = primaryImageUrl;

        if(secondaryImageUrls == null) secondaryImageUrls = [];
        validateArrayOfType({array: secondaryImageUrls, typeName: 'string', propName: 'secondaryImageUrls',});
        this.secondaryImageUrls = secondaryImageUrls;
    }

}

export { ProductDetailImages };