import { ProductMarketplaceFinanceFeeItem } from './ProductMarketplaceFinanceFeeItem';
import { round } from 'mathjs';
class ProductMarketplaceFinanceFees{
    constructor({validDate, marketPriceUsedForEstimate, items,}){
        this.validDate = new Date(validDate);
        this.marketPriceUsedForEstimate = marketPriceUsedForEstimate;

        this.marketTotal = 0;
        this.baseTotal = 0;

        if(!Array.isArray(items))
            throw `Items must be an array`;

        this.items = [];
        items.forEach(i => {
            let item = new ProductMarketplaceFinanceFeeItem(i);
            this.items.push(item);
            this.marketTotal += item.market;
            this.baseTotal += item.base;
        });

        this.marketTotal = round(this.marketTotal, 2);
        this.baseTotal = round(this.baseTotal, 2);
    }

}

export { ProductMarketplaceFinanceFees };