import React, {Component} from 'react';
import {Input, FormGroup, Button} from 'reactstrap';
import PropTypes from "prop-types";
import {LoadingSpinner} from "./LoadingSpinner";
import ImClient from "../service/ImClientSingleton"
import transactionsClient from "../../client/transactionsClient"

class ManualPackRequestSetter extends Component {
    state = {
        invalid: null,
        value: null,
        saveInProgress: false
    };

    onInput(e){
        this.setState({value: e.target.value});
        console.log("Input: ", e.target.value);
    }

    onBlur(e){
        let newValue = Number.parseInt(e.target.value);
        if(!validInt(newValue)){
            this.setState({invalid: true});
            return;
        }
        this.setState({invalid: false, value: newValue});
        return this.saveValue(newValue);
    }

    async saveValue(value){
        const {onSaved, product, region} = this.props;
        await this.setState({saveInProgress: true});
        const currentCounters = await transactionsClient.productCounters.getCountersForProductId(product.key.id);

        const diff = value - currentCounters.getCounter(`${region}-packRequest`);
        console.log(diff);
        if(diff > 0){
            await transactionsClient.transactions.createTransactionFromParams({
                productId: product.key.id,
                itemCount: Math.abs(diff),
                typeName: `PACK-REQUEST-${region.toUpperCase()}`
            });
        }
        else if(diff < 0){
            await transactionsClient.transactions.createTransactionFromParams({
                productId: product.key.id,
                itemCount: Math.abs(diff),
                typeName: `PACK-REQUEST-RM-${region.toUpperCase()}`
            });
        }

        const patchRequest = {};
        patchRequest[`inventoryProcessing.manualPackRequestBitByRegion.${region}`] = true;
        await ImClient.patchProduct({productId: product.key.id, patchRequest});

        if(onSaved && typeof onSaved === "function")
            onSaved(value);
        return this.setState({saveInProgress: false});
    }

    async clearManualPr(){
        const {onSaved, product, region} = this.props;
        await this.setState({saveInProgress: true});

        const patchRequest = {};
        patchRequest[`inventoryProcessing.manualPackRequestBitByRegion.${region}`] = false;
        await ImClient.patchProduct({productId: product.key.id, patchRequest});

        if(onSaved && typeof onSaved === "function")
            onSaved();
        return this.setState({saveInProgress: false});
    }

    render() {
        const {value, manualPackRequest} = this.props;
        const {saveInProgress} = this.state;
        if(this.state.value === null)
            this.setState({value});

        return <FormGroup inline className="form-group-sm">
            <Input type="text" invalid={this.state.invalid} placeholder="" onBlur={e => this.onBlur(e)} onInput={e => this.onInput(e)} disabled={saveInProgress} value={this.state.value} />
            {manualPackRequest && <div><Button color={"danger"} size="sm"  onClick={() => this.clearManualPr()}>Clear Manual PR</Button></div>}
            {saveInProgress && <LoadingSpinner/>}
        </FormGroup>
    }
}

function validInt(value){
    return !(!Number.isInteger(value) || isNaN(value) || value < 0);
}

ManualPackRequestSetter.propTypes = {
    product: PropTypes.object.isRequired,
    region: PropTypes.string.isRequired,
    manualPackRequest: PropTypes.bool,
    onSaved: PropTypes.func
};

export {ManualPackRequestSetter};
