import {rpWrapper as rp} from './request.axios.wrapper';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const sessionKey = cookies.get('kjd_session');

const DEV = {
    baseUrl: "http://localhost:3011/v1"
};

const PROD = {
    baseUrl: "https://mws3.kjdelectronics.com/v1"
};

const config = process.env.NODE_ENV.toLowerCase() === 'production' ? PROD : DEV;

class Mws3Client {
    constructor ({sessionKey}) {
        this.authHeader = {"Authorization": `Bearer ${sessionKey}`};
    }

    getRecentMfnOrders({marketplaceId}){
        const date = new Date();
        date.setHours(date.getHours() - 24 * 7);
        let options = {
            method: 'GET',
            uri: config.baseUrl + `/orders?marketplaceId=${marketplaceId}&fulfillmentChannels=MFN&createdAfter=${date.toISOString()}`,
            headers: this.authHeader,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }

    getAmazonOrder({orderId}){

        let options = {
            method: 'GET',
            uri: config.baseUrl + `/orders/${orderId}`,
            headers: this.authHeader,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }

    getShipmentRateQuotes({amazonOrderId, itemList, weightInOz, lengthIn, widthIn, heightIn}){
        let options = {
            method: 'POST',
            uri: config.baseUrl + `/mfn/shipping/shipments/quotes`,
            headers: this.authHeader,
            body: {
                amazonOrderId,
                itemList,
                weightInOz,
                lengthIn,
                widthIn,
                heightIn
            },
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }

    decodeLabel({data}){
        let options = {
            method: 'POST',
            uri: config.baseUrl + `/mfn/shipping/labels`,
            headers: this.authHeader,
            body: {
                data
            },
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }

    createShipment({shippingServiceOfferId, shippingServiceId, amazonOrderId, itemList, weightInOz, lengthIn, widthIn, heightIn, }){
        let options = {
            method: 'POST',
            uri: config.baseUrl + `/mfn/shipping/shipments`,
            headers: this.authHeader,
            body: {
                shippingServiceOfferId,
                shippingServiceId,
                amazonOrderId,
                itemList,
                weightInOz,
                lengthIn,
                widthIn,
                heightIn
            },
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }

    cancelShipment({shipmentId}){
        let options = {
            method: 'DELETE',
            uri: config.baseUrl + `/mfn/shipping/shipments/${shipmentId}`,
            headers: this.authHeader,
            body: {
            },
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }

    getRecentShipments({marketplaceId}){
        let options = {
            method: 'GET',
            uri: config.baseUrl + `/fba/inbound/shipments?marketplaceId=${marketplaceId}`,
            headers: this.authHeader,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }

    getShipment({marketplaceId, shipmentId}){
        let options = {
            method: 'GET',
            uri: config.baseUrl + `/fba/inbound/shipments?marketplaceId=${marketplaceId}&shipmentId=${shipmentId}`,
            headers: this.authHeader,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }

    importShipment({marketplaceId, shipmentId}){
        let options = {
            method: 'POST',
            uri: config.baseUrl + `/fba/inbound/shipments`,
            body: {marketplaceId, shipmentId},
            headers: this.authHeader,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(result => {
                return result.shipment;
            })
    }

    voidShipmentImport({marketplaceId, shipmentId}){
        let options = {
            method: 'DELETE',
            uri: config.baseUrl + `/fba/inbound/shipments`,
            body: {marketplaceId, shipmentId},
            headers: this.authHeader,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(result => {
                return result.shipment;
            })
    }
}

const client = new Mws3Client({sessionKey: sessionKey});

export default client;