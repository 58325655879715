import React, {Component} from 'react';
import {Badge, Row, Table} from 'reactstrap';
import PropTypes from "prop-types";
import {faExternalLinkAlt, faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
import {TransactionVoidBadge} from "../atoms/TransactionVoidBadge";
import {TransactionVoidButton} from "../atoms/TransactionVoidButton";
import transactionsClient from "../../client/transactionsClient"

class TransactionSummary extends Component {
    render() {
        const {transaction} = this.props;
        const date = new moment(transaction.dateTime);


        return  <Table bordered className="compact" style={{width: "100%", "table-layout": "fixed"}}>
            <tbody>
            <tr>
                <th scope="row" className="text-right"  >Type</th>
                <td className="font-weight-bold cell-wrap" style={{width: "80%"}}>{transaction.type.name}</td>
            </tr>
            <tr>
                <th scope="row" className="text-right">Product</th>
                <td className="cell-wrap">{kidFormatter(transaction.product.id)} - {transaction.product.shortName}</td>
            </tr>
            <tr>
                <th scope="row" className="text-right">Count</th>
                <td className="cell-wrap">{transaction.itemCount}</td>
            </tr>
            <tr>
                <th scope="row" className="text-right">Date</th>
                <td className="cell-wrap">{date.format("MMMM Do YYYY H:mm:ss A")}</td>
            </tr>
            <tr>
                <th scope="row" className="text-right cell-wrap">User</th>
                <td className="cell-wrap">{transaction.user.displayName} ({transaction.user.username})</td>
            </tr>
            <tr>
                <th scope="row" className="text-right ">Void</th>
                <td>{transaction.voided ? <TransactionVoidBadge transaction={transaction}/> :<TransactionVoidButton transaction={transaction}/>}</td>
            </tr>
            </tbody>
        </Table>;
    }
}

function kidFormatter(id){
    return <a tabIndex={-1} href={`https://sales.kjdelectronics.com/products/${id}`}
              target="_blank">K{id}</a>
}

TransactionSummary.propTypes = {
    transaction: PropTypes.object.isRequired
};

export {TransactionSummary};
