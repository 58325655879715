import arrayOps from '../../../helper/arrayOps';

class ProductPurchasingRecommendation{
    //projection includes by region data (really by 'channel') and is the output from projection.controller (currently mws service)
    constructor({nextOrderDate, targetInStockDays, lookbackDays, projection, overallStockLevelPercent, recommendedUnits,
                    recommendedTotalCost}){

    }
}

export { ProductPurchasingRecommendation };