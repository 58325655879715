let axios = require('axios');

function rpWrapper(options){
    options.url = options.uri;
    delete options.uri;

    if(options.body){
        options.data = options.body;
        delete options.body;
    }
    delete options.json;

    return axios(options)
        .then(result => {
            return result.data;
        }).catch(err => {
            //console.log(options);
            console.log(`HTTP Error`, err);
            if(err.response)
                console.log(JSON.stringify(err.response));
            else if (err.request) {
                console.log(err.request);
            } else
                console.log('Error', err.message);
        })
}

export {rpWrapper};