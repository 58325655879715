class ProductDetailDescriptions{
    constructor({internalDescription, packingInstructions, publicDescription, details, specifications, links}={}){
        if(internalDescription && typeof internalDescription !== 'string')
            throw `internalDescription must be of type string`;
        if(packingInstructions && typeof packingInstructions !== 'string')
            throw `packingInstructions must be of type string`;
        if(publicDescription && typeof publicDescription !== 'string')
            throw `publicDescription must be of type string`;
        if(details && typeof details !== 'string')
            throw `details must be of type string`;
        if(specifications && typeof specifications !== 'string')
            throw `specifications must be of type string`;
        if(links && typeof links !== 'string')
            throw `links must be of type string`;

        this.internalDescription = internalDescription ? internalDescription : null;
        this.packingInstructions = packingInstructions ? packingInstructions : null;
        this.publicDescription = publicDescription ? publicDescription : null;
        this.details = details ? details : null;
        this.specifications = specifications ? specifications : null;
        this.links = links ? links : null;
    }

}

export { ProductDetailDescriptions };