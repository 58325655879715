import React, {Component} from 'react';
import { Nav, NavItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, NavLink } from 'reactstrap';

class Links extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false
        };
    }

    toggle() {
        this.setState({dropdownOpen: !this.state.dropdownOpen});
    }

    render() {
        return <div>
            <Nav>
                <Dropdown nav isOpen={this.state.dropdownOpen} toggle={(e) => this.toggle()}>
                    <DropdownToggle nav caret>
                        Ready to Ship
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem href="https://mws-service.kjdelectronics.com/v1/fba/inbound/shipready/us" target="_blank">US Ready to Ship</DropdownItem>
                        <DropdownItem href="https://mws-service.kjdelectronics.com/v1/fba/inbound/shipready/eu" target="_blank">EU Ready to Ship</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                {/*<NavItem>
                    <NavLink href="#">Transactions</NavLink>
                </NavItem>*/}
            </Nav>
        </div>
    }
}

export default Links;
