import { ProductMarketplaceFinanceFees } from './ProductMarketplaceFinanceFees';
import { ProductMarketplaceFinancePrice } from './ProductMarketplaceFinancePrice';
import { ProductMarketplaceFinanceProfit } from './ProductMarketplaceFinanceProfit';
import { ProductMarketplaceFinanceRevenue } from './ProductMarketplaceFinanceRevenue';
import { ProductMarketplaceFinanceCosts } from './ProductMarketplaceFinanceCosts';
import { baseToMarketRate as baseToMarketExchangeRates } from '../../../../config/options/exchangeRates';
import { round } from 'mathjs';

class ProductMarketplaceFinance{
    constructor({costs, fees, price, marketCurrencyCode, }, productFinance){
        this.baseToMarketExchangeRate = baseToMarketExchangeRates[marketCurrencyCode];
        if(!this.baseToMarketExchangeRate)
            throw new Error(`No exchange rate for market currency code ${marketCurrencyCode} in ProductMarketplaceFinance`);
        if(costs){
            costs.baseToMarketExchangeRate = this.baseToMarketExchangeRate; //Need to apply updated baseToMarketExchangeRate
            this.costs =  new ProductMarketplaceFinanceCosts(costs, {productFinance: productFinance});
        }else
            this.costs = null;
        this.fees = fees ? new ProductMarketplaceFinanceFees(fees) : null;
        this.price = new ProductMarketplaceFinancePrice(price, {baseToMarketExchangeRate: this.baseToMarketExchangeRate});
        this.marketCurrencyCode = marketCurrencyCode;

        this.calculateAndUpdateProfitAndRevenue();
    }

    calculateAndUpdateProfitAndRevenue(price=this.price){
        let results = calculateProfitAndRevenue({costs: this.costs, fees: this.fees, price: price});
        this.profit = results.profit;
        this.revenue = results.revenue;
        return results;
    }

    getForDb(){
        let res = Object.assign({}, this);
        res.costs = this.costs.getForDb();
        return res;
    }
}

function calculateProfitAndRevenue({costs, fees, price}) {
    if(fees == null){
        return {profit: null, revenue: null}
    }
    if(costs == null || price == null){
        console.log(`ProductMarketplaceFinance: Cannot calculateProfitAndRevenue because costs and/or price is null`);
        return {profit: null, revenue: null}
    }

    let baseRevenue = round(price.base - fees.baseTotal, 2);
    let marketRevenue = round(price.market - fees.marketTotal, 2);

    let marketProfit = round(marketRevenue - costs.marketTotal, 2);
    let baseProfit = round(baseRevenue - costs.baseTotal, 2);

    return {
        profit: new ProductMarketplaceFinanceProfit({
            market: marketProfit,
            base: baseProfit, roi: baseProfit / costs.baseTotal, ros: baseProfit / price.base
        }),
        revenue: new ProductMarketplaceFinanceRevenue({
            market: marketRevenue,
            base: baseRevenue,
        })
    }
}

export { ProductMarketplaceFinance };