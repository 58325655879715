import React, {Component} from 'react';
import { FormGroup, Label, Input, Card, CardBody, CardHeader, ListGroup, ListGroupItem, Badge, Row, Col } from 'reactstrap';
import ProductSearch from "../organisms/ProductSearch";
import mws3Client from "../../client/mws3.client";
import {withRouter} from 'react-router-dom'
import Links from "../Links";
import transactionsClient from "../../client/transactionsClient";
import {TransactionsTable} from "../molecules/tables/TransactionsTable";
import {AmazonOrdersTable} from "../molecules/tables/AmazonOrdersTable";
import {LoadingSpinner} from "../atoms/LoadingSpinner";


class MfnOrdersPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: null,
        };
    }

    async componentDidMount() {
        await this._getOrders();
    }

    async _getOrders(){
        let orders = await mws3Client.getRecentMfnOrders({marketplaceId: "ATVPDKIKX0DER"});
        orders = orders.filter(o => o.numberOfItemsUnshipped !== 0);
        this.setState({orders});
    }


    render() {
        return (
            <div className="App-Width">
                <h1>Amazon Order Shipping</h1>
                {this.state.orders === null && <LoadingSpinner/>}
                {this.state.orders !== null && <AmazonOrdersTable orders={this.state.orders}/>}
            </div>
        );
    }
}

export default withRouter(MfnOrdersPage);
