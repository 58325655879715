import React, {Component} from 'react';
import {Button, Modal, ModalHeader, ModalFooter, ModalBody, Card, Row, Col, Input, Alert} from 'reactstrap';
import PropTypes from "prop-types";
import ProductsTable from "../product/ProductsTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowLeft, faArrowRight, faCaretUp} from '@fortawesome/free-solid-svg-icons'
import imClient from "../service/ImClientSingleton";
import eventClient from "../service/EventsClientSingleton";

class ReserveTransferModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoading: true,
            product: null,
            transaction: null,
            count: 0
        };
        eventClient.subscribe((e) => this.handleProductCountersUpdate(e))
    }

    handleProductCountersUpdate(e){
        if(e.type === "counters.product"){
            if(this.state.product && e.data.productId === this.state.product.key.id){
                let product = Object.assign({}, this.state.product);
                product.counters = e.data;
                this.setState({product});
            }
        }
    }

    async componentDidMount() {
        await this._getProduct();
        return this._getProductCounters();
    }

    componentDidUpdate(prevProps){

    }

    async _getProduct(){
        const {productId} = this.props;

        this.setState({isLoading: true});
        return imClient.getProductById(productId)
            .then(product => {
                this.setState({
                    isLoading: false,
                    product
                });
            }).catch(error => {
                this.setState({
                    isLoading: false,
                    error: error
                });
            });
    }

    async _getProductCounters(){
        return imClient.getProductCounters(this.props.productId)
            .then(counters => {
                let product = Object.assign({}, this.state.product);
                product.counters = counters;
                this.setState({product});
                if(this.props.onCounterUpdate)
                    this.props.onCounterUpdate(counters);
            }).catch(error => {
                this.setState({
                    error: error
                });
            });
    }

    async transfer({typeName}){
        const args = {
            typeName: typeName,
            itemCount: this.state.count,
            productId: this.props.productId,
        };
        this.setState({
            isLoading: true
        });

        try {
            const transaction = await imClient.createTransaction(args);
            this.setState({
                isLoading: false,
                transaction
            });
            return this._getProductCounters(); //Force update if event fails
        }
        catch(error){
            return this.setState({
                isLoading: false,
                error
            });
        }
    }

    _renderBody(){
        const {product, isLoading, error, transaction} = this.state;
        if(!product)
            return <ModalBody/>;
       return <ModalBody>
           <Row>
               <Col>
                   <Card body>
                       <h3>Inventory</h3>
                       <h1 className="center-text">{product.counters.counters.inventory}</h1>
                   </Card>
               </Col>
               <Col>
                   <Card body>
                       <h3>Transfer</h3>
                       <Button disabled={isLoading} onClick={e => this.transfer({typeName: "INVENTORY-ADD-FRM-RESV"})}>
                           <FontAwesomeIcon icon={faArrowLeft} size="lg" className="profit-text mr-1" /> To Inventory
                       </Button>
                       <Input className="middle" type="number" placeholder="" style={{fontSize: "32px"}}
                              min={1}
                              value={this.state.count} onChange={e => this.setState({count: parseInt(e.target.value)})} />
                       <Button disabled={isLoading} onClick={e => this.transfer({typeName: "STORE-RESV-ADD-FRM-INVENTORY"})}>To Reserve
                           <FontAwesomeIcon icon={faArrowRight} size="lg" className="profit-text ml-1"/>
                       </Button>
                   </Card>
               </Col>
               <Col>
                   <Card body>
                       <h3>Reserve</h3>
                       <h1 className="center-text">{product.counters.counters.storeReserved}</h1>
                       <span className="text-center font-weight-bold">Target: {product.targets.storeReservedTarget}</span>
                   </Card>
               </Col>
           </Row>
           {transaction && <Row>
               <Col>
                   <Alert color="success">
                      Transaction {transaction.ref} saved successfully. {transaction.type.name} {transaction.itemCount}x
                   </Alert>
               </Col>
           </Row>}
        </ModalBody>
    }

    render() {
        const {product, isLoading, error} = this.state;
        const {onCancel} = this.props;
        return (
            <div>
                <Modal isOpen={true} toggle={e => onCancel()} className={this.props.className} size="lg">
                    <ModalHeader toggle={e => onCancel()}>Reserve Transfer - K{this.props.productId} - {product ? product.detail.shortName : ""}</ModalHeader>
                    <ModalBody>
                        {this._renderBody()}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={e => onCancel()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

ReserveTransferModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    productId: PropTypes.number.isRequired,
    onCounterUpdate: PropTypes.func
};

export {ReserveTransferModal};
