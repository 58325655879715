import {Button, Card, Col, Input, Row} from "reactstrap";

import React, {Component} from 'react';
import PropTypes from "prop-types";
import transactionsClient from "../../client/transactionsClient"
import {Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Label,} from "reactstrap";

class ProductManagementInventoryCard extends Component {
    state = {
        comment: ""
    };

    async addComment(){
        transactionsClient.transactions.addComment({idOrRef: this.props.transaction.ref, comment: this.state.comment})
            .then(r => console.log("Comment added"))
            .catch(e => console.log(e))
            .finally(r => this.props.toggle())
    }

    render() {
        const {transaction, toggle, onVoid, className} = this.props;

        return <Card body style={{ backgroundColor: '#b5cfff', borderColor: '#333' }}>
            <h3>INVENTORY</h3>
            <Row className="d-flex justify-content-center">
                <Col sm={3} md={3} lg={3} className="float-right" style={{paddingRight: 4}}><Button  color="warning" onClick={e => this.resetBatch()} size="lg" className="mr-0">-1</Button></Col>
                <Col sm={6} md={6} lg={6} ><Input className="middle font-size-large-mgmt" type="text" id="reserveTransfer"
                                                  value={53}
                                                  onChange={e => this.manualCountChange(e)} /></Col>
                <Col  sm={3} md={3} lg={3} className="float-left pl-0"><Button  color="warning" onClick={e => this.decrementCount()} size="lg">+1</Button></Col>
            </Row>
            <Row className="d-flex justify-content-center mt-2">
                <Col className="d-flex justify-content-center">
                    <Button  color="primary" onClick={e => this.decrementCount()} size="lg">SET</Button>
                </Col>
            </Row>

            <Row className="d-flex justify-content-center mt-2 mb-0 border-top">
                <Col className="d-flex justify-content-center">
                    <h4 className="mb-0">Location</h4>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center mt-0 mb-0">
                <Col className="d-flex justify-content-center">
                    <Input className="middle" type="text" id="reserveTransfer"
                           onChange={e => this.manualCountChange(e)} />
                </Col>
            </Row>
            <Row className="d-flex justify-content-center mt-0">
                <Col className="d-flex justify-content-center">
                    <span className="font-italic">Unit A - Self 4</span>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center mt-0">
                <Col className="d-flex justify-content-center">
                    <Button  color="primary" onClick={e => this.decrementCount()} size="md">SET LOCATION</Button>
                </Col>
            </Row>
        </Card>
    }
}

ProductManagementInventoryCard.propTypes = {
    product: PropTypes.object.isRequired,
    counterName: PropTypes.string
};

export {ProductManagementInventoryCard};