import React, {Component} from 'react';
import { FormGroup, Label, Input, Card, CardBody, CardHeader, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import ImClient from "../service/ImClientSingleton"
import KeyAttributeHeader from "../product/KeyAttributeHeader";
import Detail from "../product/Detail";
import DiscrepancyDetails from "../discrepancy/DiscrepancyDetails";
import eventClient from '../service/EventsClientSingleton'

class DiscrepancyReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            product: null
        };

        eventClient.subscribe((e) => this.handleProductCountersUpdate(e))
    }

    componentDidMount() {
        ImClient.getProductById(this.props.match.params.productId)
            .then(product => this.setState({product}));
    }

    onProductChange(product){
        this.setState({product: product});
    }

    handleProductCountersUpdate(e){
        if(e.type === "counters.product"){
            if(this.state.product && e.data.productId === this.state.product.key.id){
                let product = Object.assign({}, this.state.product);
                product.counters = e.data;
                this.onProductChange(product);
            }
        }
    }

    render() {
        return (
            <div className="App-Width">
                <h1>Discrepancy Report</h1>
                <KeyAttributeHeader product={this.state.product}/>
                <Detail product={this.state.product} hideDiscrepancyLink={true}/>
                <DiscrepancyDetails product={this.state.product}/>
            </div>
        );
    }
}

export default DiscrepancyReport;
