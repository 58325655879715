import { ErrorWithStatusCode } from '../errors/ErrorWithStatusCode';
const BASE_PATCH_PATH = 'inventoryProcessing.manualPackRequestBitByRegion.';
import flatten from 'flat';

class ProductInventoryProcessing{
    //Data attributes that assist or inform purchasing, packing, or shipping inventory

    constructor({manualPackRequestBitByRegion={}}={}, product){
        //Manual pack request bit for each region. Example: {manualPackRequestByRegion: {us: true, ca: false}}
        this.manualPackRequestBitByRegion = manualPackRequestBitByRegion;
        this._product = product;
        //This object can be expanded to include casePackQuantity, casePackWeightLbs, minPurchaseQuantity (currently on finance.purchasing),
        // manualPackRequestRequiredBitByRegion (this would hide the product from PR unless it was set manually)
    }

    getForDb(){
        let {["_product"]:omit, ...res} = this;
        return res;
    }

    toJSON(){
        return this.getForDb();
    }

    isManualPackRequestSetForRegion(region){
        if(!_regionHasActivePackRequest({product: this._product, region}))
            return false; //Product does not have an active Pack Request and cannot be used for manual PR
        return this.manualPackRequestBitByRegion[region];
    }

    static validatePatch({baseErrorMessage, property, value}){
        if(!property.includes(BASE_PATCH_PATH))
            return; //Not patching this object
        const region = property.replace(BASE_PATCH_PATH, "");
        if(region.length !== 2)
            throw new ErrorWithStatusCode({code: 400, message: `${baseErrorMessage} Region must be exactly 2 letters.`});
        if(typeof value !== "boolean")
            throw new ErrorWithStatusCode({code: 400, message: `${baseErrorMessage} Value for region ${region} must be a boolean.`});
    }

    static isPropertyPatchingInventoryProcessing(property){
        return property.includes(BASE_PATCH_PATH);
    }

    static getRegionsToAutoResetManualPackRequestBitsAsNeeded(product){
        let regions = Object.keys(product.inventoryProcessing.manualPackRequestBitByRegion);
        //Only regions that are set as manual true WITHOUT pack requests
        return regions.filter(r => product.inventoryProcessing.manualPackRequestBitByRegion[r]
            && !_regionHasActivePackRequest({product, region: r}));
    }

    static doesPatchRequestIncludeManualPackRequestUpdate(patchRequest){
        const flatKeys = Object.keys(flatten(patchRequest));
        return !!flatKeys.find(key => key.includes('manualPackRequestBitByRegion'));
    }
}

function _regionHasActivePackRequest({product, region}){
    const counter = product.counters.getCounter(`${region}-packRequest`);
    let r = counter > 0;
    return r;
}

export { ProductInventoryProcessing };