let env = process.env.NODE_ENV;
if(env === "development") env = "local_dev";
let config = require('./config/' + env);
let routes = require('./config/routes');
const axios = require('axios').default;
import {rpWrapper as rp} from './request.axios.wrapper';

const Product = require('../vendor/client/products/obj/product/Product').Product;

const PRODUCTS_BASE = config.baseProductsUrl;
const TRANSACTIONS_BASE = config.baseTransactionsUrl;

const US_PARENT_MARKETPLACE_ID = "ATVPDKIKX0DER";
const EU_PARENT_MARKETPLACE_ID = "A1F83G8C2ARO7P";
const CA_PARENT_MARKETPLACE_ID = "A2EUQ1WTGCTBG2";

class ImClient {
    constructor ({sessionKey}) {
        if(sessionKey == null)
            throw 'Cannot create product service without sessionKey provided';
        this._authHeader = {"kjd_session": sessionKey};

        this.US_PARENT_MARKETPLACE_ID = US_PARENT_MARKETPLACE_ID;
        this.EU_PARENT_MARKETPLACE_ID = EU_PARENT_MARKETPLACE_ID;
        this.CA_PARENT_MARKETPLACE_ID = CA_PARENT_MARKETPLACE_ID;
    }

    getProductShortNameById(productId){
        return this.getProductById(productId)
            .then(product => {
                return product.detail.shortName;
            });
    }

    getProductCommissionById(productId){
        return this.getProductById(productId)
            .then(product => {
                return product.finance.costs.perItemPackingCommission;
            });
    }

    getAllProducts({noCache=false}={}){
        let options = {
            method: 'GET',
            url: PRODUCTS_BASE + routes.products.get.products,
            headers: this._authHeader
        };
        if(noCache)
            options.uri += "?noCache=true";

        return axios(options)
            .then(result => {
                return result.data;
            })
    }

    getProductSalesByRegion({startDate}){

    }

    getMarketplaces({fbaParentRegion=null}={}){
        let options = {
            method: 'GET',
            url: PRODUCTS_BASE + routes.marketplaces.get.marketplaces,
            headers: this._authHeader,
        };
        if(fbaParentRegion)
            options.uri = options.uri + "fbaParentRegion=" + fbaParentRegion;

        return rp(options)
            .then(results => {
                if(fbaParentRegion && results > 1)
                    throw new  Error("Multiple parent marketplaces found");
                if(fbaParentRegion && results.length === 1)
                    return results[0];
                return results;
            })
    }

    getAllProductsDictByProductId(){
        return this.getAllProducts()
            .then(products => {
                let dict = {};
                products.forEach(product => {
                    dict[product.key.id] = product;
                });
                return dict;
            })
    }

    getProductById(productId){
        let options = {
            method: 'GET',
            uri: PRODUCTS_BASE + routes.products.get.product.replace('{:productId}', productId),
            headers: this._authHeader
        };

        return rp(options)
            .then(p => {
                return new Product(p);
            })
            .catch(err => {
                if (err.statusCode === 404)
                    return null; //Not found
                else {
                    let message;
                    if(err.body != null) message = err.body.message;
                    else message = err.message || err.error || err;
                    console.log(`Products Client Error: ${err.statusCode} - ${message}`);
                    return Promise.reject({message: "Error getting product (im client)", err: err}); //Rethrow
                }
            })
    }

    searchForProducts(search){
        let options = {
            method: 'GET',
            uri: PRODUCTS_BASE + routes.products.get.products + `?search=${search}`,
            headers: this._authHeader,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(results => {
                return results.map(p => new Product(p));
            })
            .catch(err => {
                if (err.statusCode === 404)
                    return []; //Not found
                return Promise.reject(err);
            });
    }

    searchForProduct(search){
        return this.searchForProducts(search)
            .then(results => {
                if(results.length === 1)
                    return new Product(results[0]);
                return Promise.reject({code: 404, message: "Multiple products found"});
            })
    }

    patchProduct({productId, patchRequest}){
        let options = {
            method: 'PATCH',
            uri: PRODUCTS_BASE + routes.products.patch.product.replace("{:productId}", productId),
            headers: this._authHeader,
            body: patchRequest,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(p => {
                return new Product(p);
            })
    }

    insertManualCount({productId, discrepancyTransactionBatchId, inventoryCount, storeReservedCount}){
        let options = {
            method: 'POST',
            uri: PRODUCTS_BASE + routes.products.post.manualCount.replace('{:productId}', productId),
            headers: this._authHeader,
            body: {
                discrepancyTransactionBatchId,
                manualCounts: {
                    inventory: inventoryCount,
                    storeReserved: storeReservedCount
                },
            },
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }

    createTransaction(params){
        let options = {
            method: 'POST',
            uri: TRANSACTIONS_BASE + routes.transactions.post.create,
            headers: this._authHeader,
            body: params,
            json: true
        };

        return rp(options)
    }

    voidTransaction({idOrRef, comment}){
        let params = {
            action: 'void',
        };
        if(comment)
            params.comment = comment;

        let options = {
            method: 'PUT',
            uri:  TRANSACTIONS_BASE + routes.transactions.put.update.replace(':idOrRef', idOrRef),
            headers: this._authHeader,
            body: params,
            json: true
        };

        return rp(options);
    }

    updateTransactionApproval({idOrRef, isApproved, comment}){
        let params = {
            action: 'approval',
            approved: isApproved
        };
        if(comment)
            params.comment = comment;

        let options = {
            method: 'PUT',
            uri:  TRANSACTIONS_BASE + routes.transactions.put.update.replace(':idOrRef', idOrRef),
            headers: this._authHeader,
            body: params,
            json: true
        };

        return rp(options);
    }

    submitBatch(batch){
        let options = {
            uri: TRANSACTIONS_BASE + routes.transactionBatches.post.create,
            method: 'POST',
            body: {transactions: batch},
            headers: this._authHeader,
            json: true
        };

        return rp(options);
    }

    getApprovables(){
        let options = {
            method: 'GET',
            uri: TRANSACTIONS_BASE + routes.approvals.get.recent,
            headers: this._authHeader,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }

    getPendingApprovables(){
        let options = {
            method: 'GET',
            uri: TRANSACTIONS_BASE + routes.approvals.get.pending,
            headers: this._authHeader,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }

    getInboundReserveTransactionsPending(){
        let options = {
            method: 'GET',
            uri: TRANSACTIONS_BASE + routes.approvals.get.pending,
            headers: this._authHeader,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }

    getInboundReserveTransactions(){
        let options = {
            method: 'GET',
            uri: TRANSACTIONS_BASE + routes.reserveProcessing.get.recent,
            headers: this._authHeader,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }

    getProductCounters(productId){
        let options = {
            method: 'GET',
            uri: TRANSACTIONS_BASE + routes.productCounters.get.getForProduct.replace(":productId", productId),
            headers: this._authHeader,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }

}

export default ImClient;