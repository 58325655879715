export const validateArrayOfType = function ({array, typeName, propName = '_notSpecified_', messageDetail = null,}) {
    if(!Array.isArray(array))
        throw `${propName} must be an array. ${messageDetail ? messageDetail : ""}`;
    for (let value in array) {
        if(typeof value !== typeName)
            throw `${propName} must be of type ${typeName}. ${messageDetail ? messageDetail : ""}`;
    }
};

export const initArrayOfObjects = function ({array, objectToInstantiate}) {
    if(array == null) return null;
    if(!Array.isArray(array)) throw `initArrayOfObjects must be called with an array`;
    if(typeof constructor !== 'function') throw `initArrayOfObjects must be called with a valid objectToInstantiate`;

    let result = [];
    array.forEach(value => {
        if (value != null ) {
            if(value instanceof objectToInstantiate)
                result.push(value);
            else
                result.push(new objectToInstantiate(value));
        }
    });
    return result;
};