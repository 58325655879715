import React, {Component} from 'react';
import {
    Input,
    Badge,
    Row,
    Col,
    Card,
    Button,
    Alert,
    FormFeedback,
    FormGroup,
    ListGroup,
    Table,
    ListGroupItem,
    Label
} from 'reactstrap';
import client from '../service/ImClientSingleton'
import PropTypes from "prop-types";
import KitBuilder from "./KitBuilder";
import {ProductManagementInventoryCard} from "../molecules/ProductManagementInventoryCard";
import {ProductManagementCard} from "../molecules/ProductManagementCard";

class ProductManagemnet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            manualCount: false,
            count: 0,
            selectedMarketName: null, //TODO Not state
            shipReadyTransactionType: null,  //TODO Not state
            selectedFnsku: null,
            outOfSequenceFnskuErrorInd: false,
            transactionSavedMessage: "",
            transactionSavedError: "",
            selectedUser: null,
            currentUser: null,
            pendingOrderChecked: false
        };
    }
    handleChange(e){
        if(e.target.value.length >= 10){
            if(!this.state.selectedFnsku)
                this.selectMarketByFnsku(e.target.value);
            else
                this.validateAndIncCountByFnsku(e.target.value);
        }
        else{
            this.setState({
                searchText: e.target.value,
            });
        }
    }

    manualCountChange(e){
        this.setState({
            count: parseInt(e.target.value),
        });
    }
    static playErrorSound(){
        let audio = new Audio('http://soundbible.com/mp3/Computer%20Error%20Alert-SoundBible.com-783113881.mp3');
        audio.type = 'audio/mp3';
        return audio.play(); //Promise
    }

    render() {
        return <div>
            <Row >
                <Col sm={6} md={6} lg={4}>
                    <ProductManagementInventoryCard/>
                </Col>
                <Col sm={6} md={6} lg={4}>
                    <ProductManagementInventoryCard/>
                </Col>
                <Col sm={6} md={6} lg={4}>
                    <ProductManagementCard/>
                </Col>
            </Row>
        </div>

    }
}

ProductManagemnet.propTypes = {
    product: PropTypes.object.isRequired
};


export default ProductManagemnet;
