import React, {Component} from 'react';
import { Input, FormGroup } from 'reactstrap';
import PropTypes from "prop-types";
import {LoadingSpinner} from "./LoadingSpinner";
import ImClient from "../service/ImClientSingleton"

class AutoSaveReserveEdit extends Component {
    state = {
        invalid: null,
        value: null,
        saveInProgress: false
    };

    onInput(e){
        this.setState({value: e.target.value});
        console.log("Input: ", e.target.value);
    }

    onBlur(e){
        let newValue = Number.parseInt(e.target.value);
        if(!validInt(newValue)){
            this.setState({invalid: true});
            return;
        }
        this.setState({invalid: false, value: newValue});
        return this.saveValue(newValue);
    }

    async saveValue(value){
        const {onSaved, productId} = this.props;
        await this.setState({saveInProgress: true});
        await ImClient.patchProduct({productId, patchRequest: {targets: {storeReservedTarget: value}}});
        if(onSaved && typeof onSaved === "function")
            onSaved(value);
        return this.setState({saveInProgress: false});
    }

    render() {
        const {value} = this.props;
        const {saveInProgress} = this.state;
        if(this.state.value === null)
            this.setState({value});

        return <FormGroup inline className="form-group-sm">
            <Input type="text" invalid={this.state.invalid} placeholder="" onBlur={e => this.onBlur(e)} onInput={e => this.onInput(e)} disabled={saveInProgress} value={this.state.value} />
            {saveInProgress && <LoadingSpinner/>}
        </FormGroup>
    }
}

function validInt(value){
    return !(!Number.isInteger(value) || isNaN(value) || value < 0);
}

AutoSaveReserveEdit.propTypes = {
    productId: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    onSaved: PropTypes.func
};

export {AutoSaveReserveEdit};
