import { ProductDetailManualCount } from './ProductDetailManualCount';
import { ProductDetailDescriptions } from './ProductDetailDescriptions';
import { ProductDetailIndicators } from './ProductDetailIndicators';
import { ProductDetailSizeWeight } from './ProductDetailSizeWeight';
import { ProductDetailImages } from './ProductDetailImages';
import { ProductDetailLocation } from './ProductDetailLocation';
import { validateArrayOfType } from '../../../helper/arrayOps';
class ProductDetail{
    constructor({name, shortName, descriptions, indicators, sizeWeight, images,
                    locations, categories, manualCounts=[], meta}){
        if(!name) throw `Name is required for a product`;
        if(typeof name !== "string") throw `Name must be a string`;
        if(shortName && typeof shortName !== "string") throw `Shortname must be a string`;
        if(shortName == null) shortName = name;

        this.name = name;
        this.shortName = shortName;
        this.descriptions = new ProductDetailDescriptions(descriptions);
        this.indicators = new ProductDetailIndicators(indicators);
        this.sizeWeight = new ProductDetailSizeWeight(sizeWeight);
        this.images = new ProductDetailImages(images);

        if(!locations) locations = [];
        this.locations = [];
        //Build locations
        locations.forEach(loc => this.locations.push(new ProductDetailLocation(loc)));

        if(!categories) categories = [];
        validateArrayOfType({array: categories, typeName: 'string', propName: 'Categories', messageDetail: `Product name ${this.name}`});
        this.categories = categories;
        this.manualCounts = manualCounts.map(count => new ProductDetailManualCount(count));

        if(!meta) meta = {};
        this.meta = meta;
    }

    get primaryLocation(){
        let location = this.locations.find(loc => loc.locationType === "PRIMARY");
        if(!location || (!location.binCode && !location.locationCode))
            return null;
        return location;
    }

    get reserveLocation(){
        let location = this.locations.find(loc => loc.locationType === "RESERVE");
        if(!location || (!location.binCode && !location.locationCode))
            return null;
        return location;
    }

    get mostRecentInventoryManualCount(){
        return this._getMostRecentManualCountForCounter('inventory');
    }

    get mostRecentStoreReservedManualCount(){
        return this._getMostRecentManualCountForCounter('storeReserved');
    }

    _getMostRecentManualCountForCounter(counterName){
        return this.manualCounts.filter(c => c.manualCounts.hasOwnProperty(counterName)).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).shift();
    }

    getForDb(){
        let res = Object.assign({}, this);
        res.manualCounts = this.manualCounts.map(manualCount => manualCount.getForDb());
        return res;
    }

}

export { ProductDetail };