import React from 'react';
import {Link} from 'react-router-dom'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle} from 'reactstrap';
import AppContext from "../../context/AppContext";
import {config as ssoConfig} from "../../custom_vendor/sso";
import Avatar from 'react-avatar';

class NavBar extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return ( <AppContext.Consumer>
                {({user}) => (
                    <Navbar color="light" light expand="md">
                        <NavbarBrand tag={Link} to="/">Packing</NavbarBrand>
                        <NavbarToggler onClick={this.toggle} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="mr-auto" navbar>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        Pack Requests
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem>
                                            <NavLink  href="https://sales.kjdelectronics.com/v1/packing/us/pdf"
                                                      target="_blank">US Pack Request</NavLink>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <NavLink  href="https://sales.kjdelectronics.com/v1/packing/ca/pdf?actual=true"
                                                      target="_blank">CA Pack Request</NavLink>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <NavLink href="https://sales.kjdelectronics.com/v1/packing/eu/pdf?actual=true"
                                                     target="_blank">EU Pack Request</NavLink>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <NavItem>
                                    <NavLink tag={Link} to={`/pack-request`}>Pack Request Manager</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to={`/discrepancies`}>Discrepancies</NavLink>
                                </NavItem>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        Reserve Management
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem tag={Link} to={`/reserve`}>
                                            Reserve Management
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to={`/reserve/activity`}>
                                            Reserve Activity
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to={`/reserve/inbound-processing`}>
                                            Inbound Reserve Processing
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <NavItem>
                                    <NavLink tag={Link} to={`/transactions`}>Transactions</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to={`/shipping`}>Shipping</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="https://sales.kjdelectronics.com/v1/picking/pickandpack.pdf"
                                             target="_blank">Pick & Pack</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to={`/mfn/orders`}>Amazon Shipping</NavLink>

                                </NavItem>
                            </Nav>
                            <Nav className="ml-auto" navbar>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        IM Links
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem href={"https://warehouse.kjdelectronics.com"} target="_blank">
                                            Warehouse
                                        </DropdownItem>
                                        <DropdownItem href={"https://purchasing.kjdelectronics.com"} target="_blank">
                                            Purchasing
                                        </DropdownItem>
                                        <DropdownItem href={"https://returns.kjdelectronics.com/"} target="_blank">
                                            Returns
                                        </DropdownItem>
                                        {user.groups.includes("IM_AUTH_ADMIN") && <DropdownItem href={"/publishing"}>
                                            Publishing
                                            </DropdownItem>}
                                        </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        {user.displayName} <Avatar src={user.photo} size="25" round={true}/>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem href={`${ssoConfig.baseUrl}logout`}>
                                            Logout
                                        </DropdownItem>
                                        <DropdownItem divider />
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Nav>
                        </Collapse>
                    </Navbar>
                )}
                </AppContext.Consumer>
            );
    }
}

NavBar.propTypes = {
};

export default NavBar