import axios from 'axios';

function rpWrapper(options){
    options.url = options.uri;
    delete options.uri;

    if(options.body){
        options.data = options.body;
        delete options.body;
    }
    delete options.json;

    return axios(options)
        .then(result => {
            return result.data;
        });
}

export { rpWrapper };