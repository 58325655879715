import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import {Badge, NavItem, NavLink} from 'reactstrap';
import paginationFactory from "react-bootstrap-table2-paginator";
import {TransactionApprovalBadge} from "../../atoms/TransactionApprovalBadge";
import {Link} from "react-router-dom";
import {CommentsCollapse} from "../../atoms/CommentsCollapse";
import {TransactionMetaLinks} from "../../atoms/TransactionMetaLinks";
import {TooltipWrapper} from "../../atoms/ToolTipLink";

class TransactionsTable extends React.Component {
    static lpnLink(transaction){
        if(transaction.meta.returnLpn){
            return  <NavLink href={`https://returns.kjdelectronics.com/lpn/${transaction.meta.returnLpn}`}
                             target="_blank"><Badge>{transaction.meta.returnLpn}</Badge></NavLink>
        }
        return '';
    }

    render() {
        const {transactions} = this.props;

        if(!transactions || transactions.length === 0)
            return "";

        const records = transactions.map(i => {
            const newR = {...i};
            const date = new Date(i.dateTime);

            newR.date = date;
            newR.transaction = i;
            newR.id = <NavLink className="compact" tag={Link} to={`/transactions/${i.ref}`}>{i.ref}</NavLink> ;
            newR.typeName = i.type.name;
            newR.kid = <TooltipWrapper id={`TT-${i.ref}`} tooltip={i.product.shortName}><div style={{width: "40%"}}><NavLink className="compact" id={`TT-${i.ref}`}
                                                                                                 tag={Link} to={`/packing/${i.product.id}`}>K{i.product.id}</NavLink></div></TooltipWrapper>;
            newR.itemCount = i.itemCount;
            newR.dateString =  <span style={{'font-size': '10pt'}}>{date.toLocaleDateString()} {date.toLocaleTimeString()}</span>;
            newR.voidBadge = i.voided ? <Badge className="text-center" color="danger">Void</Badge> : "No";
            newR.user = i.user.displayName;
            newR.approval = <TransactionApprovalBadge transaction={i}/>;
            newR.comments = <CommentsCollapse transaction={i}/>;
            newR.links = <TransactionMetaLinks transaction={i} className="pl-0 overflow-hidden"/>;
            return newR;
        });

        let options = {
            sizePerPageList: [ {
                    text: '10', value: 10
                },{
                    text: '25', value: 25
                },
                {
                    text: '50', value: 50
                },
                {
                    text: '100', value: 100
                },
            ],
            sizePerPage: this.props.sizePerPage ? this.props.sizePerPage :  25
        };
        if(transactions.length <= 10)
            options = null;

        const columns = [
            {
                dataField: 'id',
                text: 'ID',
                headerStyle: () => {
                    return { width: "5%" };
                },
                sort: true
            },
            {
                dataField: 'typeName',
                text: 'Type',
                headerStyle: () => {
                    return { width: "20%" };
                },

            },
            {
                dataField: 'dateString',
                text: 'Date',
                headerStyle: () => {
                    return { width: "12%" };
                },
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    if (order === 'desc') {
                        return rowB.date.getTime() - rowA.date.getTime();
                    }
                    return rowA.date.getTime() - rowB.date.getTime();
                }
            },
            {
                dataField: 'kid',
                text: 'KID',
                headerStyle: () => {
                    return { width: "7%" };
                }
            },
            {
                dataField: 'itemCount',
                text: 'Quantity',
                headerStyle: () => {
                    return { width: "7%" };
                }
            },
            {
                dataField: 'user',
                text: 'User',
                headerStyle: () => {
                    return { width: "13%" };
                }
            },
            {
                dataField: 'approval',
                text: 'Approval',
                headerStyle: () => {
                    return { width: "10%" };
                },
                formatter: centerFormatter
            },
            {
                dataField: 'links',
                text: 'Links',
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            {
                dataField: 'voidBadge',
                text: 'Voided',
                headerStyle: () => {
                    return { width: "7%" };
                },
                formatter: centerFormatter
            },
            {
                dataField: 'comments',
                text: 'Comments',
                headerStyle: () => {
                    return { width: "7%" };
                },
                formatter: centerFormatter
            },
        ];

        return (
            <div style={{width: "99%"}}>
                <BootstrapTable
                    condensed={true}
                    bootstrap4={true} keyField='id'
                    classes={"padding-2px-table"}
                    defaultSorted={[{dataField: "dateString", order: "desc"}]}
                    data={ records } pagination={ options ? paginationFactory(options) : null } columns={ columns } />
            </div> 
            );
    }
}

function centerFormatter(cell){
    return <div className="text-center">{cell}</div>;
}

TransactionsTable.propTypes = {
    transactions: PropTypes.array.isRequired,
    sizePerPage: PropTypes.number
};

export {TransactionsTable};