import {rpWrapper as rp} from './request.axios.wrapper';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const sessionKey = cookies.get('kjd_session');

const DEV = {
    baseUrl: "http://localhost:3041/v1"
};

const PROD = {
    baseUrl: "https://sales.kjdelectronics.com/v1"
};

const config = process.env.NODE_ENV.toLowerCase() === 'production' ? PROD : DEV;

class SalesLiteClient {
    constructor ({sessionKey}) {
        this.authHeader = {"Authorization": `Bearer ${sessionKey}`};
    }

    getRegions(){
        let options = {
            method: 'GET',
            uri: config.baseUrl + "/regions",
            headers: this.authHeader,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }

    getProductSales({lookBackDays, marketplaceIds=[]}){
        let options = {
            method: 'GET',
            uri: config.baseUrl + `/sales/products?lookBackDays=${lookBackDays}&marketplaceIds=${marketplaceIds.join(",")}`,
            headers: this.authHeader,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }
}

const client = new SalesLiteClient({sessionKey: sessionKey});

export default client;