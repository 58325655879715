class ProductMarketplaceIdentifiers{
    constructor(ids){
        //Freeform object of ids
        let skuSet = false;
        delete ids.searchArray; //This is rebuilt
        for (let key in ids) {
            if (!ids.hasOwnProperty(key))
                continue;
            const value =  ids[key];
            if(value == null)
                continue;
            this[key] = ids[key];
            if(key === 'sku')
                skuSet = true;
        }
        if(!skuSet) throw `ProductMarketplace must contain a sku`;
        this.searchArray = _buildSearchArray(ids);
    }

    buildSearchArray(){
        this.searchArray = _buildSearchArray(this);
    }
}

//Moved function out of class as this is not bound during construction
function _buildSearchArray(productMarketplaceObj){
    let searchSet = new Set();
    for (let property in productMarketplaceObj) {
        if(property === "searchArray")
            continue;
        if (productMarketplaceObj.hasOwnProperty(property) && productMarketplaceObj[property] != null) {
            searchSet.add(productMarketplaceObj[property]);
        }
    }
    return Array.from(searchSet);
}

export { ProductMarketplaceIdentifiers };