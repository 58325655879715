import { ProductCountersClient } from './service/product.counters.client';
import { Transactions } from './service/transactions.client';
import { Payments } from './service/payments.client';

class TransactionsClient{
    constructor({apiKey, sessionKey, microserviceAuthUsername, microserviceAuthPassword}){
        let auth = {
            headerName: null,
            headerValue: null
        };
        let authorizationHeader = null;
        if(sessionKey && (apiKey || microserviceAuthUsername || microserviceAuthPassword)){
            throw new Error('Invalid Arguments');
        }
        if(sessionKey){
            auth.headerName = 'kjd_session';
            auth.headerValue = sessionKey;
        }
        else{
            if(!apiKey){
                throw new Error('Invalid Arguments');
            }
            auth.headerValue = apiKey;
            auth.headerName = 'x-api-key';
            if(microserviceAuthUsername && microserviceAuthPassword)
                authorizationHeader =  'Basic ' + Buffer.from(microserviceAuthUsername + ':' + microserviceAuthPassword).toString('base64');
        }

        this.transactions = new Transactions({auth: auth, microserviceAuthorizationHeader: authorizationHeader});
        this.productCounters = new ProductCountersClient({auth: auth});
        this.payments = new Payments({auth: auth});
    }
}
export { TransactionsClient };