import React, {Component} from 'react';
import { Row, Col, Input, Button, Form, FormGroup, Label } from 'reactstrap';
import KeyAttributeHeader from "../product/KeyAttributeHeader";
import Detail from "../product/Detail";
import ReserveTransferDetails from "../reserve/ReserveTransferDetails";
import {FbaInboundShipmentTable} from "../organisms/FbaInboundShipmentTable";

class ShippingPage extends Component {
    constructor(props){
        super(props);

        this.state = {
            shipmentId: ""
        }
    }

    openUsShipment(){
        window.open("https://mws-service.kjdelectronics.com/v1/fba/inbound/marketplace/ATVPDKIKX0DER/shipment/"+this.state.shipmentId);
    }

    openCaShipment(){
        window.open("https://mws-service.kjdelectronics.com/v1/fba/inbound/marketplace/A2EUQ1WTGCTBG2/shipment/"+this.state.shipmentId);
    }

    openEuShipment(){
        window.open("https://mws-service.kjdelectronics.com/v1/fba/inbound/marketplace/A13V1IB3VIYZZH/shipment/"+this.state.shipmentId);
    }

    openUkShipment(){
        window.open("https://mws-service.kjdelectronics.com/v1/fba/inbound/marketplace/A1F83G8C2ARO7P/shipment/"+this.state.shipmentId);
    }

    openEuropeShippingDistribution(format="pdf"){
        window.open("https://sales.kjdelectronics.com/v1/europe-shipping/shipping-split."+format);
    }

    render() {
        return (
            <div className="App-Width">
                <h1>Shipping</h1>
                <div >
                    <FbaInboundShipmentTable/>
                    <div className="shipping-page">
                        <Form className="justify-content-center">
                            <FormGroup>
                                <h3><Label for="shipmentId">Legacy Import - Amazon Shipment ID</Label></h3>
                                <Input type="text" id="shipmentId" size="lg"  value={this.state.shipmentId} onChange={e => this.setState({shipmentId: e.target.value})} />
                            </FormGroup>
                            <FormGroup inline>
                                <Button className="m-1" size="lg" onClick={e => this.openUsShipment()}>Preview US Import</Button>
                                <Button className="m-1" size="lg" onClick={e => this.openCaShipment()}>Preview CA Import</Button>
                                <Button className="m-1" size="lg" onClick={e => this.openUkShipment()}>Preview UK Import</Button>
                                <Button className="m-1" size="lg" onClick={e => this.openEuShipment()}>Preview EU Import</Button>
                            </FormGroup>
                        </Form>
                        <h3>Europe RTS Distribution Report</h3>
                        <Button className="m-1" outline={true} size="lg" onClick={e => this.openEuropeShippingDistribution("pdf")}>RTS Split (PDF)</Button>
                        <Button className="m-1" outline={true} size="lg" onClick={e => this.openEuropeShippingDistribution("html")}>RTS Split (HTML)</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ShippingPage;
