import React, {Component} from 'react';
import PropTypes from 'prop-types';
import transactionsClient from "../../client/transactionsClient";
import eventClient from '../service/EventsClientSingleton'
import imClient from "../service/ImClientSingleton"
import {TransactionDetail} from "../organisms/TransactionDetail";
import {TransactionVoidBadge} from "../atoms/TransactionVoidBadge";
import {TransactionApprovalBadge} from "../atoms/TransactionApprovalBadge";
import {Row, Col, Input, Form, Label, FormGroup, Alert, NavLink, NavItem} from 'reactstrap';
import {TransactionCommentsTable} from "../atoms/TransactionCommentsTable";
import ReactJson from "react-json-view";
import Counters from "../product/Counters";
import ProductSearch from "../organisms/ProductSearch";
import Button from "reactstrap/es/Button";
import {Link} from "react-router-dom";

class TransactionCreatePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            transaction: null,
            types: [],
            type: {},
            product: null,
            count: 1,
            comment: "",
            meta: {},
            createdTransaction: null,
            error: null
        };
    }

    componentDidMount() {
        this.refreshSate();
    }

    refreshSate(){
        transactionsClient.transactions.getTransactionTypes()
            .then(types => {
                types = types.sort((a, b) => a.name > b.name ? 1 : -1);
                return this.setState({types: types, error: null, type: types[3]});
            })
            .catch(err => {
                console.log(err);
            });
    }

    getMetaObject(){
        const meta = this.state.meta;
        return this._unflatten(meta);
    }

    _unflatten = obj =>
        Object.keys(obj).reduce((res, k) => {
            k.split('.').reduce(
                (acc, e, i, keys) =>
                    acc[e] ||
                    (acc[e] = isNaN(Number(keys[i + 1]))
                        ? keys.length - 1 === i
                            ? obj[k]
                            : {}
                        : []),
                res
            );
            console.log(res);
            return res;
        }, {});

    createTransaction(){
        const params = {
            typeName: this.state.type.name,
            itemCount: this.state.count,
            productId: this.state.product.key.id,
            ignoreAllWarningsInd: true
        };
        if(this.state.comment)
            params.comment = this.state.comment;
        if(this.state.meta)
            params.meta = this.getMetaObject();

        transactionsClient.transactions.createTransactionFromParams(params)
            .then(createdTransaction => {
                return this.setState({createdTransaction, error: null});
            })
            .catch(err => {
                console.log(err);
                this.setState({error: err});
            });
    }

    onTypeChange(e){
       const typeName = e.target.value;
       const type = this.state.types.find(t => t.name === typeName);
       this.setState({type, meta: {}});
    }

    get commentProps(){
        if(!this.type.rules || !this.type.rules.commentRequired)
            return {};
        if(this.state.comment.length > 1)
            return {valid: true};
        return {invalid: true};
    }


    get type(){
        return this.state.type;
    }

    get requiredMetaAttributes(){
        if(!this.type.requiredMetaAttributes)
            return [];
        return this.type.requiredMetaAttributes;
    }

    setMeta({name, value}){
        let meta = Object.assign({}, this.state.meta);
        meta[name] = value;
        this.setState({meta});
    }

    renderRequiredMeta(){
        if(this.requiredMetaAttributes.length === 0)
            return "";
        return this.requiredMetaAttributes.map((name, index, array) => {
            if(index % 2 === 1)
                return ;
            return <FormGroup>
                <Row>
                    <Col md={6}>
                        <Label for="type">{name}</Label>
                        <Input type="text" style={{fontSize: "18px"}}
                               value={this.state.meta[name]} onChange={e => this.setMeta({name, value: e.target.value})} />
                    </Col>
                    {array.length > index && <Col md={6}>
                        <Label for="type">{array[index + 1]}</Label>
                        <Input type="text" style={{fontSize: "18px"}}
                               value={this.state.meta[array[index + 1]]} onChange={e => this.setMeta({name: array[index + 1] , value: e.target.value})} />
                    </Col> }
                </Row>
            </FormGroup>
        });
    }

    renderError(){
        if(!this.state.error)
            return "";
        return <Alert color="danger">
           Error Creating Transaction: {this.state.error.message}
        </Alert>;
    }

    renderCreatedTransaction(){
        const transaction = this.state.createdTransaction;
        if(!transaction)
            return "";
        return <Alert color="success">
            Created Transaction With ID  <NavLink className={"d-inline p-0 pr-1"} tag={Link} to={`/transactions/${transaction.ref}`}> {transaction.ref}</NavLink>
            for K{transaction.productId} {transaction.itemCount}x Unit{transaction.itemCount !== 1 ? "s" : ""}
        </Alert>;
    }

    get productName(){
        return this.state.product ? `${this.state.product.key.kid} - ${this.state.product.detail.shortName}` : "";
    }

    renderContent(){
        return <div>
            {this.renderError()}
            {this.renderCreatedTransaction()}
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <Row>
                                <Col md={6}>
                                    <Label for="type">Select</Label>
                                    <Input type="select" name="type" id="type" style={{fontSize: "18px"}} onChange={e => this.onTypeChange(e)}>
                                        {this.state.types.map(type => <option selected={type.name === this.state.type.name} value={type.name}>{type.name}</option>)}
                                    </Input>
                                </Col>
                                <Col md={6}>
                                    <Label for="type">Type Description</Label>
                                    <div className="mt-1" style={{fontSize: "18px"}}>{this.state.type.description}</div>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Label for="type">Product</Label>
                            <Row>
                                <Col md={6}>
                                    <ProductSearch onProductChange={product => this.setState({product})}/>
                                </Col>
                                <Col md={6}>
                                    <Label className="mt-1 font-weight-bold large">{this.productName}</Label>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col md={6}>
                                    <Label for="type">Item Count</Label>
                                    <Input type="number" placeholder="Count" style={{fontSize: "18px"}}
                                           min={0}
                                           value={this.state.count} onChange={e => this.setState({count: Number.parseInt(e.target.value)})} />
                                </Col>
                                <Col md={6}>
                                    <Label for="type" {...this.commentProps}>Comment</Label>
                                    <Input type="text" placeholder="Comment" style={{fontSize: "18px"}} {...this.commentProps}
                                           value={this.state.comment} onChange={e => this.setState({comment: e.target.value})} />
                                </Col>
                            </Row>
                        </FormGroup>
                        {this.renderRequiredMeta()}
                        <Button color="success" size="lg" onClick={() => this.createTransaction()}>Create Transaction</Button>
                    </Form>
                </Col>
            </Row>
            <Counters product={this.state.product} heading={`Current Counters - ${this.productName}`}/>
        </div>
    }

    render() {
        return (
            <div className="App-Width">
                 <h1 className="d-inline mr-4">New Transaction</h1>
                {this.renderContent()}
            </div>
        );
    }
}

TransactionCreatePage.propTypes = {
    transactionId: PropTypes.number.isRequired,
};


export default TransactionCreatePage;
