import React, {Component} from 'react';
import {
    Row,
    Col,
    Table,
    Button
} from 'reactstrap';

import {withRouter} from 'react-router-dom'
import mws3Client from "../../client/mws3.client";
import {MfnOrderItemsTable} from "../molecules/tables/MfnOrderItemsTable";
import MfnShipmentForm from "../organisms/MfnShipmentForm";
import {NotificationManager} from "react-notifications";
import {MfnShipmentRateQuotesTable} from "../molecules/tables/MfnShipmentRateQuotesTable";


class MfnOrderShipmentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: null,
            weight: "",
            length: "",
            width: "",
            height: "",
            rateQuotes: null,
            loading: false
        };
        this.productSearchRef = React.createRef();
    }


    async componentDidMount() {
        await this._getOrder();
    }

    async _getOrder(){
        const orderId = this.getOrderId();
        const order = await mws3Client.getAmazonOrder({orderId: orderId});
        this.setState({order});
    }

    async _getQuotes(){
        this.setState({loading: true});
        const params = this.getQuoteAndBuyParams();

        mws3Client.getShipmentRateQuotes(params)
            .then(result => {
                NotificationManager.info('Rate Quotes fetched', 'Rate Quotes');
                this.setState({rateQuotes: result.shippingServiceList, loading: false});
            })
            .catch(err => {
                NotificationManager.error('Error getting rate quotes', 'Error');
                this.setState({rateQuotes: null, loading: false});
            });

    }

    async _buyLabel(rateQuote){
        this.setState({loading: true});
        const params = this.getQuoteAndBuyParams();

        params.shippingServiceOfferId = rateQuote.shippingServiceOfferId;
        params.shippingServiceId = rateQuote.shippingServiceId;

        mws3Client.createShipment(params)
            .then(result => {
                NotificationManager.info('Label Purchased', 'Label Purchased');
                this.setState({loading: false, shipmentWithLabel: result});
                this.getLabelPng({shipmentWithLabel: result});
            })
            .catch(err => {
                NotificationManager.error('Error getting label', 'Error');
                this.setState({rateQuotes: null, loading: false});
            });
    }

    getLabelPng({shipmentWithLabel}){
        mws3Client.decodeLabel({data: shipmentWithLabel.label.fileContents.contents})
            .then(result => {
                console.log(result);
                let imagedata = "data:image/png;base64," + result;
                this.setState({labelPng: imagedata, rateQuotes: null});
                this.openImage(imagedata)
            })
            .catch(err => {
                console.log(err);
                NotificationManager.error('Error decoding label. Label was purchased. DO NOT PURCHASE DUPLICATE LABEL', 'Error');
                this.setState({rateQuotes: null, loading: false});
            });
    }

    openImage(data = null){
        const imageData = data || this.state.labelPng;
        if(!imageData)
            return;
        const win = window.open();
        win.document.write("<img src='"+imageData+"'/>");
        win.document.close();
    }

    getQuoteAndBuyParams(){
        const {order, weight, length, width, height} = this.state;
        const weightInOz =  Number(weight) * 16;
        return {
            amazonOrderId: this.getOrderId(),
            itemList: order.orderItems.map(oi => {return {OrderItemId: oi.orderItemId, Quantity: oi.quantityOrdered}}),
            weightInOz: weightInOz,
            lengthIn: Number(length),
            widthIn: Number(width),
            heightIn: Number(height)
        }
    }

    getOrderId(){
        if(this.props.match)
            return this.props.match.params.orderId;
        return null;
    }

    handleChange({value, name}){
        const obj = {rateQuotes: null};
        obj[name] = value;
        this.setState(obj)
    }

    renderBody(){
        const {order, weight, length, width, height, loading} = this.state;
        if(!order)
            return "";

        order.date = new Date(order.purchaseDate);
        const now = new Date();
        const orderAgeHours = Math.round((now - order.date) / 36e5);
        const orderAgeClass = orderAgeHours < 30 ? "profit-text" : "red-text";

        return <div>
            <Row>
                <Col xs={12} sm={5} md={4}>
                    <h4 className="text-left">Details</h4>
                </Col>
                <Col>
                    <h4 className="text-left">Order Items</h4>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={5} md={4}>
                    <Table bordered className="compact">
                        <tbody>
                        <tr>
                            <th scope="row" className="text-right">Order Id</th>
                            <td>{order.amazonOrderId}</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-right">Age</th>
                            <td ><span className={orderAgeClass}>{`${ orderAgeHours} hrs`}</span></td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-right">Date</th>
                            <td>{`${order.date.toLocaleDateString()} ${order.date.toLocaleTimeString()}`}</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-right">Status</th>
                            <td>{order.orderStatus}</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-right">Order Type</th>
                            <td>{order.orderType}</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-right">Ship Service Level</th>
                            <td>{order.shipServiceLevel}</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-right">Latest Ship Date</th>
                            <td>{new Date(order.latestShipDate).toLocaleDateString()}</td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-right">Latest Delivery Date</th>
                            <td>{new Date(order.latestDeliveryDate).toLocaleDateString()}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <MfnOrderItemsTable order={order}/>
                    <MfnShipmentForm loading={loading} weight={weight} length={length} width={width} height={height} onGetQuotes={() => this._getQuotes()}
                                     onChange={({value, name}) => this.handleChange({value, name})}/>
                    {this.state.rateQuotes && <div>
                          <h4 className="text-left mt-3">Rate Quotes</h4>
                          <MfnShipmentRateQuotesTable buyLabelFunc={(rateQuote) => this._buyLabel(rateQuote)}  serviceList={this.state.rateQuotes}/>
                      </div> }
                </Col>
            </Row>
            {this.state.labelPng && <div className="mt-3">  <Row>
                <Col className="text-center">
                    <Button onClick={() => this.openImage()}>Open Label in New Tab</Button>
                </Col>
            </Row>
                <Row className="mt-2 mb-2">
                    <Col className="text-center">
                        <img width="500px" src={this.state.labelPng}/>
                    </Col>
                </Row>
            </div>}

        </div>
    }


    render() {
        return (
            <div className="App-Width">
                <h1>Amazon Order Shipping - {this.getOrderId()}</h1>
                {this.renderBody()}
            </div>
        );
    }
}

export default withRouter(MfnOrderShipmentPage);
